/*******************************************************************
                Composant pour un album partagé
            mais dont le lien n'est pas/plus valide
*******************************************************************/

// Hooks
import { useTranslation } from 'react-i18next';
// Styles
import './notAllowed.scss';

const NotAllowed: Function = (): JSX.Element => {
    const { t } = useTranslation('', { keyPrefix: 'features.album.singleAlbum' })
    return (
        <div className="not-allowed">
            {t('messages.not-allowed')}
        </div>
    )
}

export default NotAllowed;