/*******************************************************************
   Composant pour la soumission de suggestions en bas de page 
*******************************************************************/

// Hooks
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
// Composants de librairies
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas } from 'react-bootstrap';
// Composants "locaux"
import ButtonWithTooltip from '../../components/Buttons/buttonWithTooltip';
import PrimaryButton from '../../components/Buttons/primaryButton';
import SecondaryButton from '../../components/Buttons/secondaryButton';
// Stores
import { GeneralLayoutStore } from '../../app/store/layout';
// Actions
import { clearFields, handleFeedback } from '../../app/actions/controlledFields';
import { openFeedback, thanksForFeedback } from '../../app/actions/layout/general';
import { sendFeedback } from '../../app/actions/user';
// Styles
import './feedback.scss';

const FeedBack: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.feedback' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération des states
    const { openFeedback: feedbackIsOpen, thanksForFeedback: thanks } = GeneralLayoutStore();

    return (
        <div className="feedback">
            <ButtonWithTooltip
                trigger={['hover', 'click', 'focus']}
                tooltip={{
                    placement: "left",
                    classname: "feedback-tooltips",
                    content: t('tooltip')
                }}
                button={{ classname: "feedback-button" }}
                icon={{
                    icon: faComments,
                    classname: "feedback-icon"
                }}
                onClick={() => dispatch(openFeedback(true))}
            />
            <Offcanvas
                id="feedback-offcanvas"
                show={feedbackIsOpen}
                onHide={() => {
                    dispatch(openFeedback(false))
                    dispatch(clearFields('feedback'))
                }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('title')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {!thanks && (
                        <>
                            <form
                                id="feedback-form"
                                method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    dispatch(sendFeedback())
                                }}
                            >
                                <label htmlFor="feedback-textarea">
                                    {t('label')}
                                    <textarea id="feedback-textarea" required={true} onChange={(e) => dispatch(handleFeedback(e.target.value))} />
                                </label>
                            </form>
                            <PrimaryButton buttonType="submit" buttonName={t('send')} formAttached="feedback-form" />
                        </>
                    )}
                    {thanks && (
                        <>
                            <div className="thanks">{t('thanks.message')}</div>
                            <SecondaryButton buttonName={t('thanks.new-feedback')} onClick={() => dispatch(thanksForFeedback(false))} />
                        </>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default FeedBack;