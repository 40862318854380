/// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../types/states/user';
// Importations des actions utilisées
import {
    CLEAR_USER_DATA,
    GET_TOKEN_FOR_RESET_PASSWORD,
    GET_USER_DATA,
    USER_CONNECTED_AFTER_REGISTER
} from '../actions/user';
// Importation du module jwt pour décoder les tokens
import { jwtDecode } from 'jwt-decode';

const initialState: InitialState = {
    userData: {
        id: undefined,
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        userConnected: undefined
    },
    resetPasswordToken: undefined
};

function userReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case GET_USER_DATA:
            {
                const userConnected = localStorage.getItem('userConnected') !== null ? JSON.parse(localStorage.getItem('userConnected')!) : false

                let payloadToken: any | undefined = undefined;
                try {
                    if (localStorage.getItem('user') != null && localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== 'undefined') {
                        payloadToken = jwtDecode(localStorage.getItem('user') ?? '');
                    }
                } catch (error) {
                    console.log("error JWT");
                    console.error(error)
                }
                const firstname = payloadToken && payloadToken.user?.firstname ? payloadToken.user.firstname : undefined;
                const lastname = payloadToken && payloadToken.user?.lastname ? payloadToken.user.lastname : undefined;
                const id = payloadToken && payloadToken.user?.id ? payloadToken.user.id : undefined;
                const email = payloadToken && payloadToken.user?.email ? payloadToken.user.email : undefined;

                return ({
                    ...state,
                    userData: {
                        ...state.userData,
                        id: id,
                        firstname: firstname,
                        lastname: lastname,
                        email: email,
                        userConnected: userConnected
                    }
                })

            }

        case USER_CONNECTED_AFTER_REGISTER:
            return ({
                ...state,
                userData: {
                    ...state.userData,
                    userConnected: true
                }
            })

        case CLEAR_USER_DATA:
            return ({
                ...state,
                userData: initialState.userData
            })

        case GET_TOKEN_FOR_RESET_PASSWORD:
            return ({
                ...state,
                resetPasswordToken: action.token
            })

        default:
            return state;
    }
}

export default userReducer;