// typage des props
type secondaryButtonProps = {
    buttonType: "button" | "submit" | "reset" | undefined,
    buttonName: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => any,
};

const SecondaryButton: Function = ({ buttonType, buttonName, onClick }: secondaryButtonProps): JSX.Element => (
    <button type={buttonType === undefined ? "button" : buttonType} className="btn btn-secondary" onClick={onClick}>{buttonName}</button>
);

export default SecondaryButton;
