// Hooks
import { useAppDispatch } from "../../app/hooks"
import { useTranslation } from "react-i18next"
// Comopsants de librairies
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Overlay, Popover } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/types"
import { Link } from "react-router-dom"
// Stores
import { MediasLayoutStore } from "../../app/store/layout"
// Actions
import { openCanILikeOrCommentPopover } from "../../app/actions/layout/medias"

// Typages des props
type PopoverProps = {
    target: HTMLElement,
    mediaId: string,
    container: string,
    action: string,
    placement: Placement
}

const LikeOrCommentPopover: Function = ({ target, mediaId, container, action, placement }: PopoverProps): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.popover.likeOrComment' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch()
    // Récupération des states
    const { popover } = MediasLayoutStore();

    return (
        <Overlay
            // Lorsque l'ouverture d'un popover est déclenchée par une action, les states relatifs au popover à afficher sont mis à jour
            // Le popover ne s'ouvre que si ses props sont identiques aux informations du state
            // Afin d'utiliser ce même composant sur des interfaces différentes, l'Overlay étant hors flux, 
            // on spécifie l'action souhaitée qui a déclenché l'affichage, le media dont provient cette action et le container auquel
            // appartient le popover à afficher
            show={popover.media === mediaId && popover.action === action && popover.refContainer === container ? popover.show : false}
            target={target}
            placement={placement}
        >
            <Popover>
                <div id="popover-like">
                    <FontAwesomeIcon
                        className="close-popover"
                        icon={faXmark}
                        onClick={() => dispatch(openCanILikeOrCommentPopover(false, mediaId, '', null))}
                    />
                    <Popover.Body>
                        <span>{t('span1')}</span>
                        <Link
                            to="/login/signin"
                            onClick={() => dispatch(openCanILikeOrCommentPopover(false, mediaId, '', null))}
                        >
                            {t('signIn')}
                        </Link>
                        <span>{t('span2')}</span>
                        <Link
                            to="/login/register"
                            onClick={() => dispatch(openCanILikeOrCommentPopover(false, mediaId, '', null))}
                        >
                            {t('register')}
                        </Link>
                        <span>{`${t('span3')}${popover.action} !`}</span>
                    </Popover.Body>
                </div>
            </Popover>
        </Overlay>
    )
}

export default LikeOrCommentPopover