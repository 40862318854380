// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { instance, instanceWithRefreshOnResponse } from "../config";
// Importation des actions utilisées
import {
    POST_ANSWERKISS,
    updateFileUploadStatus,
    updateProgressOnUploadFile,
    UPLOAD_MEDIA
} from "../../actions/media/upload";
import { mediaUploadingInAlbum } from "../../actions/album/singleAlbum";
import { clearFields } from "../../actions/controlledFields";

const uploadMiddleware: Middleware = store => next => action => {

    switch (action.type) {

        case UPLOAD_MEDIA:
            // passage du statut "to send" au statut "sending" pour le média à uploader
            store.dispatch(updateFileUploadStatus(action.media, 'sending'));
            // création du formData pour l'envoi du fichier et des données
            const formData = new FormData();
            // Définition du type de média pour effectuer le postage sur le endpoint correspondant
            // et déterminer le nom du champ pour le fichier à uploader
            const mediaType: string = action.media.type;
            let endpoint: string = '';
            switch (true) {
                case mediaType.includes('image/'):
                    formData.append("image", action.media);
                    endpoint = 'media/image';
                    break;
                case mediaType.includes('video/'):
                    formData.append("video", action.media);
                    endpoint = 'media/video';
                    break;
                default:
            }
            // Si un id d'album est fourni avec l'action alors on est dans le cas d'un média uploadé depuis un album
            // on ajoute l'id de l'album au formData
            if (action.albumId !== undefined) {
                store.dispatch(mediaUploadingInAlbum(true))
                formData.append('idAlbum', action.albumId)
            }

            // Envoi au serveur
            instanceWithRefreshOnResponse.post(endpoint, formData, {
                withCredentials: true,
                onUploadProgress: (e) => {
                    // on calcul le pourcentage de données transférées pour faire évoluer la barre de progression des uploadToasts
                    const progress: number = Math.round((e.loaded / e.total) * 100);
                    store.dispatch(updateProgressOnUploadFile(action.media, progress));
                }
            })
                .then(response => {
                })
                .catch(error => {
                    console.log(error);
                    // si l'uplod a échoué on repasse le statut du média à 'to send'
                    store.dispatch(updateFileUploadStatus(action.media, 'to send'))
                })

            break;

        case POST_ANSWERKISS:
            {
                // Récupération de la valeur des champs contrôlés correspondant aux input question / réponse de l'answerkiss 
                const { question, answer, albumIdSend } = store.getState().controlledFields.answerkiss

                if (albumIdSend !== undefined) {
                    store.dispatch(mediaUploadingInAlbum(true))
                }

                // Envoi au serveur
                instance.post('/answer-kiss', {
                    question: question,
                    answer: answer,
                    idAlbum: albumIdSend
                })
                    .then(response => {
                        // On vide les champs contrôlés correspondant à l'answerkiss
                        store.dispatch(clearFields('answerkiss'))
                    })
                    .catch(error => {
                        console.log(error)
                        store.dispatch(clearFields('answerkiss'))
                    })
            }
            break;

        default:
    }

    next(action);
}

export default uploadMiddleware;