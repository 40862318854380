/*******************************************************************
     Modal pour la création ou la modification d'une Answerkiss
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../app/hooks";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
// Composants de librairies
import { Modal } from "react-bootstrap";
// Composants "locaux"
import PrimaryButton from "../Buttons/primaryButton";
import SecondaryButton from "../Buttons/secondaryButton";
import Field from "../Field/field";
// Stores
import { MediasLayoutStore, ModalsLayoutStore } from "../../app/store/layout";
import { ControlledFieldsStore } from "../../app/store/controlledFields";
// Actions
import { clearFields, handleAnswerkissFields } from "../../app/actions/controlledFields";
import { openAnswerKissModal, openAnswerKissHelp } from "../../app/actions/layout/modals";
import { patchAnswerkiss } from "../../app/actions/media/singleMedia";
import { postAnswerkiss } from "../../app/actions/media/upload";

import './addAnswerkiss.scss';

const AddAnswerkiss: Function = ({ albumId }: any) => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.addAnswerkiss' });

    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const { addAnswerkiss: addAnswerkissModal } = ModalsLayoutStore();
    const { question, answer, albumIdSend } = ControlledFieldsStore().answerkiss;
    const { editAnswerkiss } = MediasLayoutStore();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // envoi l'id de l'album dans le store si cela n'a pas été fait
        if (albumIdSend !== albumId) {
            dispatch(handleAnswerkissFields('albumIdSend', albumId))
        }
    });

    return (
        <Modal id="addAnswerkissModal" show={addAnswerkissModal.show} size="lg" centered>
            <Modal.Body>
                <form
                    id="add-answerkiss"
                    method="post"
                    onSubmit={(e) => {
                        e.preventDefault()
                        // Si on est en mode édition on dispatche l'action patch sinon l'action post
                        !editAnswerkiss ? dispatch(postAnswerkiss()) : dispatch(patchAnswerkiss())
                        // Fermeture du modal
                        dispatch(openAnswerKissModal(false))

                        if (addAnswerkissModal.showHelp) {
                            // Fermeture de l'aide
                            dispatch(openAnswerKissHelp(false))
                        }
                    }}
                >

                    <div className="row mb-3">
                        <div className="col-12">
                            <h1>Ajouter une answerKiss</h1>
                            Sous forme de question / réponse, offrez des informations précieuses à votre entourage sur vous-même et/ou vos proches : votre date et lieu de naissance, les prénoms de vos parents, vos passions, votre parcours scolaire, vos traits de caractère, vos livres préférés, etc.
                        </div>
                    </div>

                    <div className="row">
                        <div className={addAnswerkissModal.showHelp ? 'col-6' : 'col-12'}>
                            <Field
                                labelHtml="question-input"
                                labelName={t('labels.question')}
                                inputType="text"
                                inputName="question"
                                required={true}
                                // Champ contrôlé
                                inputValue={question}
                                action={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(handleAnswerkissFields('question', e.target.value))}
                            />
                            <label id="answer-textarea" htmlFor="answer-field">
                                {t('labels.answer')}
                                <textarea
                                    id="answer-field"
                                    required={true}
                                    maxLength={5000}
                                    // champ contrôlé
                                    value={answer}
                                    onChange={(e) => dispatch(handleAnswerkissFields('answer', e.target.value))}
                                />
                            </label>
                        </div>
                        <div className={addAnswerkissModal.showHelp ? 'col-6' : ''}>
                            <div className={addAnswerkissModal.showHelp ? 'aideVisible' : 'aideNonVisible'}>
                                Exemples :
                                <ul>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quelle est ma date de naissance ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quel est mon lieu de naissance ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quels souvenirs ai-je de mon enfance ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quelles sont mes passions ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quels sont mes traits de caractère ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quel est mon parcours scolaire ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Quelle est ma profession ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Qui sont/étaient mes parents ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Dates et lieux de naissance de mes parents ?
                                    </li>
                                    <li className="questionExemple" onClick={(e) => {
                                        const elementQuestion = e.currentTarget.innerHTML;
                                        // Ajout de la question
                                        dispatch(handleAnswerkissFields('question', elementQuestion))
                                        dispatch(handleAnswerkissFields('answer', ''))
                                        dispatch(openAnswerKissHelp(false))
                                    }}>
                                        Des anecdotes à raconter ?
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="titreAide" onClick={() => {
                    // Fermeture de l'aide
                    dispatch(openAnswerKissHelp(!addAnswerkissModal.showHelp))
                }}>
                    Vous souhaitez quelques exemples de questions ?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton buttonName={t('buttons.secondary')} onClick={() => {
                    // Fermeture du modal
                    dispatch(openAnswerKissModal(false))
                    // Réinitialisation des champs
                    dispatch(clearFields('answerkiss'))
                    if (addAnswerkissModal.showHelp) {
                        // Fermeture de l'aide
                        dispatch(openAnswerKissHelp(false))
                    }
                }}
                />
                <PrimaryButton
                    buttonType="submit"
                    buttonName={editAnswerkiss ? t('buttons.primary.edit') : t('buttons.primary.validate')}
                    formAttached="add-answerkiss"
                />
            </Modal.Footer>
        </Modal>
    )
}

export default AddAnswerkiss;