import { useAppSelector } from "../hooks"
import { InitialState as MediasListStates } from "../types/states/media/mediasList"
import { InitialState as UploadStates } from "../types/states/media/upload"

export const MediasListStore: Function = () => {
    const mediasList: MediasListStates = useAppSelector(state => state.mediasList)
    return mediasList
}

export const UploadStore: Function = () => {
    const upload: UploadStates = useAppSelector(state => state.upload)
    return upload
}