// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { instance } from "../config";
// Importation des actions utilisées
import { handleIsLoading } from "../../actions/layout/general";
import { GET_ALL_MEDIAS, putAllMediasInState } from "../../actions/media/mediasList";
// Importation de types
import { Media } from "../../types/media";

const mediasListMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case GET_ALL_MEDIAS:
            // Mise en place d'un loader pendant l'attente de la requête
            store.dispatch(handleIsLoading(true));
            instance.get('/media/all')
                .then(response => {
                    // stockage des médias dans le store
                    const allMedias: Media[] = response.data;
                    store.dispatch(putAllMediasInState(allMedias));
                    // Arrêt du loader
                    store.dispatch(handleIsLoading(false));
                })
                .catch(error => {
                    console.log(error);
                    // Arrêt du loader
                    store.dispatch(handleIsLoading(false));
                });
            break;

        default:
    }

    next(action);
}

export default mediasListMiddleware;
