/*******************************************************************
                Composant pour la page /all-albums
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../../app/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Composants de librairies
import { Link } from "react-router-dom";
// Composants "locaux"
import CreateAlbum from "../../../components/Modals/createAlbum";
import DeleteConfirmation from "../../../components/Modals/deleteConfirmation";

// Stores
import { ModalsLayoutStore } from "../../../app/store/layout";
import { AllAlbumsStore, SharedAlbumsStore } from "../../../app/store/album";
import { MediasListStore } from "../../../app/store/media";

// Actions
import { getAllMedias } from "../../../app/actions/media/mediasList";
import { getAlbumsSharedWithMe } from "../../../app/actions/album/sharedAlbums";
import { getAllAlbums } from "../../../app/actions/album/allAlbums";
import { deleteAlbum, duplicateAlbum } from "../../../app/actions/album/singleAlbum";
import { confirmDeleteAlbum, confirmDuplicateAlbum } from "../../../app/actions/layout/modals";
import PrimaryButton from "../../../components/Buttons/primaryButton";
import { showCreateAlbumModal } from "../../../app/actions/layout/modals";
// Assets
import { findThumbnail } from "./findThumbnail";
// Types
import { Album } from "../../../app/types/album";
// Styles
import './allAlbums.scss';

import votreHistoire from '../../../assets/images/home/votre-histoire.jpg';
import vosPhotos from '../../../assets/images/home/vos-photos.jpg';
import vosDocuments from '../../../assets/images/home/vos-documents.jpg';
import vosVideos from '../../../assets/images/home/vos-videos-compress.mp4';

const AllAlbums: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.album.allAlbums' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();

    // Récupération des states
    const { allAlbums } = AllAlbumsStore();
    const { albumsSharedWithMe } = SharedAlbumsStore();
    const { allMedias } = MediasListStore();
    const { deleteAlbum: deleteAlbumModal } = ModalsLayoutStore();
    const { duplicateAlbum: duplicateAlbumModal } = ModalsLayoutStore();

    const labelTextMedium = t('buttons.create.device.medium')

    // Effet de bord
    useEffect(() => {
        // Si on est sur la page d'albums partagés avec moi on récupère la liste des albums partagés avec moi
        // sinon on recupère tous les albums personnels (page /all-albums)
        pathname === '/shared-with-me' ? dispatch(getAlbumsSharedWithMe()) : dispatch(getAllAlbums());
        // On récupère la liste de tous les médias
        dispatch(getAllMedias());
    }, [dispatch, pathname])

    // Déclatation de la liste des albums en fonction de l'url
    const albumsList = pathname === '/shared-with-me' ? albumsSharedWithMe : allAlbums;

    useEffect(() => {
        console.log(albumsList);
    }, [albumsList]);



    return (
        <div className="all-albums marginMenuLeft">

            {albumsList.length === 0 && (
                <>
                    <div className="presentationCompteVierge">
                        <div className="row">
                            <div className="col-12">
                                <h1>Créez votre première boîte à souvenirs</h1>

                                <div className="empty-message">
                                    {t('messages.empty-list')}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-lg"
                                    buttonName={labelTextMedium}
                                    onClick={() => dispatch(showCreateAlbumModal(true))}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="row rowPresentation mb-0 g-0">
                        <div className="col-6"><img src={votreHistoire} alt='votre histoire' className="imgFullWidth" /></div>
                        <div className="col-6"><img src={vosPhotos} alt='vos photos' className="imgFullWidth" /></div>
                    </div>

                    <div className="row rowPresentation mb-0 g-0">
                        <div className='col-6'>
                            <video autoPlay muted loop width="600" src={vosVideos} className="imgFullWidth">
                            </video>
                        </div>
                        <div className='col-6'>
                            <img src={vosDocuments} alt='vos documents' className="imgFullWidth" />
                        </div>
                    </div>
                </>
            )}

            <div className="all-albums-list">
                {albumsList.length !== 0 &&
                    albumsList.map((album: Album) => (
                        <div className="album-item" key={album._id}>
                            <Link
                                // Si l'album n'est pas un album partagé, au clic sur l'album on redirige vers la page de l'album
                                // sinon on redirige vers le liens de partage 
                                to={
                                    `${pathname !== '/shared-with-me' ?
                                        `/album/${album._id}`
                                        :
                                        `/share/${album._id}/${album.id_share}`}`
                                }
                            >
                                {/* balise picture utilisée au cas où le l'url src en .webp n'est pas prise en charge,
                                    on passe automatiquement à l'url en .jpg de la balise img */}
                                <picture>
                                    <source
                                        srcSet={
                                            // on recherche dans la liste des médias, le premier média disponible et existant dans l'album
                                            // pour afficher sa vignette de présentation
                                            findThumbnail(pathname, allMedias, album, 'webp')
                                        }
                                    />
                                    <img
                                        alt=""
                                        src={findThumbnail(pathname, allMedias, album, 'jpg')}
                                    />
                                </picture>
                                <h4 className="album-title">{album.title}</h4>
                                {pathname === '/shared-with-me' && (
                                    <h4 className="album-owner">{album.owner_firstname} {album.owner_lastname}</h4> 
                                )}
                            </Link>
                        </div>
                    ))
                }
            </div>

            {/* Modals à afficher en fonction des actions : */}
            <CreateAlbum allMedias={allMedias} />
            <DeleteConfirmation
                specificModal={deleteAlbumModal}
                message={t('messages.confirm-delete')}
                closeModal={() => dispatch(confirmDeleteAlbum(false, undefined))}
                deleteAction={() => {
                    dispatch(deleteAlbum(deleteAlbumModal.albumToDelete!))
                    dispatch(confirmDeleteAlbum(false, undefined))
                }}
            />
            <DeleteConfirmation
                specificModal={duplicateAlbumModal}
                message={t('messages.confirm-duplicate')}
                closeModal={() => dispatch(confirmDuplicateAlbum(false, undefined))}
                deleteAction={() => {
                    dispatch(duplicateAlbum(duplicateAlbumModal.albumToDuplicate!))
                    dispatch(confirmDuplicateAlbum(false, undefined))
                }}
            />
        </div>
    );
}

export default AllAlbums;