// Vérouillage des actions
export const DISPLAY_CONNECT: string = 'DISPLAY_CONNECT';
export const DISPLAY_REGISTER: string = 'DISPLAY_REGISTER';

// affichage du formulaire de connexion => reducer
export function displayConnect() {
    return ({
        type: DISPLAY_CONNECT,
    })
}

// affichage du formulaire d'inscription => reducer
export function displayRegister() {
    return ({
        type: DISPLAY_REGISTER,
    })
}