/*******************************************************************
              Modal d'ajout d'un média dans un Album
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
// Composants de librairies
import { Modal } from "react-bootstrap";
// Composants "locaux"
import PrimaryButton from "../Buttons/primaryButton";
import SecondaryButton from "../Buttons/secondaryButton";
// Stores
import { MediasListStore } from "../../app/store/media";
import { ModalsLayoutStore } from "../../app/store/layout";
// Actions
import { selectMediaForAlbum } from "../../app/actions/album/allAlbums";
import { addMediasToAlbum } from "../../app/actions/album/singleAlbum";
import { openSingleAlbumMenu } from "../../app/actions/layout/album";
import { showAddMediaInAlbum } from "../../app/actions/layout/modals";
// Types
import { Media } from "../../app/types/media";

const AddMediaInSingleAlbum: Function = ({ albumId }: { albumId: string }) => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.addMediaInSingleAlbum' })

    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const { allMedias } = MediasListStore();
    const { addMediaInSingleAlbum: addMediaModal } = ModalsLayoutStore();

    return (
        <Modal show={addMediaModal.show}>
            <Modal.Body>
                <div className="medias-selection">{t('medias-selection.label')}</div>
                <div className="all-medias">
                    {allMedias.map((media: Media) => (
                        <div className="media-item" key={media._id}>
                            <picture>
                                <source srcSet={media.url_album.webp} />
                                <img
                                    draggable={false}
                                    alt=""
                                    src={media.url_album.jpg}
                                    onClick={(e) => {
                                        dispatch(selectMediaForAlbum(media._id));
                                        e.currentTarget.classList.toggle("selected");
                                    }}
                                />
                                {/* Filtre rouge sur le médias sélectionné */}
                                <div className="overlay" onClick={(e) => {
                                    dispatch(selectMediaForAlbum(media._id));
                                    e.currentTarget.previousElementSibling!.classList.toggle("selected")
                                }}
                                />
                            </picture>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton buttonName={t('buttons.secondary')} onClick={() => dispatch(showAddMediaInAlbum(false))} />
                <PrimaryButton buttonName={t('buttons.primary')} onClick={() => {
                    dispatch(addMediasToAlbum(albumId))
                    // Fermeture du modal
                    dispatch(showAddMediaInAlbum(false))
                    // Fermeture des options d'album
                    dispatch(openSingleAlbumMenu(false))
                }} />
            </Modal.Footer>
        </Modal>
    )
}

export default AddMediaInSingleAlbum;