// Assemblage des Reducers pour la constitution du Store
// IMPORTS
import { configureStore } from '@reduxjs/toolkit';
import socketMiddleware from '../middlewares/socket';
import userMiddleware from '../middlewares/user';
import controlledFieldsReducer from '../reducers/controlledFields';
import userReducer from '../reducers/user';
import allAlbumsMiddleware from '../middlewares/album/allAlbums';
import sharedAlbumsMiddleware from '../middlewares/album/sharedAlbums';
import singleAlbumMiddleware from '../middlewares/album/singleAlbum';
import allAlbumsReducer from '../reducers/album/allAlbums';
import singleAlbumReducer from '../reducers/album/singleAlbum';
import sharedAlbumsReducer from '../reducers/album/sharedAlbums';
import mediasListReducer from '../reducers/media/mediasList';
import uploadReducer from '../reducers/media/upload';
import singleMediaMiddleware from '../middlewares/media/singleMedia';
import mediasListMiddleware from '../middlewares/media/mediasList';
import uploadMiddleware from '../middlewares/media/upload';
import generalLayoutReducer from '../reducers/layout/generalLayout';
import loginReducer from '../reducers/layout/login';
import lightboxReducer from '../reducers/layout/lightbox';
import modalsReducer from '../reducers/layout/modals';
import albumLayoutReducer from '../reducers/layout/album';
import mediasLayoutReducer from '../reducers/layout/medias';


// STORE
export const store = configureStore({
    reducer: {
        mediasLayout: mediasLayoutReducer,
        generalLayout: generalLayoutReducer,
        login: loginReducer,
        albumLayout: albumLayoutReducer,
        lightbox: lightboxReducer,
        modals: modalsReducer,
        user: userReducer,
        controlledFields: controlledFieldsReducer,
        mediasList: mediasListReducer,
        upload: uploadReducer,
        allAlbums: allAlbumsReducer,
        singleAlbum: singleAlbumReducer,
        sharedAlbums: sharedAlbumsReducer
    },
    middleware: [
        userMiddleware,
        allAlbumsMiddleware,
        sharedAlbumsMiddleware,
        singleAlbumMiddleware,
        singleMediaMiddleware,
        mediasListMiddleware,
        uploadMiddleware,
        socketMiddleware
    ]
});

// TYPAGE Des Hooks d'accès au Store et du dispatch d'action;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<
// ReturnType,
// RootState,
// unknown,
// Action<string>
// >;