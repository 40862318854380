// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/album/sharedAlbums';
import { Media } from '../../types/media';
// Importations des actions utilisées
import { PUT_ALBUMS_SHARED_WITH_ME_IN_STATE, PUT_SHARED_ALBUM_MEDIAS_IN_STATE } from '../../actions/album/sharedAlbums';
// Importation de la fonction permettant la création des urls pour l'affichage des médias
import { composeAlbumSharedThumbnail, composeUrlsForMedia } from '../functions';
import { Album } from '../../types/album';

const initialState: InitialState = {
    singleAlbum: {
        id: '',
        title: '',
        medias: [],
        sharingContacts: []
    },
    albumsSharedWithMe: [],
}

function sharedAlbumsReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case PUT_SHARED_ALBUM_MEDIAS_IN_STATE:
            {
                const sharedAlbumMedias = action.medias.map((media: Media) => {
                    // mise en place des urls pour l'affichage des médias en fonction des dimensions souhaitées (album, lightbox, etc)
                    const mediaShared = composeUrlsForMedia(media)
                    return mediaShared
                })

                return ({
                    ...state,
                    singleAlbum: {
                        id: action.id,
                        title: action.title,
                        medias: sharedAlbumMedias,
                        sharingContacts: []
                    }
                })
            }

        case PUT_ALBUMS_SHARED_WITH_ME_IN_STATE:
            const albumsSharedWithMe = action.albums.map((album: Album) => {
                // mise en place de la vignette d'affichage utilisée pour la présentation de la liste des albums
                const albumWithThumbnail = composeAlbumSharedThumbnail(album)
                return albumWithThumbnail
            })
            return ({
                ...state,
                albumsSharedWithMe: albumsSharedWithMe,
            })

        default:
            return state
    }
}

export default sharedAlbumsReducer;