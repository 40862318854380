/*******************************************************************
        Composant pour l'upload (Drag and drop & input files)
*******************************************************************/

// Hooks
import { useAppDispatch } from '../../app/hooks';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FileRejection, useDropzone } from 'react-dropzone';
// Composants "locaux"
import UploadToast from './uploadToast';
// Stores
import { UploadStore } from '../../app/store/media';
// Actions
import { putUploadFileInState, updateFilesToUploadQueue, uploadMedia } from '../../app/actions/media/upload';
// Types
import { MediaFileToUpload } from '../../app/types/media';
import { ChangeEvent } from 'react';
import { DropEvent } from 'react-dropzone';
import { LegacyRef } from 'react';
// Styles
import './upload.scss';

// Déclaration du composant
const Upload: Function = ({ filesUploadDialog }: { filesUploadDialog: LegacyRef<HTMLInputElement> | undefined }): JSX.Element => {
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();
    // Récupération des paramètres d'url
    const { albumId } = useParams();

    // Récupération des states
    const { mediaFilesToUpload } = UploadStore();

    /**
     * Fonction qui se déclenche au drop de fichier(s)
     * Les fichiers déversés sont envoyés au store
     * 
     * @param files 
     */
    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
        event.stopPropagation()
        const droppedFilesToUpload: MediaFileToUpload[] = acceptedFiles.map((file: File) => {
            return { file: file, status: 'to send' }
        })
        dispatch(putUploadFileInState(droppedFilesToUpload));
        // Masquage de la zone de drag and drop
        rootRef.current!.style.visibility = '';
    }

    /**
    * Fonction qui se déclenche lors de la sélection de fichier(s) en boîte de dialogue 
    * Les fichiers sélectionnés sont envoyés au store
    * 
    * @param e 
    */
    const onChange: React.ChangeEventHandler = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        const filesListToArray: File[] = Array.from(target.files!);
        const inputFilesToUpload: MediaFileToUpload[] = filesListToArray.map((file: File) => ({ file: file, status: 'to send' }));
        dispatch(putUploadFileInState(inputFilesToUpload));
    }

    /**
     * Fonction déclanchant l'upload du média à chaque fois que la liste de médias à uploader est mise à jour (voir le useEffect)
     * 
     * @param mediaFilesToUpload
     */
    const upload = (mediaFilesToUpload: MediaFileToUpload[]) => {
        if (mediaFilesToUpload.length !== 0) {
            // si la liste des médias à envoyer ne contient aucun média ayant le status 'sending' (en cours d'envoi)
            // ou contient moins de 3 médias ayant le statut 'sending'
            // on recherche le premier média de la liste ayant le statut 'to send' (à envoyer) et on l'envoie
            const sendingFile: MediaFileToUpload[] = mediaFilesToUpload.filter(file => file.status === 'sending');
            if (sendingFile.length >= 0 && sendingFile.length < 3) {
                const newFileInQueue: MediaFileToUpload = mediaFilesToUpload.find(file => file.status === 'to send')!;
                if (newFileInQueue) {
                    // Upload au sein d'un album ou au sein de all-media
                    if (pathname.includes('/album/')) {
                        dispatch(uploadMedia(newFileInQueue.file, albumId));
                    } else {
                        dispatch(uploadMedia(newFileInQueue.file, undefined));
                    }
                    // si la liste des médias en cours d'envoi contient moins de trois médias et qu'il ne reste aucun média à envoyer
                    // on met à jour la liste des médias à uploader dans le store
                } else {
                    dispatch(updateFilesToUploadQueue());
                }
                // Si la liste des médias en cours d'envoi à atteint un maximum de trois éléments
                // on met à jour la liste des médias à uploader dans le store
            } else if (sendingFile.length === 3) {
                dispatch(updateFilesToUploadQueue());
            }
        }
    }

    // configuration de React Dropzonne
    const { getRootProps, rootRef } = useDropzone({
        accept: 'image/*,video/*',
        onDrop: onDrop,
    })


    // Effets de bord
    // la dropzone est initialement rendu avec une visibilité cachée (hidden)
    // lorsqu'un glissemenet (drag) de fichiers(s) est détecté sur la feneêtre, la dropzone est rendue visible 
    // sur l'ensemble de la fenêtre y compris le header et le footer
    // si on quitte la fenêtre ou que le fichier est déposé (voir la fonction onDrop ci-dessus), 
    // la dropzone repasse à nouveau en visibilité cachée afin de pouvoir rendre accessible les autres fonctionnalités de la page
    useEffect(() => {
        window.addEventListener('dragenter', () => rootRef.current!.style.visibility = 'visible')
        rootRef.current!.addEventListener('dragleave', () => rootRef.current!.style.visibility = '')
    }, [rootRef])

    // Fonction upload toujours en appel sur la page
    useEffect(() => {
        upload(mediaFilesToUpload);
    })

    return (
        <div className="upload">
            <div {...getRootProps({ className: 'dropzone' })} />
            <input type="file" className='input-dropzone' ref={filesUploadDialog} onChange={onChange} multiple />
            <UploadToast />
        </div>
    );
}

export default Upload;