import { buttonsFRtranslation } from "./components/Buttons/buttonsFRtranslation";
import { albumFRtranslation } from "./components/features/albumFRtranslation";
import { feedbackFRtranslation } from "./components/features/feedbackFRtranslation";
import { footerFRtranslation } from "./components/features/footerFRtranslation";
import { headerFRtranslation } from "./components/features/headerFRtranslation";
import { homeFRtranslation } from "./components/features/homeFRtranslation";
import { lightboxFRtranslation } from "./components/features/lightboxFRtranslation";
import { loginFRtranslation } from "./components/features/loginFRtranslation";
import { lostPasswordFRtranslation } from "./components/features/lostPasswordFRtranslation";
import { mediasFRtranslation } from "./components/features/mediasFRtranslation";
import { navabarFRtranslation } from "./components/features/navbarFRtranslation";
import { profileFRtranslation } from "./components/features/profileFRtranslation";
import { uploadFRtranslation } from "./components/features/uploadFRtranslation";
import { modalsFRtranslation } from "./components/modals/modalsFRtranslation";
import { popoverFRtranslation } from "./components/Popover/popoverFRtranslation";

export const translationFR = {
    "components": {
        "modals": {
            ...modalsFRtranslation
        },
        "buttons": {
            ...buttonsFRtranslation
        },
        "popover": {
            ...popoverFRtranslation
        }
    },
    "features": {
        "album": {
            ...albumFRtranslation
        },
        "login": {
            ...loginFRtranslation
        },
        "feedback": {
            ...feedbackFRtranslation
        },
        "footer": {
            ...footerFRtranslation
        },
        "header": {
            ...headerFRtranslation
        },
        "home": {
            ...homeFRtranslation
        },
        "lightbox": {
            ...lightboxFRtranslation
        },
        "lostPassword": {
            ...lostPasswordFRtranslation
        },
        "medias": {
            ...mediasFRtranslation
        },
        "layout": {
            ...navabarFRtranslation
        },
        "profile": {
            ...profileFRtranslation
        },
        "upload": {
            ...uploadFRtranslation
        }
    }
}