/*******************************************************************
                Modal pour la création d'un album
*******************************************************************/

// Hooks
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
// Composants de librairies
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
// Compsoants locaux
import PrimaryButton from "../Buttons/primaryButton";
import SecondaryButton from "../Buttons/secondaryButton";
import Field from "../Field/field";
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields";
import { LightboxLayoutStore, ModalsLayoutStore } from "../../app/store/layout";
import { SingleAlbumStore } from "../../app/store/album";
// Actions
import { createAlbum, selectMediaForAlbum } from "../../app/actions/album/allAlbums";
import { handleNewAlbumTitle } from "../../app/actions/controlledFields";
import { createAlbumNextStep, showCreateAlbumModal } from "../../app/actions/layout/modals";
// Types
import { Media } from "../../app/types/media";

const CreateAlbum: Function = ({ allMedias }: { allMedias: Media[] }) => {
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();
    // Récupération des paramètres d'url
    const { albumId } = useParams();

    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.createAlbum' })

    // Dispather d'action Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const { title } = ControlledFieldsStore().newAlbum;
    const { createAlbum: createAlbumModal } = ModalsLayoutStore();
    const { open: lightboxIsOpen, currentSlide } = LightboxLayoutStore();
    const { singleAlbum } = SingleAlbumStore();

    // On détermine si la demande de création a été faire depuis un album à partir de la lightbox
    // Si OUI, on récupère le média de la slide à part de laquelle a été faite la demande de création
    // Si NON, on récupère la liste de tous les médias
    const createFromOtherAlbum = lightboxIsOpen && pathname.includes('/album/') && albumId !== undefined ? true : false
    const mediasSelection = createFromOtherAlbum ? [singleAlbum.medias[currentSlide!]] : allMedias

    return (
        <Modal show={createAlbumModal.show}>
            <Modal.Body>
                {!createAlbumModal.firstStep && (
                    <div
                        className="closeIcon"
                        onClick={() => {
                            dispatch(showCreateAlbumModal(false))
                            dispatch(handleNewAlbumTitle(''))
                            dispatch(createAlbumNextStep(false))
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </div>
                )}
                <form action="/all-albums" method="post" id="create-album" onSubmit={(e) => {
                    e.preventDefault()

                    if (createFromOtherAlbum) {
                        dispatch(selectMediaForAlbum(singleAlbum.medias[currentSlide!]._id))
                    }

                    dispatch(createAlbum(title))
                }}>
                    {/* Affichage / masquage du champ titre en fonction de l'étape de création */}
                    <Field
                        className={createAlbumModal.firstStep ? "newAlbum-title" : "newAlbum-title newAlbum-title--hide"}
                        labelHtml="newAlbum-title"
                        labelName={t('labels.title')}
                        inputType="text"
                        inputName="newAlbum-title"
                        required={true}
                        inputValue={title}
                        maxLength={40}
                        action={
                            (e: React.ChangeEvent<HTMLInputElement>) => dispatch(handleNewAlbumTitle(e.target.value))
                        }
                    />
                </form>

                {/* Sélection des médias (ÉTAPE 2 de la création si on ne la fait pas à partir d'une lightbox) */}
                {mediasSelection.length !== 0 && !createAlbumModal.firstStep && (
                    <>
                        <div className="medias-selection">
                            {lightboxIsOpen ? t('medias-selection.in-lightbox') : t('medias-selection.in-all-albums')}
                        </div>
                        <div className="all-medias">
                            {mediasSelection.map((media) => (
                                <div className="media-item" key={media._id}>
                                    <picture>
                                        <source srcSet={media.url_album.webp} />
                                        <img
                                            className={createFromOtherAlbum ? 'selected' : ''}
                                            alt=""
                                            src={media.url_album.jpg}
                                            onClick={(e) => {
                                                if (!createFromOtherAlbum) {
                                                    dispatch(selectMediaForAlbum(media._id));
                                                    e.currentTarget.classList.toggle("selected");
                                                }
                                            }}
                                        />

                                        {/* Filtre rouge indiquant la sélection sur le média */}
                                        <div
                                            className="overlay"
                                            onClick={(e) => {
                                                if (!createFromOtherAlbum) {
                                                    dispatch(selectMediaForAlbum(media._id));
                                                    e.currentTarget.previousElementSibling!.classList.toggle("selected")
                                                }
                                            }}
                                        />
                                    </picture>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <SecondaryButton
                    // Bouton "Annuler" pour l'étape 1 sinon "Précédent" pour l'étape 2 (et changer le titre)
                    buttonName={createAlbumModal.firstStep ? t('buttons.secondary.first-step') : t('buttons.secondary.second-step')}
                    onClick={() => {
                        createAlbumModal.firstStep ? dispatch(showCreateAlbumModal(false)) : dispatch(createAlbumNextStep(false))
                    }}
                />
                <PrimaryButton
                    buttonType="submit"
                    // Bouton "Suivant" pour l'étape 1 / "Valider" pour l'étape 2
                    buttonName={createAlbumModal.firstStep ? t('buttons.primary.first-step') : t('buttons.primary.second-step')}
                    formAttached="create-album"
                />
            </Modal.Footer>
        </Modal>
    )
}

export default CreateAlbum;