import { Album } from "../../types/album";
import { Media } from "../../types/media";

// Vérouillage du nom des actions
export const DELETE_ALBUM: string = 'DELETE_ALBUM';
export const DUPLICATE_ALBUM: string = 'DUPLICATE_ALBUM';
export const DELETE_MEDIA_IN_ALBUM: string = 'DELETE_MEDIA_IN_ALBUM';
export const POPULATE_SINGLE_ALBUM_IN_STATE: string = 'POPULATE_SINGLE_ALBUM_IN_STATE';
export const ADD_MEDIAS_TO_ALBUM: string = 'ADD_MEDIAS_TO_ALBUM';
export const UPDATE_ALBUM_TITLE: string = 'UPDATE_ALBUM_TITLE';
export const EDIT_SINGLE_ALBUM_TITLE_IN_STATE: string = 'EDIT_SINGLE_ALBUM_TITLE_IN_STATE';
export const MEDIA_UPLOADING_IN_ALBUM: string = 'MEDIA_UPLOADING_IN_ALBUM';
export const ADD_NEW_UPLOADED_MEDIA_TO_ALBUM: string = 'ADD_ NEW_UPLOADED_MEDIA_TO_ALBUM';
export const SHARE_ALBUM: string = 'SHARE_ALBUM';
export const UPDATE_SHARING_CONTACTS_IN_SINGLE_ALBUM: string = 'UPDATE_SHARING_CONTACTS_IN_SINGLE_ALBUM';

// Supprimer un album => middleware
export function deleteAlbum(albumId: string) {
    return ({
        type: DELETE_ALBUM,
        albumId: albumId
    })
}


// Duplique un album => middleware
export function duplicateAlbum(albumId: string) {
    return ({
        type: DUPLICATE_ALBUM,
        albumId: albumId
    })
}

// Supprimer un média dans un album => middleware
export function deleteMediaInAlbum(albumId: string, mediaId: string) {
    return ({
        type: DELETE_MEDIA_IN_ALBUM,
        albumId: albumId,
        mediaId: mediaId
    })
}

// Peupler le store avec le contenu d'un album => reducer
export function populateSingleAlbumInState(albumId: string | undefined, allMedias: Media[], allAlbums: Album[]) {
    return ({
        type: POPULATE_SINGLE_ALBUM_IN_STATE,
        albumId: albumId,
        allMedias: allMedias,
        allAlbums: allAlbums
    })
}

// ajouter un média dans un album depuis la liste des médias => middleware
export function addMediasToAlbum(albumId: string) {
    return ({
        type: ADD_MEDIAS_TO_ALBUM,
        albumId: albumId,
    })
}

// Modifier le titre d'un album => middleware
export function updateAlbumTitle() {
    return ({
        type: UPDATE_ALBUM_TITLE,
    })
}

// Mettre à jour le titre d'un album dans le store => reducer
export function editSingleAlbumTitleInState(title: string) {
    return ({
        type: EDIT_SINGLE_ALBUM_TITLE_IN_STATE,
        title: title
    })
}

// Savoir si un media est uploadé depuis la page d'un album (et pas depuis /all-medias) => reducer
export function mediaUploadingInAlbum(uploading: boolean) {
    return ({
        type: MEDIA_UPLOADING_IN_ALBUM,
        uploading: uploading
    })
}

// Ajouter un nouveau media uploadé depuis l'ordinateur de l'utilisateur à la liste des médias de l'album en question => reducer
export function addNewUploadedMediaToAlbum(media: Media) {
    return ({
        type: ADD_NEW_UPLOADED_MEDIA_TO_ALBUM,
        media: media
    })
}

// partager un album => middleware
export function shareAlbum(typeShare: string) {
    return ({
        type: SHARE_ALBUM,
        typeShare: typeShare
    })
}

// Mettre à jour la liste des emails avec lesquels l'album est partagé => reducer
export function updateSharingContactsInSingleAlbum(action: string, id: string, email: string) {
    return ({
        type: UPDATE_SHARING_CONTACTS_IN_SINGLE_ALBUM,
        action: action,
        id: id,
        email: email
    })
}