
// typage des propos pour le composant, exporté pour les données dynamiques dans les composants concernés
export type FieldProps = {
    className: string | undefined
    labelHtml: string,
    labelName: string,
    placeholder: string | undefined
    inputType: string,
    inputName: string,
    required: boolean,
    inputValue: string,
    maxLength: number
    action: (event: React.ChangeEvent<HTMLInputElement>) => any,
};

const Field: Function = ({
    className,
    labelHtml,
    labelName,
    placeholder,
    inputType,
    inputName,
    required,
    inputValue,
    maxLength,
    action
}: FieldProps): JSX.Element => {
    return (
        <div className={`form-group mt-2 ${className ? className : ''}`}>
            <label htmlFor={labelHtml}>
                {labelName}
                <input
                    autoComplete="off"
                    placeholder={placeholder !== undefined ? placeholder : ''}
                    required={required}
                    className="form-control"
                    id={labelHtml}
                    type={inputType}
                    name={inputName}
                    value={inputValue}
                    maxLength={maxLength}
                    onChange={action}
                />
            </label>
        </div>
    );
}

export default Field;
