/// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../types/states/controlledFields';
// Importations des actions utilisées
import {
    CLEAR_FIELDS,
    HANDLE_COMMENT_TO_SEND,
    HANDLE_EMAIL_FOR_SHARE,
    HANDLE_USER_FIELDS,
    HANDLE_NEW_ALBUM_TITLE,
    HANDLE_SELECTED_ALBUM,
    HANDLE_SINGLE_ALBUM_TITLE,
    HANDLE_FEEDBACK,
    HANDLE_ANSWERKISS_FIELDS
} from '../actions/controlledFields';

const initialState: InitialState = {
    user: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        resetPassword: '',
    },
    answerkiss: {
        question: '',
        answer: ''
    },
    newAlbum: {
        title: ''
    },
    addMediaToAlbum: {
        selectedAlbum: ''
    },
    singleAlbum: {
        title: '',
        emailForShare: '',
    },
    commentToSend: '',
    feedback: ''
};

function controlledFieldsReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {
        case HANDLE_USER_FIELDS:
            return ({
                ...state,
                user: {
                    ...state.user,
                    [action.field]: action.fieldValue
                }
            });
        case HANDLE_NEW_ALBUM_TITLE:
            return ({
                ...state,
                newAlbum: {
                    title: action.fieldValue
                }
            })
        case HANDLE_SELECTED_ALBUM:
            return ({
                ...state,
                addMediaToAlbum: {
                    selectedAlbum: action.selectedAlbum
                }
            })
        case HANDLE_SINGLE_ALBUM_TITLE:
            return ({
                ...state,
                singleAlbum: {
                    ...state.singleAlbum,
                    title: action.albumTitle
                }
            })
        case HANDLE_EMAIL_FOR_SHARE:
            return ({
                ...state,
                singleAlbum: {
                    ...state.singleAlbum,
                    emailForShare: action.email,
                }
            })
        case HANDLE_COMMENT_TO_SEND:
            return ({
                ...state,
                commentToSend: action.comment,
            })
        case HANDLE_FEEDBACK:
            return ({
                ...state,
                feedback: action.feedback
            })
        case HANDLE_ANSWERKISS_FIELDS:
            return ({
                ...state,
                answerkiss: {
                    ...state.answerkiss,
                    [action.field]: action.fieldValue
                }
            })
        case CLEAR_FIELDS:
            {
                const fieldsList: 'user' | 'answerkiss' | 'newAlbum' | 'addMediaToAlbum' | 'singleAlbum' | 'commentToSend' | 'feedback' = action.fieldsList
                // réinitialisation d'une liste de champs
                return ({
                    ...state,
                    [fieldsList]: initialState[fieldsList],
                })
            }
        default:
            return state;
    }
}

export default controlledFieldsReducer;