/*******************************************************************
   Modal pour l'ajout d'un média dans une liste d'album existante
    (à partir de la lightbox)
*******************************************************************/

// Hooks
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks"
// Composants de librairies
import { Modal } from "react-bootstrap"
// Composant "locaux"
import PrimaryButton from "../Buttons/primaryButton";
import SecondaryButton from "../Buttons/secondaryButton";
// Stores
import { ModalsLayoutStore } from "../../app/store/layout";
import { AllAlbumsStore } from "../../app/store/album";
import { ControlledFieldsStore } from "../../app/store/controlledFields";
// Actions
import { getAllAlbums, selectMediaForAlbum } from "../../app/actions/album/allAlbums";
import { addMediasToAlbum } from "../../app/actions/album/singleAlbum";
import { handleSelectedAlbum } from "../../app/actions/controlledFields";
import { addToAlbumModal, showCreateAlbumModal } from "../../app/actions/layout/modals";
// Types
import { Album } from "../../app/types/album";

const AddToAlbumList: Function = ({ mediaToAdd }: { mediaToAdd: string }) => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.addToAlbumList' })

    // Récupération du chemin dans l'url
    const { pathname } = useLocation();

    // Récupération des paramètres d'url
    const { albumId } = useParams();

    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch()

    // Récupération des states
    const { updateAlbum } = ModalsLayoutStore();
    const { allAlbums } = AllAlbumsStore();
    const { selectedAlbum } = ControlledFieldsStore().addMediaToAlbum;

    // Effet de bord :
    // Récupération de la liste des albums en BDD au chargement du composant
    useEffect(() => {
        dispatch(getAllAlbums());
    }, [dispatch])

    // On affine la liste des albums :
    // Si la lightbox a été ouverte depuis un album en particulier (url contenant '/album/') :
    //   on retire de la liste des albums, l'album dans lequel on se trouve
    // Si la lightbox a été ouverte depuis la liste de tous les médias (url contenant '/all-medias') :
    //   on garde la liste de tous les albums
    let albumsList: Album[] = []
    if (pathname.includes('/album/')) {
        albumsList = allAlbums.filter((album: Album) => album._id !== albumId)
    } else if (pathname.includes('/all-medias')) {
        albumsList = allAlbums;
    }

    return (
        <Modal show={updateAlbum.show} id="updateAlbum-modal">
            <Modal.Body>
                {albumsList.length !== 0 && (
                    <form method="post" id="update-album" onSubmit={(e) => {
                        e.preventDefault()
                        // Si la valeur de la sélection depuis select est vide alors on prend comme valeur par défaut le premier select de la liste 
                        const album = selectedAlbum === "" ? albumsList[0]._id : selectedAlbum;
                        // On peuple le tableau qui reçoit le média sélectionné dans le store
                        dispatch(selectMediaForAlbum(mediaToAdd));
                        // Requête => middleware
                        dispatch(addMediasToAlbum(album));
                        // Fermeture du modal
                        dispatch(addToAlbumModal(false));
                    }}>
                        <label htmlFor="albums-list">
                            <span>{t('labels.albums-list')}</span>
                            <select
                                name="albums-list"
                                id="albums-list"
                                required
                                // "champ contrôlé" pour la sélection de l'album
                                value={selectedAlbum}
                                onChange={(e) => {
                                    dispatch(handleSelectedAlbum(e.target.value))
                                }}
                            >
                                {albumsList.map(album => (
                                    <option key={album._id} value={album._id}>
                                        {album.title}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </form>
                )}

                {/* message dans le cas où l'utilisateur n'a encore aucun album */}
                {albumsList.length === 0 && (
                    <div className="no-album-message">{t('no-album-message')}</div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton
                    buttonName={t('buttons.secondary')}
                    onClick={() => dispatch(addToAlbumModal(false))}
                />
                {albumsList.length !== 0 ?
                    // Bouton "ajouter" si on a déjà des albums
                    <PrimaryButton buttonType="submit" buttonName={t('buttons.primary.add')} formAttached="update-album" />
                    :
                    // Bouton "créer un album" si on a encore aucun album
                    <PrimaryButton buttonType="text" buttonName={t('buttons.primary.create')} onClick={() => {
                        // fermeture du présent modal
                        dispatch(addToAlbumModal(false))
                        // ouverture du modal pour la création d'album
                        dispatch(showCreateAlbumModal(true))
                    }}
                    />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default AddToAlbumList;