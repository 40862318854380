// Vérouillage du nom des actions
export const OPEN_ALL_MEDIAS_MENU: string = 'OPEN_ALL_MEDIAS_MENU';
export const OPEN_CAN_I_LIKE_OR_COMMENT_POPOVER: string = 'OPEN_CAN_I_LIKE_OR_COMMENT_POPOVER';
export const GET_COMMENTS_OFFCANVAS_SIZE: string = 'GET_COMMENTS_OFFCANVAS_SIZE';
export const EDIT_ANSWERKISS: string = 'EDIT_ANSWERKISS';

// Ouvrir le menu de la page /all-medias pour les options d'ajout (photos/vidéos ou answerkiss) => reducer
export function openAllMediasMenu(open: boolean) {
    return ({
        type: OPEN_ALL_MEDIAS_MENU,
        open: open
    })
}

// Affichage du popover indiquant qu'il faut être connecté pour liker ou commenter => reducer
export function openCanILikeOrCommentPopover(open: boolean, mediaId: string, action: string, refContainer: string | null) {
    return ({
        type: OPEN_CAN_I_LIKE_OR_COMMENT_POPOVER,
        open: open,
        mediaId: mediaId,
        action: action,
        refContainer: refContainer
    })
}

// Récupération de la largeur de la boîte de commentaires => reducer
// (permet de recalculer la largeur du médias dans le slide lors les commentaires sont ouverts)
export function getCommentsOffcanvasSize(width: number, height: number) {
    return ({
        type: GET_COMMENTS_OFFCANVAS_SIZE,
        width: width,
        height: height
    })
}

// Indique si l'answerkiss est en mode édition pour afficher les champs préremplis du modal answerkiss => reducer
export function editAnswerkiss(edit: boolean) {
    return ({
        type: EDIT_ANSWERKISS,
        edit: edit
    })
}



