/*******************************************************************
        Modal pour la confirmation d'une suppression
*******************************************************************/

// Hooks
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
// Composants de librairies
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// Composants "locaux"
import ButtonWithTooltip from "../Buttons/buttonWithTooltip";
import LinkButton from "../Buttons/linkButton";
import PrimaryButton from "../Buttons/primaryButton";
import Field from "../Field/field";
import DeleteConfirmation from "./deleteConfirmation";
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields";
import { ModalsLayoutStore } from "../../app/store/layout";
// Actions
import { deleteShare, suggestShares } from "../../app/actions/album/sharedAlbums";
import { shareAlbum } from "../../app/actions/album/singleAlbum";
import { handleEmailForShare } from "../../app/actions/controlledFields";
import { clearSuggestedShares, confirmDeleteShare, openShareAlbumModal } from "../../app/actions/layout/modals";
// Url de l'application pour la constitution du lien public
const { REACT_APP_URL: Url } = process.env;

const ShareAlbumModal: Function = ({ albumId, sharingContacts }: { albumId: string, sharingContacts: { id: string, email: string }[] }) => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.shareAlbumModal' })

    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const shareModal = ModalsLayoutStore().shareAlbum
    const { emailForShare } = ControlledFieldsStore().singleAlbum
    const deleteShareModal = ModalsLayoutStore().deleteShare
    const { suggestedShares, emailAlreadyInShares } = ModalsLayoutStore().shareAlbum;

    // Récupération de l'id du lien public si il en existe un
    const publicShareId = sharingContacts.find(contact => contact.email === "partage public")?.id;

    // Effet de bord
    // Mise à 0 du champ de partages au chargement du composant
    useEffect(() => {
        dispatch(handleEmailForShare(''))
    }, [dispatch])

    // Récupération de la largeur d'écran pour afficher une icône de corbeille au lieu du lien "supprimer" pour les petits écrans
    const deviceForSharingList = window.innerWidth > 525 ? 'large' : 'small';
    const deviceForPublicShare = window.innerWidth > 875 ? 'large' : 'small';

    return (
        <Modal id="shareAlbum-modal" className={deleteShareModal.show ? 'shareAlbum-modal--fade' : ''} show={shareModal.show} backdropClassName={deleteShareModal.show ? 'shareAlbum-backdrop--fade' : ''}>
            <Modal.Header>
                <LinkButton
                    buttonName={t('buttons.close')}
                    onClick={() => {
                        dispatch(openShareAlbumModal(false))
                        dispatch(clearSuggestedShares())
                        dispatch(handleEmailForShare(''))
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                <form id="shareAlbum" onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(shareAlbum('private'))
                }}>
                    <div className="shareAlbum-field">
                        <Field
                            className="shareAlbum"
                            labelHtml="shareAlbum-email"
                            labelName={t('labels.share-email')}
                            placeholder={t('placeholders.share-email')}
                            inputType="email"
                            inputName="shareAlbum-input"
                            required={true}
                            maxLength={null}
                            // champ contrôlé pour l'email de partage
                            // À partir de 3 caractères, on envoie une requête au serveur pour avoir une liste de suggestion
                            // d'emails déjà saisis auparavant
                            inputValue={emailForShare}
                            action={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch(handleEmailForShare(e.target.value))
                                    if (emailForShare.length >= 3) {
                                        dispatch(suggestShares())
                                    }
                                }
                            }
                        />

                        {/* message en cas d'email déjà dans la liste de partage */}
                        {emailAlreadyInShares && (
                            <div className="email-already-shared">
                                {t('message.already-shared')}
                            </div>
                        )}

                        {/* Liste d'emails suggérés si il y en a */}
                        {suggestedShares.length !== 0 && emailForShare.length >= 3 && (
                            <ul className="suggested-shares">
                                {suggestedShares.map((share: string) => (
                                    <li
                                        key={Math.random().toString(12)}
                                        onClick={() => {
                                            dispatch(handleEmailForShare(share))
                                            dispatch(clearSuggestedShares())
                                        }}
                                    >
                                        {share}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <PrimaryButton buttonType="submit" buttonName="Partager" formAttached="shareAlbum" />
                </form>

                {/* Liste des partages déjà existants pour l'album en question (en dehors du partage public) */}
                {sharingContacts.length !== 0 && (
                    <>
                        <div className="sharing-contacts">{t('sharing-contacts')}</div>
                        <ul className="sharing-contacts-list">
                            {[...sharingContacts?.filter(contact => contact.email !== 'partage public')].map(contact => (
                                <li className="contact-item" key={contact.id}>
                                    <div className="contact-email">{contact.email}</div>
                                    {deviceForSharingList !== 'small' ?
                                        <LinkButton buttonType="button" buttonName={ t('tooltip.delete') } onClick={() => dispatch(confirmDeleteShare(true, contact.id))} />
                                        :
                                        <ButtonWithTooltip
                                            trigger={['hover', 'click']}
                                            tooltip={{
                                                placement: "bottom",
                                                classname: "deleteShare-tooltips",
                                                content: t('tooltip.delete')
                                            }}
                                            button={{ classname: "deleteShare-button" }}
                                            icon={{
                                                icon: faTrashCan,
                                                classname: "deleteShare-icon"
                                            }}
                                            onClick={() => dispatch(confirmDeleteShare(true, contact.id))}
                                        />
                                    }
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {/* Partage public - Si on a déjà un lien de partage public on l'affiche sinon on explique la procédure */}
                <div className="public-share">{t('public-share.label')}</div>
                {sharingContacts.find(contact => contact.email === "partage public") === undefined ?
                    <>
                        <p>{t('public-share.message.not-shared')}</p>
                        <PrimaryButton buttonName={t('public-share.button')} onClick={() => dispatch(shareAlbum('public'))} />
                    </>
                    :
                    <>
                        <p>{t('public-share.message.shared')}</p>
                        <div className="url-public-share">
                            <span>{t('public-share.link.label')}</span>
                            <div className="url">
                                <Link
                                    to={`/share/${albumId}/${publicShareId}`}
                                    onClick={() => dispatch(openShareAlbumModal(false))}
                                >
                                    {`${Url}/share/${albumId}/${publicShareId}`}
                                </Link>

                                {deviceForPublicShare !== 'small' ?
                                    <LinkButton buttonType="button" buttonName={t('public-share.link.delete')} onClick={() => dispatch(confirmDeleteShare(true, publicShareId))} />
                                    :
                                    <ButtonWithTooltip
                                        trigger={['hover', 'click']}
                                        tooltip={{
                                            placement: "bottom",
                                            classname: "deleteShare-tooltips",
                                            content: t('public-share.link.tooltip')
                                        }}
                                        button={{ classname: "deleteShare-button" }}
                                        icon={{
                                            icon: faTrashCan,
                                            classname: "deleteShare-icon"
                                        }}
                                        onClick={() => dispatch(confirmDeleteShare(true, publicShareId))}
                                    />
                                }
                            </div>
                        </div>
                    </>
                }

            </Modal.Body>

            <DeleteConfirmation
                specificModal={deleteShareModal}
                message={
                    sharingContacts?.find(contact => contact.id === deleteShareModal.shareToDelete!)?.email !== 'partage public' ?
                        `${t('delete-modal-message.private-share')}${sharingContacts?.find(contact => contact.id === deleteShareModal.shareToDelete!)?.email} ?`
                        :
                        t('delete-modal-message.public-share')
                }
                closeModal={() => dispatch(confirmDeleteShare(false, undefined))}
                deleteAction={() => {
                    dispatch(deleteShare())
                    dispatch(confirmDeleteShare(false, undefined))
                }}
            />
        </Modal>
    );
}

export default ShareAlbumModal