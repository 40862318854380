// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/album';
// Importations des actions utilisées
import {
    DISPLAY_ALBUM_SHARED,
    EDIT_SINGLE_ALBUM_TITLE,
    GET_RESPONSIVE_CONTAINER_WIDTH,
    OPEN_SINGLE_ALBUM_MENU
} from '../../actions/layout/album';


const initialState: InitialState = {
    editSingleAlbumTitle: false,
    openSingleAlbumMenu: false,
    displayAlbumShared: true,
    allMediasContainerWidth: 0,
}

function albumLayoutReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case EDIT_SINGLE_ALBUM_TITLE:
            return ({
                ...state,
                editSingleAlbumTitle: action.edit
            })

        case OPEN_SINGLE_ALBUM_MENU:
            return ({
                ...state,
                openSingleAlbumMenu: action.open
            })

        case DISPLAY_ALBUM_SHARED:
            return ({
                ...state,
                displayAlbumShared: action.display
            })

        case GET_RESPONSIVE_CONTAINER_WIDTH:
            return ({
                ...state,
                allMediasContainerWidth: action.width
            })

        default:
            return state
    }
}

export default albumLayoutReducer;
