// Typage des props
type LinkButtonProps = {
    buttonName: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => any,
};

const LinkButton: Function = ({ buttonName, onClick }: LinkButtonProps): JSX.Element => (
    <button className="btn btn-link" onClick={onClick}>{buttonName}</button>
);

export default LinkButton;
