import allAlbums from "../../../../../../features/album/allAlbums/languages/fr/allAlbums.json"
import singleAlbum from "../../../../../../features/album/singleAlbum/languages/fr/singleAlbum.json"

export const albumFRtranslation = {
    "allAlbums": {
        ...allAlbums
    },
    "singleAlbum": {
        ...singleAlbum
    }
}