/*******************************************************************
              Champs pour le form d'inscription
*******************************************************************/

// Hooks
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
// Composants "locaux"
import Field from "../../components/Field/field";
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields";
// Actions
import { handleUserFields } from "../../app/actions/controlledFields";

const RegisterFields: Function = () => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.login.register.fields' })
    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch()

    // Récupération des states
    const { firstname, lastname } = ControlledFieldsStore().user

    // Liste des champs
    const registerFieldsProps = [
        {
            labelHtml: 'firstname-input',
            labelName: t('firstname'),
            placeholder: undefined,
            inputType: 'text',
            inputName: 'firstname',
            required: true,
            maxLength: 1000,
            // Champ contrôlé
            action: (e: React.ChangeEvent<HTMLInputElement>) => { dispatch(handleUserFields('firstname', e.target.value)) },
            inputValue: firstname
        },

        {
            labelHtml: 'lastname-input',
            labelName: t('lastname'),
            placeholder: undefined,
            inputType: 'text',
            inputName: 'lastname',
            required: true,
            maxLength: 1000,
            // Champ contrôlé
            action: (e: React.ChangeEvent<HTMLInputElement>) => { dispatch(handleUserFields('lastname', e.target.value)) },
            inputValue: lastname
        }
    ];

    return (
        registerFieldsProps.map(({ labelHtml, labelName, inputType, inputName, required, maxLength, action, inputValue }): JSX.Element => (
            <Field
                className={undefined}
                key={labelHtml}
                labelHtml={labelHtml}
                labelName={labelName}
                inputType={inputType}
                inputName={inputName}
                required={required}
                inputValue={inputValue}
                maxLength={maxLength}
                action={action}
            />
        ))
    )
}

export default RegisterFields