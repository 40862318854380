// Hooks
import { useTranslation } from "react-i18next";
// Composants de librairies
import { Button, Spinner } from "react-bootstrap";

const LoaderButton: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.buttons.loader' });

    return (
        <Button id="button-loader">
            <Spinner
                className="spinner"
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            {t('message')}
        </Button>
    );
}

export default LoaderButton;