/*******************************************************************
          Titre d'un album avec mode édition (input caché)
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
// Composants "locaux"
import PrimaryButton from "../../../components/Buttons/primaryButton";
import SecondaryButton from "../../../components/Buttons/secondaryButton";
// Stores
import { ControlledFieldsStore } from "../../../app/store/controlledFields";
import { AlbumLayoutStore } from "../../../app/store/layout";
// Actions
import { updateAlbumTitle } from "../../../app/actions/album/singleAlbum";
import { handleSingleAlbumTitle } from "../../../app/actions/controlledFields";
import { editSingleAlbumTitle } from "../../../app/actions/layout/album";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

const SingleAlbumTitle: Function = ({ title, albumShared}: { title: string, albumShared:boolean }): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.album.singleAlbum' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération des states
    const { editSingleAlbumTitle: editTitle, openSingleAlbumMenu: menuIsOpen } = AlbumLayoutStore();
    const { title: singleAlbumTitleField } = ControlledFieldsStore().singleAlbum;

    // Pour les largeurs d'écrans inférieurs à 576px,
    // on descend le titre lorsqu'il est en mode édition
    // pour ne pas gêner le menu
    const downTitle = window.innerWidth < 576 ? true : false;

    const isOnMobile: boolean = window.innerWidth <= 768 ? true : false

    // Hook de navigation
    const navigate = useNavigate();

    return (
        <div className="album-title">
            {editTitle === false ?
                <div className="title" style={{ marginTop: downTitle && menuIsOpen ? '40px' : '0' }}>
                    <h1> 
                    {isOnMobile && (
                        <FontAwesomeIcon className="returnToAlbumList" icon={faReply} onClick={() => { albumShared ? navigate('/shared-with-me') : navigate('/all-albums') }} />
                    )}
                    {title}
                    </h1>
                </div>
                :
                <div className="title-input">
                    <label htmlFor="album-title">
                        <input
                            required
                            type="text"
                            id="album-title"
                            maxLength={40}
                            // Champ contrôlé
                            value={singleAlbumTitleField}
                            onChange={(e) => dispatch(handleSingleAlbumTitle(e.currentTarget.value))}
                        />
                    </label>
                    <div className="buttons">
                        <SecondaryButton buttonName={t('edit-title.cancel')} onClick={() => dispatch(editSingleAlbumTitle(false))} />
                        <PrimaryButton buttonName={t('edit-title.validate')} onClick={() => dispatch(updateAlbumTitle())} />
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleAlbumTitle;