// verouillage du nom des actions
export const LOG_IN: string = 'LOG_IN';
export const REGISTER: string = 'REGISTER';
export const GET_USER_DATA: string = 'GET_USER_DATA';
export const CLEAR_USER_DATA: string = 'CLEAR_USER_DATA';
export const USER_CONNECTED_AFTER_REGISTER: string = 'USER_CONNECTED_AFTER_REGISTER';
export const UPDATE_PROFILE: string = 'UPATE_PROFILE';
export const SEND_FEEDBACK: string = 'SEND_FEEDBACK';
export const RESET_PASSWORD: string = 'RESET_PASSWORD';
export const GET_TOKEN_FOR_RESET_PASSWORD: string = 'GET_TOKEN_FOR_RESET_PASSWORD';
export const ASK_FOR_CHANGING_PASSWORD: string = 'ASK_FOR_CHANGING_PASSWORD';
export const SEND_STATISTICS: string = 'SEND_STATISTICS';

// connexion de l'utilsiateur => middleware
export function logIn() {
    return ({
        type: LOG_IN,
    })
}

// inscription de l'utilisateur => middleware
export function register() {
    return ({
        type: REGISTER,
    })
}

// Enregistrement du statut connecté de l'utilisateur dans le store => reducer
export function userConnectedAfterRegister() {
    return ({
        type: USER_CONNECTED_AFTER_REGISTER
    })
}

// Enregistrement des données de l'utilisateur dans le store via le token 'user' en localstorage => reducer
export function getUserData() {
    return ({
        type: GET_USER_DATA,
    })
}

// Suppression des données de l'utilisateur dans le store (après déconnexion) => reducer
export function clearUserData() {
    return ({
        type: CLEAR_USER_DATA,
    })
}

// Modification des informations de profil de l'utilisateur => middleware
export function updateProfile() {
    return ({
        type: UPDATE_PROFILE
    })
}

// Envoi de feedback au serveur => middleware
export function sendFeedback() {
    return ({
        type: SEND_FEEDBACK
    })
}

// Envoyer une demande de changement de mot de passe au serveur
export function askForChangingPassword() {
    return ({
        type: ASK_FOR_CHANGING_PASSWORD
    })
}

// Récupérer le token en url permettant de réinitialiser le mot de passe
export function getTokenForResetPassword(token: string | undefined) {
    return ({
        type: GET_TOKEN_FOR_RESET_PASSWORD,
        token: token
    })
}

// Envoyer un novueau mot de passe
export function resetPassword() {
    return ({
        type: RESET_PASSWORD
    })
}

// Envoyer une indication au serveur si la page est visitée
export function sendStatistics() {
    return ({
        type: SEND_STATISTICS
    })
}