import { Album } from "../../types/album";

// Vérouillage des noms des actions
export const GET_ALL_ALBUMS: string = 'GET_ALL_ALBUMS';
export const PUT_ALL_ALBUMS_IN_STATE: string = 'PUT_ALL_ALBUMS_IN_STATE';
export const CREATE_ALBUM: string = 'CREATE_ALBUM';
export const SELECT_MEDIA_FOR_ALBUM: string = 'SELECT_MEDIA_FOR_ALBUM';
export const CLEAR_SELECTED_MEDIA_FOR_ALBUM: string = 'CLEAR_SELECTED_MEDIA_FOR_ALBUM';

// Obtenir la liste des albums => middleware
export function getAllAlbums() {
    return ({
        type: GET_ALL_ALBUMS
    })
}

// Mettre la liste des albums dans le store => Reducer
export function putAllAlbumsInState(albums: Album[]) {
    return ({
        type: PUT_ALL_ALBUMS_IN_STATE,
        albums: albums
    })
}

// Créer un nouvel album => Middleware
export function createAlbum(title: string) {
    return ({
        type: CREATE_ALBUM,
        title: title
    })
}

// Sélectionner la liste des médias lors de la création d'un nouvel album (optionnel) => Reducer
// (également utilisée pour sélectionner des médias à ajouter à un album déjà créé, à partir de la liste de tous les médias)
export function selectMediaForAlbum(mediaId: string) {
    return ({
        type: SELECT_MEDIA_FOR_ALBUM,
        mediaId: mediaId
    })
}

// Vider la liste des médias sélectionnés pour la création d'un nouvel album => Reducer
// ( ou pour l'ajout de médias dans l'album, depuis la liste des médias )
export function clearSelectedMediasForAlbum() {
    return ({
        type: CLEAR_SELECTED_MEDIA_FOR_ALBUM,
    })
}