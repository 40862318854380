import { Album } from "../types/album";
import { Media } from "../types/media";

export const composeUrlsForMedia: Function = (media: Media) => {
    switch (media.type_media) {
        case 'image':
        case 'answerkiss':
            media.url_allMedias = {
                webp: `${media.serveurMedia}/files/${media._id}-400.webp`,
                jpg: `${media.serveurMedia}/files/${media._id}-400.jpg`
            };
            media.url_lightbox = {
                webp: `${media.serveurMedia}/files/${media._id}-full.webp`,
                jpg: `${media.serveurMedia}/files/${media._id}-full.jpg`
            };
            media.url_album = {
                webp: `${media.serveurMedia}/files/${media._id}-260.webp`,
                jpg: `${media.serveurMedia}/files/${media._id}-260.jpg`
            }
            break;
        case 'video':
            media.url_allMedias = {
                webp: `${media.serveurMedia}/videos/${media._id}/preview.webp`,
                jpg: `${media.serveurMedia}/videos/${media._id}/preview.jpg`,
                anim: `${media.serveurMedia}/videos/${media._id}/preview_anim.webp`,
            };
            media.url_lightbox = `${media.serveurMedia}/videos/${media._id}/index.m3u8`;
            media.url_album = {
                webp: `${media.serveurMedia}/files/${media._id}-260.webp`,
                jpg: `${media.serveurMedia}/files/${media._id}-260.jpg`
            }
            break;
    }
    return media
}

export const composeAlbumSharedThumbnail: Function = (album: Album) => {
    if (album.medias.length !== 0) {
        album.medias[0].url_album = {
            webp: `${album.medias[0].serveurMedia}/files/${album.medias[0]._id}-260.webp`,
            jpg: `${album.medias[0].serveurMedia}/files/${album.medias[0]._id}-260.jpg`
        }
    }
    return album
}