/*******************************************************************
                  Composant pour le diaporama
*******************************************************************/

// Hooks
import { useEffect } from "react";
import { useRef } from "react";
import { useAppDispatch } from "../../app/hooks"
// Composants de librairies
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
// Stores
import { LightboxLayoutStore, MediasLayoutStore } from "../../app/store/layout";
// Actions
import { changeCurrentSlide, changeSlidesInterval, openLightbox, playCarousel } from "../../app/actions/layout/lightbox";
// Types
import { Media } from "../../app/types/media";

const Slides: Function = ({ mediasList, interval }: { mediasList: Media[], interval: number | null }): JSX.Element => {
    // Dispatcher d'action Redux
    const dispatch = useAppDispatch();
    // Récupération des states
    const { offcanvasSize } = MediasLayoutStore();
    let { currentSlide, play } = LightboxLayoutStore();

    // Références DOM
    // Players contenus dans le carousel
    const playersRefs = useRef<null | any[]>([]);
    // Boutons Précédent - Suivant
    const prevIcon = useRef<null | HTMLSpanElement>(null);
    const nextIcon = useRef<null | HTMLSpanElement>(null);

    if (currentSlide >= mediasList.length) {
        currentSlide = mediasList.length - 1;
    }

    // Effet de bord
    // Désactivation du drag sur les boutons précédent et suivant du carousel fourni par bootstrap
    // pour éviter le drag and drop opérable sur allMédias en arrière-plan
    useEffect(() => {
        if (prevIcon !== null && nextIcon !== null) {
            prevIcon.current!.parentElement!.draggable = false;
            nextIcon.current!.parentElement!.draggable = false;
        }
    }, []);

    // On définit la hauteur et la largueur du médias
    // pour l'adapter em fonction de la position de la boîte de commentaires
    // qui dépend elle-même de la largeur de la fenêtre
    const slidesWidth = window.innerWidth < 768 ? "100%" : `calc(100% - ${offcanvasSize.width}px)`
    const slidesHeight = window.innerWidth < 768 ? `calc(100vh - ${offcanvasSize.height}px)` : "100vh"

    return (
        <div className="slides" style={{ width: slidesWidth, height: slidesHeight }}>
            <div className="close-lightbox">
                {/* icône de fermeture de la lightbox */}
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    className='close-button'
                    onClick={() => {
                        dispatch(openLightbox(false, null))
                        // Arrêt de la lecture automatique si elle était activée
                        dispatch(playCarousel(false))
                    }}
                />
            </div>

            {/* Carousel bootstrap */}
            <Carousel
                indicators={false}
                prevIcon={
                    <span ref={prevIcon}>
                        <FontAwesomeIcon
                            icon={faCircleChevronLeft}
                            className="prevIcon"
                            // changement de la couleur du bouton si on est sur une answerkiss
                            // #ad003a correspond à la couleur principale définie dans le fichier de style _vars.scss
                            color={mediasList[currentSlide!]?.type_media === "answerkiss" ? "#ad003a" : "white"}
                        />
                    </span>
                }
                nextIcon={
                    <span ref={nextIcon}>
                        <FontAwesomeIcon
                            icon={faCircleChevronRight}
                            className="nextIcon"
                            color={mediasList[currentSlide!]?.type_media === "answerkiss" ? "#ad003a" : "white"}
                        />
                    </span>
                }
                // interval de temps entre les slides (si null : pas défilement)
                interval={interval}
                pause={false}
                activeIndex={currentSlide}
                /* Au changement de slide (slide (item) active => onSelect), on détecte si le slide contient un élément player
                contenu dans le tableau des références associées aux éléments ReactPlayer.
                Si oui, l'index de la slide courante renvoie un élément référencé dans le tableau au même index,
                si non le tableau de référence renvoie "undefined" pour cet index.
                */
                onSelect={(index) => {
                    // On envoie l'index courant dans le store (state => currentSlide)
                    dispatch(changeCurrentSlide(index))

                    /* Si il y a un élément player pour cet index, on stop le défilement des slides (play).
                    Quand une lecture de vidéo s'achève :
                    Si le défilement automatique était activé et qu'on arrive sur une slide ne contenant pas de player,
                    on redéfinit l'interval entre deux slides pour réactivé le défilement
                    */
                    if (playersRefs.current![index] !== undefined) {
                        if (play === true) {
                            dispatch(changeSlidesInterval(null))
                        }
                    } else if (playersRefs.current![index] === undefined) {
                        play === true ? dispatch(changeSlidesInterval(5000)) : dispatch(changeSlidesInterval(null))
                    }
                }}
            >

                {/* Liste des slides */}

                {mediasList.map((media, index) => (
                    <Carousel.Item key={media._id}>

                        {/* barre de progression (timeline) en haut de lightbox lors du défilement automatique */}
                        {interval !== null && (
                            <div className="lightbox-progress" style={{ width: slidesWidth }} />
                        )}

                        <div className="slide">
                            {/* IMAGES */}
                            {(media.type_media === "image" || media.type_media === 'answerkiss') && (
                                <>
                                    <picture key={media.timestamp ? media.timestamp : media.url_lightbox.web}>
                                        <source srcSet={media.url_lightbox.webp} />
                                        <img
                                            loading="lazy"
                                            alt=""
                                            src={media.url_lightbox.jpg}
                                            draggable={false}
                                            style={{ maxHeight: slidesHeight }}
                                        />
                                    </picture>
                                </>
                            )}

                            {/* VIDEOS */}
                            {media.type_media === "video" && (
                                <div className="player-wrapper">
                                    <ReactPlayer
                                        // mise en référence des éléments reactplayer du dom
                                        ref={(el: ReactPlayer) => playersRefs.current![index] = el}
                                        height={slidesHeight}
                                        // On indique une url uniquement à l'affichage de la slide en cours
                                        // (pour éviter le pré-chargement de toutes les vidéos du carousel)
                                        url={currentSlide === index ? media.url_lightbox : ' '}
                                        // Autoplay
                                        playing
                                        // Boutons du player
                                        controls
                                        // À la fin de la lecture d'une vidéo on passe la vitesse de slide à 1 milliseconde pour passer à la slide suivante
                                        onEnded={() => {
                                            if (play === true) {
                                                dispatch(changeSlidesInterval(1))
                                            }
                                        }}
                                        // configurations supplémentaires
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload',
                                                    disablePictureInPicture: true,
                                                }
                                            }
                                        }}
                                        /* Désactivation du clic droit sur le player */
                                        onContextMenu={(e: Event) => e.preventDefault()}
                                    />
                                </div>
                            )}
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default Slides