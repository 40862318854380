// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/generalLayout';
// Importations des actions utilisées
import {
    DISPLAY_LOGIN_MESSAGE_ERROR,
    DISPLAY_REGISTER_MESSAGE_ERROR,
    DISPLAY_PASSWORD_CHANGED_MESSAGE,
    GET_HEADER_HEIGHT,
    HANDLE_IS_LOADING,
    OPEN_DIALOG_FILES_FOR_UPLOAD,
    OPEN_FEEDBACK,
    OPEN_NAVBAR,
    SHOW_PASSWORD,
    THANKS_FOR_FEEDBACK
} from '../../actions/layout/general';

const initialState: InitialState = {
    isLoading: false,
    openFeedback: false,
    thanksForFeedback: false,
    passwordChanged: false,
    loginError: false,
    registerError: false,
    showPassword: false,
    openDialogFiles: false,
    headerHeight: 0,
    openNavbar: false,
}

function generalLayoutReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case HANDLE_IS_LOADING:
            return ({
                ...state,
                isLoading: action.isLoading,
            })

        case OPEN_FEEDBACK:
            return ({
                ...state,
                openFeedback: action.open
            })

        case THANKS_FOR_FEEDBACK:
            return ({
                ...state,
                thanksForFeedback: action.thanks
            })

        case DISPLAY_PASSWORD_CHANGED_MESSAGE:
            return ({
                ...state,
                passwordChanged: action.display
            })

        case DISPLAY_LOGIN_MESSAGE_ERROR:
            return ({
                ...state,
                loginError: action.display
            })

        case DISPLAY_REGISTER_MESSAGE_ERROR:
            return ({
                ...state,
                registerError: action.display
            })

        case SHOW_PASSWORD:
            return ({
                ...state,
                showPassword: action.show
            })

        case OPEN_DIALOG_FILES_FOR_UPLOAD:
            return ({
                ...state,
                openDialogFiles: action.open
            })

        case GET_HEADER_HEIGHT:
            return ({
                ...state,
                headerHeight: action.height
            })

        case OPEN_NAVBAR:
            return ({
                ...state,
                openNavbar: action.open
            })
        default:
            return state
    }
}

export default generalLayoutReducer;