// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { instance } from "../config";
// Importation de l'interface history pour récupérer les urls à partir desquelles l'action est dispatchée
// et modifier le state lié à ces urls si besoin
import { createBrowserHistory } from "history";
// Importation des actions utilisées
import {
    ADD_MEDIAS_TO_ALBUM,
    DELETE_ALBUM,
    DUPLICATE_ALBUM,
    DELETE_MEDIA_IN_ALBUM,
    editSingleAlbumTitleInState,
    SHARE_ALBUM,
    updateSharingContactsInSingleAlbum,
    UPDATE_ALBUM_TITLE
} from "../../actions/album/singleAlbum";
import { clearSelectedMediasForAlbum, getAllAlbums, putAllAlbumsInState } from "../../actions/album/allAlbums";
import { getAlbumsSharedWithMe } from "../../actions/album/sharedAlbums";
import { clearFields, handleEmailForShare } from "../../actions/controlledFields";
import { editSingleAlbumTitle } from "../../actions/layout/album";
// Importation de types
import { Album } from "../../types/album";
import { isEmailAlreadyInShares } from "../../actions/layout/modals";

const singleAlbumMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case DELETE_ALBUM:
            instance.delete(`/album/${action.albumId}`)
                .then(response => {
                    // Actualisation de la liste des albums après suppression effectuée
                    const { allAlbums } = store.getState().allAlbums
                    allAlbums.splice(allAlbums.findIndex((album: Album) => album._id === action.albumId), 1)
                    store.dispatch(putAllAlbumsInState([...allAlbums]))
                    if (document.location.pathname.includes('/album/')) {
                        document.location.replace('/all-albums')
                    }
                })
                .catch(error => console.log(error));
            break;

        case DUPLICATE_ALBUM:
            instance.post(`/album/${action.albumId}/duplicate`)
                .then(response => {
                    // Actualisation de la liste des albums après que la duplication soit effectuée
                    const { allAlbums } = store.getState().allAlbums
                    allAlbums.splice(allAlbums.findIndex((album: Album) => album._id === action.albumId), 1)
                    store.dispatch(putAllAlbumsInState([...allAlbums]))
                    if (document.location.pathname.includes('/album/')) {
                        document.location.replace('/all-albums')
                    }
                })
                .catch(error => console.log(error));
            break;

        case DELETE_MEDIA_IN_ALBUM:
            instance.delete(`/album/${action.albumId}/media/${action.mediaId}`)
                .then(response => {
                })
                .catch(error => console.log(error));
            break;

        case ADD_MEDIAS_TO_ALBUM:
            {
                // Récupération des médias sélectionnés depuis la liste des médias et à ajouter à l'album 
                const { selectedMediasForAlbum } = store.getState().allAlbums;
                // Envoi au serveur
                instance.post(`/album/${action.albumId}/addMedias`,
                    {
                        medias: selectedMediasForAlbum
                    }
                )
                    .then(response => {
                        // Suppression de la liste des médias sélectionnés pour l'album créé
                        store.dispatch(clearSelectedMediasForAlbum());
                        // Si on est sur la page d'un album, on met à jour la liste des albums pour 
                        // repeupler l'album en question (action populateSingleAlbumInState, lancée dans un useEffect de la page en question)
                        const history = createBrowserHistory();
                        if (history.location.pathname.includes('/album/') === true) {
                            store.dispatch(getAllAlbums())
                        }
                    })
                    .catch(error => console.log(error));
            }
            break;

        case UPDATE_ALBUM_TITLE:
            {
                // Récupération de l'id de l'album 
                // et du champ contrôlé correspondant à la valeur de l'input du titre de l'album
                const { id } = store.getState().singleAlbum.singleAlbum;
                const { title } = store.getState().controlledFields.singleAlbum;
                // modification envoyée au serveur
                instance.patch(`/album/${id}`,
                    {
                        title: title
                    }
                )
                    .then(response => {
                        // Pour éviter le refresh, on met à jour le titre modifié de l'album
                        // et on passe le mode édition à false pour cacher l'input et revenir à un affichage titre
                        // on vide les champs contrôlés relatifs à un album
                        store.dispatch(editSingleAlbumTitleInState(title))
                        store.dispatch(editSingleAlbumTitle(false))
                        store.dispatch(clearFields('singleAlbum'))
                        // On remplace le titre de l'album dans le state lié à l'url de la page
                        // afin de le garder en mémoire dans ce state en cas de refresh
                        const history = createBrowserHistory();
                        const state = history.location.state as object;
                        history.replace(history.location, { ...state, title: title })
                    })
                    .catch(error => console.log(error));
            }
            break;

        case SHARE_ALBUM:
            {
                const { id } = store.getState().singleAlbum.singleAlbum;
                if (action.typeShare === 'private') {
                    // Récupération de la valeur de l'input de l'email de partage et de l'id de l'album à partagé
                    const { emailForShare } = store.getState().controlledFields.singleAlbum;
                    instance.post(`/album-share/${id}`, {
                        email: emailForShare
                    })
                        .then(response => {
                            // Actualisation des emails de partages dans le store pour les afficher instannément sans refresh
                            store.dispatch(updateSharingContactsInSingleAlbum('add', response.data._id, response.data.email))
                            // si l'email de partage est le même que celui de l'utilisateur,
                            // (partage avec son propre email)
                            // on met à jour la liste des albums partagés avec lui (évite le refresh)
                            const { email: userEmail } = store.getState().user.userData
                            if (emailForShare === userEmail) {
                                store.dispatch(getAlbumsSharedWithMe())
                            }
                            // On réinitialise le champ de saisi pour l'email de partage
                            store.dispatch(handleEmailForShare(''))
                        })
                        .catch(error => {
                            console.log(error)
                            if (error.response.status === 403) {
                                store.dispatch(isEmailAlreadyInShares(true))
                            }
                        });
                } else if (action.typeShare === 'public') {
                    instance.post(`/album-share-public/${id}`)
                        .then(response => {
                            store.dispatch(updateSharingContactsInSingleAlbum('add', response.data.tokenPublic, "partage public"))
                        })
                        .catch(error => console.log(error));
                }
            }
            break;

        default:
    }

    next(action);
}

export default singleAlbumMiddleware;
