// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { instance } from "../config";
// Importation des actions utilisées
import {
    clearSelectedMediasForAlbum,
    CREATE_ALBUM,
    getAllAlbums,
    GET_ALL_ALBUMS,
    putAllAlbumsInState
} from "../../actions/album/allAlbums";
import { handleNewAlbumTitle } from "../../actions/controlledFields";
import { createAlbumNextStep, showCreateAlbumModal } from "../../actions/layout/modals";

const allAlbumsMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case CREATE_ALBUM:
            {
                const { title } = store.getState().controlledFields.newAlbum;
                // Récupération des médias sélectionnés pour la création du nouvel album
                const { selectedMediasForAlbum } = store.getState().allAlbums;
                // Envoi au serveur
                instance.post('/album',
                    {
                        title: title,
                        medias: selectedMediasForAlbum
                    }
                )
                    .then(response => {
                        // Récupération de la nouvelle liste d'albums après la création du nouvel album
                        store.dispatch(getAllAlbums())
                        // Suppression de la liste de médias sélectionnés pour le nouvel album créé
                        store.dispatch(clearSelectedMediasForAlbum())
                        store.dispatch(handleNewAlbumTitle(''))
                        store.dispatch(createAlbumNextStep(false))
                        store.dispatch(showCreateAlbumModal(false))

                        // redirection vers l'album
                        const idAlbum = response.data._id
                        const urlToAlbum = "/album/" + idAlbum
                        document.location.replace(urlToAlbum)
                    })
                    .catch(error => console.log(error));
            }
            break;

        case GET_ALL_ALBUMS:
            // Demande de la liste des albums au serveur
            instance.get('/albums')
                .then(response => {
                    // Stockage de la liste des albums reçue dans le store
                    store.dispatch(putAllAlbumsInState(response.data))
                })
                .catch(error => console.log(error));
            break;

        default:
    }

    next(action);
}

export default allAlbumsMiddleware;