/*******************************************************************
Composant pour l'inscription (/login/register) / connexion (/login/signin)
*******************************************************************/

// Hooks
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

// Composants de librairies
// import { Card, ListGroup } from 'react-bootstrap';
// import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// Composants "locaux"
import PrimaryButton from '../../components/Buttons/primaryButton';
import SecondaryButton from '../../components/Buttons/secondaryButton';
import LinkButton from '../../components/Buttons/linkButton';
import ChangingPassword from '../../components/Modals/changingPassword';
import ConnectFields from './connectFields';
import RegisterFields from './registerFields';
// Stores
import { GeneralLayoutStore, LoginLayoutStore } from '../../app/store/layout';
// Actions
import { displayConnect, displayRegister } from '../../app/actions/layout/login';
import { clearFields } from '../../app/actions/controlledFields';
import { openChangingPasswordModal } from '../../app/actions/layout/modals';
import { logIn, register } from '../../app/actions/user';
import { displayLoginMessageError, showPassword } from '../../app/actions/layout/general';
// Styles
import './login.scss';


const Login: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.login' });
    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch();
    // Hook de navigation
    const navigate = useNavigate();
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();

    // Récupérations des states
    const { loginError, registerError, showPassword: show } = GeneralLayoutStore();
    const { openConnect, openRegister } = LoginLayoutStore();

    // EFFETS DE BORD :
    // au premier rendu du composant et rafraîchissement de la page :
    // masquage du message d'erreur de connexions
    // réinitiaisation des champs
    // affichage du bon formulaire
    useEffect(() => {
        dispatch(displayLoginMessageError(false))
        dispatch(clearFields('user'))
        if (pathname.includes('register')) {
            dispatch(displayRegister());
        }
        if (pathname.includes('signin')) {
            dispatch(displayConnect());
        }
    }, [dispatch, pathname])

    // au premier rendu du composant et rafraîchissement de la page : masquage de la saisie pour le mot de passe
    useEffect(() => {
        dispatch(showPassword(false))
    }, [dispatch, openConnect, openRegister])

    return (
        <div className="container">
            <h1>
                {`${openConnect ? t('signIn.title') : ''}`}
                {openRegister && t('register.title')}
            </h1>

            {/* Message d'erreur si conenxion invalide */}
            {loginError && (
                <Alert variant="danger" className="login-Error">{t('signIn.error')}</Alert>
            )}

            {registerError && (
                <Alert variant="danger" className="login-Error">{t('register.error')}</Alert>
            )}

            <form
                method="post"
                id="login"
                onSubmit={(event) => {
                    event.preventDefault();
                    if (openConnect) {
                        dispatch(logIn());
                    }
                    if (openRegister) {
                        dispatch(register());
                    }
                }}
            >

                {openRegister && (
                    <>
                        <div className="betaVersion-message">
                            {/* <p>{t('register.message.sentence1')}</p>
                            <p>{t('register.message.sentence2')}</p> */}
                        </div>
                        { /* Champs du form présents pour l'inscription */}
                        <RegisterFields />
                    </>
                )}
                { /* Champs du form présents pour la connexion (et l'inscription) */}
                <ConnectFields />

                {/* Affichage / masquage de la saisie du mot de passe */}
                <button
                    type="button"
                    id="show-password"
                    style={{ color: show ? "#ad003a" : "lightgrey" }}
                    onClick={() => dispatch(showPassword(!show))}
                >
                    <FontAwesomeIcon icon={show ? faEye : faEyeSlash} />
                </button>

                {/* Validation / Annulation du form */}
                <div className="buttons">
                    <SecondaryButton
                        buttonName={t('cancel')}
                        onClick={() => navigate('/')}
                    />
                    <PrimaryButton
                        buttonType="submit"
                        buttonName={t(openConnect ? 'signIn.validate' : 'register.validate')}
                        formAttached="login"
                    />
                </div>
            </form>

            {/* Mot de passe perdu */}
            {openConnect && (
                <div className="row m-3">
                    <div className="col text-center">
                        <LinkButton
                            buttonType="button"
                            buttonName={t('signIn.lost-password')}
                            onClick={() => {
                                dispatch(openChangingPasswordModal(true))
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Modal pour le mot de passe perdu */}
            <ChangingPassword />


            {/* formules d'abonnement */}
            {/* openRegister && (
        // <div className="prices">
        //   Nous ne vous demanderons jamais votre numéro de carte bancaire si vous utilisez notre offre \"free kiss\"
        //   <div className="prices-cards">
        //     <Card>
        //       <Card.Title>Free Kiss</Card.Title>
        //       <ListGroup>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleCheck} className="text-success"/>
        //           <p className="included">300 images</p>
        //         </ListGroup.Item>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleXmark} className="text-danger"/>
        //           <p className="not-included">Notre éditeur d'images</p>
        //         </ListGroup.Item>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleXmark} className="text-danger"/>
        //           <p className="not-included">Vidéos</p>
        //         </ListGroup.Item>
        //       </ListGroup>
        //       <Card.Footer>
        //       Ce sera toujours gratuit
        //       </Card.Footer>
        //     </Card>
        //     <Card>
        //       <Card.Title>Kiss unlimited</Card.Title>
        //       <ListGroup>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleCheck} className="text-success"/>
        //           <p className="included">Stockage illimité des images</p>
        //         </ListGroup.Item>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleCheck} className="text-secondary"/>
        //           <p className="unavailable text-secondary">Notre éditeur d'images (bientôt)</p>
        //         </ListGroup.Item>
        //         <ListGroup.Item>
        //           <FontAwesomeIcon icon={faCircleCheck} className="text-success"/>
        //           <p className="included">Vidéos</p>
        //         </ListGroup.Item>
        //       </ListGroup>
        //       <Card.Footer>
        //         <p className="price-offer">4,99€ / mois</p>
        //         <p className="commitment">(sans engagement de durée)</p>
        //       </Card.Footer>
        //     </Card>
        //   </div>
        // </div>
      ) */}
        </div>
    );
}

export default Login;
