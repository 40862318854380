/*******************************************************************
          Composant pour le rendu d'un seul média
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../app/hooks";
import { useRef } from "react";
import { useEffect } from "react";
// Composants de librairies
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment } from '@fortawesome/free-solid-svg-icons';
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
// Composants "locaux"
import LikeOrCommentPopover from "../../components/Popover/LikeOrCommentPopover";
// Stores
import { UserStore } from "../../app/store/user";
import { MediasLayoutStore } from "../../app/store/layout";
// Actions
import { openCanILikeOrCommentPopover } from "../../app/actions/layout/medias";
import { openLightbox } from "../../app/actions/layout/lightbox";
import { dislikeMedia, likeMedia } from "../../app/actions/media/singleMedia";

type SingleMediaProps = {
    alt: string,
    width: number,
    height: number,
    marginRight: number,
    src: { [key: string]: any },
    index: number,
    id: string,
    likes: { [key: string]: any }[],
    commentsCount: number | undefined,
    pictureKey: number | string,
}
const SingleMedia: Function = ({
    alt,
    width,
    height,
    marginRight,
    src,
    index,
    id,
    likes,
    commentsCount,
    pictureKey
}: SingleMediaProps): JSX.Element => {

    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const { id: userId } = UserStore().userData;
    const { popover } = MediasLayoutStore();

    // Mise en référence de l'icône de likes
    // (en vue d'afficher le popover exigeant la connexion pour liker si l'utilisateur n'est pas connecté)
    const iconLike = useRef<null | HTMLSpanElement>(null);

    // Effet de bord
    // Fermeture des popovers éventuellement déjà ouverts
    useEffect(() => {
        dispatch(openCanILikeOrCommentPopover(false, id, '', null))
    }, [dispatch, id])

    return (
        <div
            className="blocImage"
            style={{ marginRight: marginRight }}
        >
            <picture key={pictureKey}>
                <source srcSet={src.webp} />
                <img
                    loading="lazy"
                    alt={alt}
                    src={src.jpg}
                    style={{ width: width, height: height }}
                    draggable={false}
                    onMouseOver={e => {
                        if (src.anim) {
                            e.currentTarget.previousElementSibling?.setAttribute('srcSet', src.anim)
                        }
                    }}
                    onMouseLeave={e => {
                        if (e.currentTarget.previousElementSibling?.getAttribute('srcSet') === src.anim) {
                            e.currentTarget.previousElementSibling?.setAttribute('srcSet', src.webp)
                        }
                    }}
                    onClick={() => dispatch(openLightbox(true, index))}
                />
            </picture>

            <div className="btnActionAsset">

                <span
                    onClick={() => {
                        if (popover.show) {
                            dispatch(openCanILikeOrCommentPopover(false, id, '', null))
                        }
                        dispatch(openLightbox(true, index))
                    }}
                >
                    <FontAwesomeIcon icon={faComment} className={`FontAwesomeFaComment ${commentsCount !== undefined && commentsCount !== 0 ? 'alight' : ''}`} />
                    {commentsCount !== undefined && commentsCount !== 0 && (
                        commentsCount > 99 ?
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip >
                                        {commentsCount}
                                    </Tooltip>
                                }
                            >
                                <Badge id="comments-counter">99<span>+</span></Badge>
                            </OverlayTrigger>
                            :
                            <Badge id="comments-counter">{commentsCount}</Badge>
                    )}
                </span>

                <span ref={iconLike}>
                    <FontAwesomeIcon
                        icon={faHeart}
                        className={`FontAwesomeFaHeart ${likes?.find(like => like.owner_id === userId) !== undefined ? 'alight' : ''}`}
                        onClick={(e) => {
                            if (userId !== undefined) {
                                e.currentTarget.classList.contains('alight') ? dispatch(dislikeMedia(id)) : dispatch(likeMedia(id))
                                e.currentTarget.classList.toggle('alight');
                            } else {
                                dispatch(openCanILikeOrCommentPopover(true, id, 'liker', 'mediasList'));
                            }
                        }}
                    />
                </span>

            </div>

            <LikeOrCommentPopover
                target={iconLike}
                mediaId={id}
                container="mediasList"
                action="liker"
                placement="bottom"
            />

        </div>
    );
}

export default SingleMedia;