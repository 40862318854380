/*******************************************************************
            Modal pour le changement de mot de passe
        et le message d'envoi du lien de réinitialisation
*******************************************************************/

// Hooks
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../app/hooks"
// Composants de librairies
import { Modal } from "react-bootstrap"
// Composants "locaux"
import PrimaryButton from "../Buttons/primaryButton"
import SecondaryButton from "../Buttons/secondaryButton"
import Field from "../Field/field"
// Stores
import { ModalsLayoutStore } from "../../app/store/layout"
import { ControlledFieldsStore } from "../../app/store/controlledFields"
// Actions
import { handleUserFields } from "../../app/actions/controlledFields"
import { askForChangingPasswordMessage, openChangingPasswordModal } from "../../app/actions/layout/modals"
import { askForChangingPassword } from "../../app/actions/user"

const ChangingPassword: Function = () => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.changingPassword' })

    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch()

    // Récupération des states
    const { changingPassword } = ModalsLayoutStore();
    const { email } = ControlledFieldsStore().user;

    return (
        <Modal show={changingPassword.show} id="changingPassword-modal">
            <Modal.Body>
                {/* Si on est pas dans le cas de l'affichage du message confirmant l'envoi du lien,
        on affiche le formulaire avec la demande d'email */}
                {!changingPassword.showMessage && (
                    <form
                        id="ask-changing-password"
                        onSubmit={(e) => {
                            e.preventDefault()
                            dispatch(askForChangingPassword())
                            // Affichage du message d'envoi du lien de réinitialisation
                            dispatch(askForChangingPasswordMessage(true))
                        }}
                    >
                        <Field
                            labelHtml="email"
                            labelName={t('labels.email')}
                            inputType="email"
                            inputName="email"
                            required={true}
                            inputValue={email}
                            action={(e: React.ChangeEvent<HTMLInputElement>) => { dispatch(handleUserFields('email', e.target.value)) }}
                        />
                    </form>
                )}

                {/* message confirmant l'envoi du lien de réinitialisation */}
                {changingPassword.showMessage && (
                    <div className="message">
                        {t('message')}
                    </div>
                )}

                <div className="buttons">
                    <SecondaryButton
                        // Bouton "Fermer" dans le cas du message / "Annuler" dans le cas du formulaire
                        buttonName={`${!changingPassword.showMessage ? t('buttons.secondary.cancel') : t('buttons.secondary.close')}`}
                        onClick={() => dispatch(openChangingPasswordModal(false))}
                    />

                    {/* bouton de validation dans le cas du formulaire */}
                    {!changingPassword.showMessage && (
                        <PrimaryButton
                            buttonName={t('buttons.primary')}
                            buttonType="submit"
                            formAttached="ask-changing-password"
                        />
                    )}

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ChangingPassword