/*******************************************************************
        Modal pour la confirmation d'une suppression
*******************************************************************/

// Hook
import { useTranslation } from "react-i18next";
// Composants de librairies
import { Modal } from "react-bootstrap";
// Composants "locaux"
import PrimaryButton from "../Buttons/primaryButton";
import SecondaryButton from "../Buttons/secondaryButton";

const DeleteConfirmation: Function = (
    { specificModal, message, closeModal, deleteAction }
        : { specificModal: { [key: string]: any }, message: string, closeModal: Function, deleteAction: Function }
) => {

    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'components.modals.deleteConfirmation' })

    return (
        <Modal show={specificModal.show}>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <SecondaryButton buttonName={t('buttons.secondary')} onClick={() => closeModal()} />
                <PrimaryButton buttonName={t('buttons.primary')} onClick={() => deleteAction()} />
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteConfirmation;