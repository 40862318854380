import addAnswerkiss from "../../../../../../components/Modals/languages/en/addAnswerkiss.json"
import addMediaInSingleAlbum from "../../../../../../components/Modals/languages/en/addMediaInSingleAlbum.json"
import addToAlbumList from "../../../../../../components/Modals/languages/en/addToAlbumList.json"
import changingPassword from "../../../../../../components/Modals/languages/en/changingPassword.json"
import createAlbum from "../../../../../../components/Modals/languages/en/createAlbum.json"
import deleteConfirmation from "../../../../../../components/Modals/languages/en/deleteConfirmation.json"
import shareAlbumModal from "../../../../../../components/Modals/languages/en/shareAlbumModal.json"

export const modalsENtranslation = {
    "addAnswerkiss": {
        ...addAnswerkiss
    },
    "addMediaInSingleAlbum": {
        ...addMediaInSingleAlbum
    },
    "addToAlbumList": {
        ...addToAlbumList
    },
    "changingPassword": {
        ...changingPassword
    },
    "createAlbum": {
        ...createAlbum
    },
    "deleteConfirmation": {
        ...deleteConfirmation
    },
    "shareAlbumModal": {
        ...shareAlbumModal
    }
}