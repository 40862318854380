/*******************************************************************
                Composant pour la page /album/:id
*******************************************************************/

// Hooks
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
// Composants "locaux"
import AddMediaInSingleAlbum from "../../../components/Modals/addMediaInSingleAlbum";
import DeleteConfirmation from "../../../components/Modals/deleteConfirmation";
import ShareAlbumModal from "../../../components/Modals/shareAlbumModal";
import Lightbox from "../../lightbox/lightbox";
import MediasList from "../../medias/mediasList";
import Upload from "../../upload/upload";
import SingleAlbumTitle from "./singleAlbumTitle";
import AddAnswerkiss from "../../../components/Modals/addAnswerkiss";
// Stores
import { MediasListStore } from "../../../app/store/media";
import { LightboxLayoutStore, ModalsLayoutStore } from "../../../app/store/layout";
import { AllAlbumsStore, SharedAlbumsStore, SingleAlbumStore } from "../../../app/store/album";
import { UserStore } from "../../../app/store/user";
// Actions
import { getSharedAlbumMedias } from "../../../app/actions/album/sharedAlbums";
import { getAllAlbums } from "../../../app/actions/album/allAlbums";
import { deleteAlbum, duplicateAlbum, populateSingleAlbumInState } from "../../../app/actions/album/singleAlbum";
import { handleSingleAlbumTitle } from "../../../app/actions/controlledFields";
import { getAllMedias } from "../../../app/actions/media/mediasList";
import PrimaryButton from "../../../components/Buttons/primaryButton";
import { confirmDeleteAlbum, confirmDuplicateAlbum, showAddMediaInAlbum } from "../../../app/actions/layout/modals"
import { editAnswerkiss } from "../../../app/actions/layout/medias"
import { openAnswerKissModal } from "../../../app/actions/layout/modals"
// Types
import { Media } from "../../../app/types/media";
// Styles 
import './singleAlbum.scss'

const SingleAlbum: Function = (props: any): JSX.Element => {
    const { t } = useTranslation('')
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch()
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();
    // Récupération des paramètres d'url
    const { albumId, shareId } = useParams();

    // Récupération des states (reducers/stores)
    const { allMedias } = MediasListStore();
    const { open, interval } = LightboxLayoutStore();
    const deleteAlbumModal = ModalsLayoutStore().deleteAlbum;
    const duplicateAlbumModal = ModalsLayoutStore().duplicateAlbum;
    const { allAlbums } = AllAlbumsStore();
    const personalSingleAlbum = SingleAlbumStore().singleAlbum;
    const singleAlbumShared = SharedAlbumsStore().singleAlbum;
    const { id: userId } = UserStore().userData;

    // mise en référence du composant Upload pour déclencher le clic sur l'input files
    const filesUploadDialog = props.filesUploadDialog;

    // On détermine si on est sur la page d'un album partagé ou d'un album personnel
    const albumShared = pathname.includes('/share/') && shareId !== undefined && albumId !== undefined ? true : false;
    const singleAlbum = albumShared ? singleAlbumShared : personalSingleAlbum;

    // Effets de bord
    // Récupération de la liste des médias et des albums depuis la BDD (refresh)
    useEffect(() => {
        if (!albumShared) {
            dispatch(getAllMedias())
            dispatch(getAllAlbums())
        }
    }, [albumShared, userId, dispatch])

    // Si il s'agit d'un album personnel on peuple l'album pour récupérer ses médias
    useEffect(() => {
        if (!albumShared && allAlbums.length !== 0) {
            dispatch(populateSingleAlbumInState(albumId, allMedias, allAlbums))
        }
    }, [dispatch, albumId, albumShared, allMedias, allAlbums])

    useEffect(() => {
        if (albumShared) {
            dispatch(getSharedAlbumMedias(albumId, shareId))
        }
    }, [dispatch, albumShared, albumId, shareId])

    // Au chargement du composant on injecte le titre de l'album comme valeur de champ contrôlé
    // pour qu'au clic sur "modifier le titre" dans le menu, le titre de l'album soit en valeur par défaut dans l'input
    useEffect(() => {
        dispatch(handleSingleAlbumTitle(singleAlbum.title))
    }, [dispatch, singleAlbum])

    const titleHeight: any = document.querySelector(".singleAlbum .album-title")?.clientHeight;
    const singleAlbumElement: HTMLElement | null = document.querySelector('.singleAlbum');
    if (singleAlbumElement) {
        singleAlbumElement.style.paddingTop = titleHeight + 'px';
    }

    return (
        <div className="singleAlbum marginMenuLeft">
            <div className="album-header">
                <SingleAlbumTitle title={singleAlbum.title} albumShared={albumShared} />
            </div>

            {!albumShared && (
                <Upload tooltipDirection="right" filesUploadDialog={filesUploadDialog} />
            )}

            {singleAlbum && singleAlbum.medias.length !== 0 && (
                <MediasList className="mediasList" mediasList={singleAlbum.medias.map((media: Media) => ({ ...media }))} />
            )}

            {!albumShared && singleAlbum.medias.length === 0 && (
                <>
                    <div className="no-media" style={{marginTop:'40px'}}>
                        <div className='menuSingleAlbumStart'>Ajouter un souvenir</div>

                        <div className="row">
                            <div className="col text-center">
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-lg"
                                    buttonName={t('features.album.singleAlbum.menu.add-media.from-computer')}
                                    onClick={() => filesUploadDialog.current?.click()}
                                />
                            </div>
                            {/* <div className="col text-center">
                            <PrimaryButton
                                id="create-button"
                                className="btn-lg"
                                buttonName={t('features.album.singleAlbum.menu.add-media.from-all-medias')}
                                onClick={() => dispatch(showAddMediaInAlbum(true))}
                            />
                        </div> */}
                            <div className="col text-center">
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-lg"
                                    buttonName={t('features.medias.allMedias.menu.add.answerkiss')}
                                    onClick={() => {
                                        dispatch(editAnswerkiss(false))
                                        dispatch(openAnswerKissModal(true))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}



            <DeleteConfirmation
                specificModal={deleteAlbumModal}
                message={t('features.album.singleAlbum.messages.confirm-delete')}
                closeModal={() => dispatch(confirmDeleteAlbum(false, undefined))}
                deleteAction={() => {
                    dispatch(deleteAlbum(deleteAlbumModal.albumToDelete!))
                    dispatch(confirmDeleteAlbum(false, undefined))
                }}
            />

            <DeleteConfirmation
                specificModal={duplicateAlbumModal}
                message={t('features.album.singleAlbum.messages.confirm-duplicate')}
                closeModal={() => dispatch(confirmDuplicateAlbum(false, undefined))}
                deleteAction={() => {
                    dispatch(duplicateAlbum(duplicateAlbumModal.albumToDuplicate!))
                    dispatch(confirmDuplicateAlbum(false, undefined))
                }}
            />

            <AddMediaInSingleAlbum albumId={singleAlbum.id} />
            <ShareAlbumModal albumId={singleAlbum.id} sharingContacts={singleAlbum.sharingContacts} />
            {open === true && singleAlbum.medias.length !== 0 && (
                <Lightbox mediasList={singleAlbum.medias} interval={interval} albumId={albumId} albumShared={albumShared} />
            )}

            {/* Modal pour la création d'answerkiss */}
            <AddAnswerkiss albumId={singleAlbum.id} />
        </div>
    )
}

export default SingleAlbum;