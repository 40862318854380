/***************** Vérouillage du nom des actions *****************/

// SINGLE ALBUM
export const EDIT_SINGLE_ALBUM_TITLE: string = 'EDIT_SINGLE_ALBUM_TITLE';
export const OPEN_SINGLE_ALBUM_MENU: string = 'OPEN_SINGLE_ALBUM_MENU';
// SHARED ALBUM
export const DISPLAY_ALBUM_SHARED: string = 'DISPLAY_ALBUM_SHARED';
// ALL ALBUMS
export const GET_RESPONSIVE_CONTAINER_WIDTH: string = 'GET_RESPONSIVE_CONTAINER_WIDTH';

// Signaler l'édition du titre d'un album pour afficher l'input de modification => reducer
export function editSingleAlbumTitle(edit: boolean) {
    return ({
        type: EDIT_SINGLE_ALBUM_TITLE,
        edit: edit
    })
}

// Ouvrir le menu d'un album (contenant les actions de suppression, partage, etc...) => reducer
export function openSingleAlbumMenu(open: boolean) {
    return ({
        type: OPEN_SINGLE_ALBUM_MENU,
        open: open
    })
}

// Afficher un album partagé (si le lien de partage est valide et si on y est autorisé) => reducer
export function displayAlbumShared(display: boolean) {
    return ({
        type: DISPLAY_ALBUM_SHARED,
        display: display
    })
}

// obention de la taille du containeur pour le calcul de la taille des médias
// en fonction du redimensionnement de la fenêtre du navigateur
// => reducer
export function getResponsiveContainerWidth(width: number) {
    return ({
        type: GET_RESPONSIVE_CONTAINER_WIDTH,
        width: width
    })
}
