/*******************************************************************
        Formulaire pour la demande de renouvellement 
                de mot de passe en cas de perte
*******************************************************************/

// Hooks
import { useEffect } from "react"
import { useAppDispatch } from "../../app/hooks"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
// Composants de librairies
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
// Composants "locaux"
import PrimaryButton from "../../components/Buttons/primaryButton"
import SecondaryButton from "../../components/Buttons/secondaryButton"
import Field from "../../components/Field/field"
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields"
import { GeneralLayoutStore } from "../../app/store/layout"
// Actions
import { handleUserFields } from "../../app/actions/controlledFields"
import { showPassword } from "../../app/actions/layout/general"
import { getTokenForResetPassword, resetPassword } from "../../app/actions/user"
// Style
import './lostPassword.scss'

const LostPassword: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.lostPassword' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch()
    // Hook de navigation
    const navigate = useNavigate()

    // Récupèration des paramètres d'url
    const { token } = useParams()
    // Récupération des states
    const { resetPassword: newPassword } = ControlledFieldsStore().user;
    const { passwordChanged, showPassword: show } = GeneralLayoutStore();

    // Effet de bord
    // Sauvegarde du token dans le store
    useEffect(() => {
        dispatch(getTokenForResetPassword(token));
    }, [dispatch, token])
    // Masquage de la saisie du mot de passe par défaut
    useEffect(() => {
        dispatch(showPassword(false))
    }, [dispatch])

    return (
        <div className="lost-password">
            {!passwordChanged && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        dispatch(resetPassword())
                    }}
                >
                    <Field
                        labelHtml="new-password"
                        labelName={t('new-password-label')}
                        inputType={show ? 'text' : 'password'}
                        inputName="new-password"
                        required={true}
                        inputValue={newPassword}
                        action={(e: React.ChangeEvent<HTMLInputElement>) => { dispatch(handleUserFields('resetPassword', e.target.value)) }}
                    />
                    {/* Affichage / masquage du mot de passe */}
                    <button
                        type="button"
                        id="show-password"
                        style={{ color: show ? "#ad003a" : "lightgrey" }}
                        onClick={() => dispatch(showPassword(!show))}
                    >
                        <FontAwesomeIcon icon={show ? faEye : faEyeSlash} />
                    </button>

                    <div className="buttons">
                        <SecondaryButton buttonName={t('cancel')} onClick={() => { navigate('/login/signin') }} />
                        <PrimaryButton buttonName={t('validate')} buttonType="submit" />
                    </div>
                </form>
            )}
            {passwordChanged && (
                <p>{t('message')} <Link to='/login/signin'>{t('signIn-link')}</Link></p>
            )}
        </div>
    )
}

export default LostPassword