/*******************************************************************
    Boutons présents dans l'en-tête du panneau de commentaires
*******************************************************************/

// Hooks
import { useRef } from "react";
import { useAppDispatch } from "../../app/hooks"
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// Composants de librairies
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
// Composants "locaux"
import ButtonWithTooltip from "../../components/Buttons/buttonWithTooltip";
import { lightboxButtons } from "./lightboxButtons"
import LikeOrCommentPopover from "../../components/Popover/LikeOrCommentPopover";
// Stores
import { LightboxLayoutStore, MediasLayoutStore } from "../../app/store/layout";
import { AllAlbumsStore } from "../../app/store/album";
import { UserStore } from "../../app/store/user";
// Actions
import { handleAnswerkissFields } from "../../app/actions/controlledFields";
import { editAnswerkiss, openCanILikeOrCommentPopover } from "../../app/actions/layout/medias";
import { addToAlbumModal, confirmDeleteMedia, openAnswerKissModal } from "../../app/actions/layout/modals";
import { dislikeMedia, likeMedia } from "../../app/actions/media/singleMedia";
import { openComments, playCarousel } from "../../app/actions/layout/lightbox";
// Types
import { Media } from "../../app/types/media";
// Styles
import './offCanvasButtons.scss';

const OffCanvasButtons: Function = ({ albumShared, mediasList }: { albumShared: boolean, mediasList: Media[] }): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.lightbox.buttons' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();
    // Récupération des states
    const { currentSlide, play, openComments: open } = LightboxLayoutStore();
    const { allAlbums } = AllAlbumsStore();
    const { id: userId } = UserStore().userData;
    const { popover } = MediasLayoutStore();

    // Média en cours d'affichage
    const currentMedia: Media = mediasList[currentSlide!]

    // Référence DOM sur l'icône Like (pour le positionnement du popover correspondant si non connecté)
    const lightboxIconLike = useRef<null | HTMLButtonElement>(null);

    // Pour les petits écrans les boutons s'affichent en bas d'écran et non à droite
    // la boîte de commentaires se déroule à la demande ( au clic)
    const commentsDrodpown: boolean = window.innerWidth < 768 ? true : false

    return (
        <div className="offCanvas-buttons">
            {/* Lecture du diaporama */}
            <div className="play-button">
                {play === false ?
                    <FontAwesomeIcon icon={faPlayCircle} className="play-slides" onClick={() => dispatch(playCarousel(true))} />
                    :
                    <FontAwesomeIcon icon={faStopCircle} className="play-slides" onClick={() => dispatch(playCarousel(false))} />
                }
            </div>

            <div className="other-functions-buttons">
                {/* affichage / masquage des commentaires : petits écrans */}
                {commentsDrodpown && (
                    <div className="comments">
                        <ButtonWithTooltip
                            trigger={['hover', 'click']}
                            tooltip={{
                                ...lightboxButtons.commentsDropdown.tooltip,
                                content: t('see-comments')
                            }}
                            icon={lightboxButtons.commentsDropdown.icon}
                            button={lightboxButtons.commentsDropdown.button}
                            onClick={() => dispatch(openComments(!open))}
                        />
                        {mediasList[currentSlide!].nbComments > 0 && (
                            <Badge id="comments-counter">{currentMedia.nbComments}</Badge>
                        )}
                    </div>
                )}

                {/* Ajouter un média dans un album: ouvre le modal correspondant */}
                {!albumShared && allAlbums.length !== 0 && (
                    <ButtonWithTooltip
                        trigger={['hover', 'click']}
                        tooltip={{
                            ...lightboxButtons.addToAlbum.tooltip,
                            content: t('add-to-album')
                        }}
                        icon={lightboxButtons.addToAlbum.icon}
                        button={lightboxButtons.addToAlbum.button}
                        onClick={() => dispatch(addToAlbumModal(true))}
                    />
                )}

                {/* Liker le média */}
                <div className="likes">
                    <ButtonWithTooltip
                        trigger={['hover', 'click']}
                        tooltip={{
                            ...lightboxButtons.like.tooltip,
                            content: t('like')
                        }}
                        icon={{
                            ...lightboxButtons.like.icon,
                            // Si le média en cours d'affichage contient un like appartenant à l'utilisateur on "allume" l'icône
                            classname: `likeInSlide-icon ${currentMedia.likes?.find((like: any) => like.owner_id === userId) !== undefined ? 'alight' : ''}`
                        }}
                        button={{ ...lightboxButtons.like.button, buttonRef: lightboxIconLike }}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            // Si l'utilisateur n'est pas connecté on affiche le message en popover
                            // lui indiquant que la connexion est obligatoire pour cette action
                            if (userId === undefined) {
                                // Si un popover était déjà ouvert (pour les commentaires), on le referme pour afficher le nouveau au bon emplacement
                                if (popover.show) {
                                    dispatch(openCanILikeOrCommentPopover(false, currentMedia._id, '', null))
                                }
                                dispatch(openCanILikeOrCommentPopover(true, currentMedia._id, 'liker', 'lightbox'));
                                // Si l'utilisateur est connecté on like ou dislike le média au clic
                            } else {
                                e.currentTarget.classList.contains('alight') ? dispatch(dislikeMedia(currentMedia._id)) : dispatch(likeMedia(currentMedia._id))
                                e.currentTarget.classList.toggle('alight');
                            }
                        }}
                    />
                    { /* Compteur de likes */}
                    {currentMedia.nbLikes > 0 && (
                        <Badge id="likes-counter">{currentMedia.nbLikes}</Badge>
                    )}
                </div>

                {!albumShared && (
                    <>
                        {/* Bouton pour la modification d'une answerkiss */}
                        {currentMedia.type_media === 'answerkiss' && (
                            <ButtonWithTooltip
                                trigger={['hover', 'click']}
                                tooltip={{
                                    ...lightboxButtons.editAnswerkiss.tooltip,
                                    content: t('edit-answerkiss')
                                }}
                                icon={lightboxButtons.editAnswerkiss.icon}
                                button={lightboxButtons.editAnswerkiss.button}
                                // au clic on injecte dans les champs contrôlés, les valeurs actuelles de question / réponse
                                // on passe en mode édition et on ouvre le modal de modification
                                onClick={() => {
                                    dispatch(handleAnswerkissFields('question', currentMedia.answerKissQuestion))
                                    dispatch(handleAnswerkissFields('answer', currentMedia.answerKissAnswer))
                                    dispatch(editAnswerkiss(true))
                                    dispatch(openAnswerKissModal(true))
                                }}
                            />
                        )}

                        {/* Bouton pour la suppression du média */}
                        <ButtonWithTooltip
                            trigger={['hover', 'click']}
                            tooltip={{
                                ...lightboxButtons.delete.tooltip,
                                content: pathname.includes('/all-medias') ? t('delete.from-all-medias') : t('delete.from-album')
                            }}
                            icon={lightboxButtons.delete.icon}
                            button={lightboxButtons.delete.button}
                            onClick={() => dispatch(confirmDeleteMedia(true))}
                        />
                    </>
                )}
            </div>

            { /* Popover indiquant l'obligation de connexion pour pouvoir liker */}
            <LikeOrCommentPopover
                target={lightboxIconLike}
                mediaId={currentMedia?._id}
                container="lightbox"
                action="liker"
                placement="bottom"
            />
        </div>
    )
}

export default OffCanvasButtons;