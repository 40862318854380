import { useAppSelector } from "../hooks"

export const MediasLayoutStore: Function = () => {
    const mediasLayout = useAppSelector(state => state.mediasLayout)
    return mediasLayout
}

export const GeneralLayoutStore: Function = () => {
    const generalLayout = useAppSelector(state => state.generalLayout)
    return generalLayout
}

export const AlbumLayoutStore: Function = () => {
    const albumLayout = useAppSelector(state => state.albumLayout)
    return albumLayout
}

export const LightboxLayoutStore: Function = () => {
    const lightbox = useAppSelector(state => state.lightbox)
    return lightbox
}

export const LoginLayoutStore: Function = () => {
    const login = useAppSelector(state => state.login)
    return login
}

export const ModalsLayoutStore: Function = () => {
    const modalsLayout = useAppSelector(state => state.modals)
    return modalsLayout
}