/*******************************************************************
          Composant pour la liste des médias
*******************************************************************/

// Hook
import { useRef } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
// Composants "locaux"
import LoaderButton from "../../components/Buttons/loaderButton";
import SingleMedia from "./singleMedia";
// Stores
import { GeneralLayoutStore } from "../../app/store/layout";
// Actions
import { getResponsiveContainerWidth } from "../../app/actions/layout/album";
// Types
import { Media } from "../../app/types/media";
// Styles
import './mediasList.scss';
// Assets
import { calculMediaSize } from "./calculMediaSize";


const MediasList: Function = ({ mediasList }: { mediasList: Media[] }): JSX.Element => {
    // Dispatcheur d'action Redux
    const dispatch = useAppDispatch();

    // Récupération des states
    const { isLoading } = GeneralLayoutStore();

    // utilisation du hook useRef pour cibler le conteneur de la liste des médias dans le DOM
    const mediasContainer = useRef<null | HTMLDivElement>(null);
    // msie en variable de la taille du conteneur
    const mediasContainerWidth: number = mediasContainer.current ? parseInt(window.getComputedStyle(mediasContainer.current).width) : 0;


    // enregistrement de la largeur du conteneur dans le store au chargement du composant
    useEffect(() => {
        setTimeout(() => {
            dispatch(getResponsiveContainerWidth(mediasContainerWidth));
        }, 200);
    }, [mediasContainerWidth, dispatch])

    // enregistrement de la largeur du conteneur dans le store chaque fois que celle-ci change (redimensionnement de la fenêtre)
    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('resize', () => dispatch(getResponsiveContainerWidth(mediasContainerWidth)))
        }, 200);
    }, [mediasContainerWidth, dispatch])


    calculMediaSize(mediasContainerWidth, mediasList)

    return (
        <div className="row">
            <div className="col">
                <div className="medias" ref={mediasContainer}>

                    {!isLoading ?

                        <ul className="medias-list">
                            {mediasList.map((media: Media, index: number) => (
                                <li key={media._id} className="media-item">
                                    <SingleMedia
                                        alt={media.type_media}
                                        src={media.url_allMedias}
                                        width={media.width}
                                        height={media.height}
                                        marginRight={media.marginRight}
                                        index={index}
                                        id={media._id}
                                        likes={media.likes}
                                        commentsCount={media.nbComments}
                                        pictureKey={media.timestamp ? media.timestamp : media.url_allMedias.webp}
                                    />
                                </li>
                            ))}
                        </ul>

                        :

                        <LoaderButton />

                    }

                </div>
            </div>
        </div>
    )
}

export default MediasList;