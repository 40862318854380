/*******************************************************************
                Composant pour les vignettes indiquant 
                la progression de chargement des médias
*******************************************************************/

// Hooks
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// Composants de librairies
import { ToastContainer, Toast } from "react-bootstrap";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Stores
import { UploadStore } from "../../app/store/media";
// Actions
import { MediaUploadProgress } from "../../app/types/media";


const UploadToast: Function = (): JSX.Element | false => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.upload' });

    // utilisation du hook useRef pour cibler le conteneur des toasts
    const toastContainer = useRef<null | HTMLDivElement>(null);

    // Effet de bord
    // lorsque des toast sont affichés on fait défiler automatiquement l'affichage à leur niveau
    // en bas de page (cf la feuille de style) afin de les visualiser et de visualiser également
    // les nouveaux médias qui apparaissent progressivement
    useEffect(() => {
        /*if (toastContainer.current) {
            toastContainer.current.scrollIntoView()
        }*/
    })

    let uploadingFilesForToast: { progress: number, nameForToast: string }[] = [];

    // récupération de la liste des médias et leur progression d'upload dans le store
    const { mediaUploadProgress } = UploadStore();

    // On ne prend en compte que les médias en cours d'upload 
    const uploadingFiles: MediaUploadProgress[] =
        mediaUploadProgress.filter((file: MediaUploadProgress) => file.progress > 0 && file.progress < 100);

    if (uploadingFiles.length !== 0) {
        // on met à jour pour chacun d'eux le nom du fichier qui va s'afficher dans le toast
        // si le nom du fichier (sans extension) contient plus de 30 caractères
        // on le réduit à 17 caractères + ... + extension
        uploadingFilesForToast = uploadingFiles.map((file: MediaUploadProgress) => {
            let fileName: string = file.file.name;
            const fileNameWithoutExtension: string = fileName.split('.')[0];
            const fileExtension: string = fileName.split('.')[1];
            if (fileNameWithoutExtension.length > 30) {
                fileName = fileNameWithoutExtension.substring(0, (20 - 3 - fileExtension.length)) + '...' + fileExtension;
            }
            return { progress: file.progress, nameForToast: fileName }
        })
    }

    return (
        uploadingFilesForToast.length !== 0 && (
            <ToastContainer className="upload-files-list" ref={toastContainer}>
                {uploadingFilesForToast.map(file => (
                    <Toast key={file.nameForToast} className="upload-file-item">
                        <Toast.Header closeButton={false}>{t('toasts-container')}</Toast.Header>
                        <Toast.Body>
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            <span>{file.nameForToast}</span>
                            <div className="upload-progress-bar" style={{ width: `${file.progress}%` }} />
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        )
    );
}

export default UploadToast;