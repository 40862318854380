// Styles
import './primaryButton.scss';

// typage des props
type PrimaryButtonProps = {
    buttonType: "button" | "submit" | "reset" | undefined,
    buttonName: string,
    formAttached: string,
    className: string | undefined,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => any,
};

const PrimaryButton: Function = ({ buttonName, buttonType, formAttached, onClick, className }: PrimaryButtonProps): JSX.Element => (
    <button
        type={buttonType === undefined ? "button" : buttonType}
        // permet de rattacher le bouton à un formulaire si il est placé en dehors de ce formulaire
        // et donc de permettre ledéclenchement du submit sur ce form
        form={formAttached}
        className={`btn btnMenuPrimary ${className !== undefined ? className : ''}`}
        onClick={onClick}>{buttonName}
    </button>
);

export default PrimaryButton;
