/*******************************************************************
Champs pour le form de connexion (également présents sur l'inscription)
*******************************************************************/

// Hooks
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
// Composants "locaux"
import Field from "../../components/Field/field";
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields";
import { GeneralLayoutStore } from "../../app/store/layout";
// Actions
import { handleUserFields } from "../../app/actions/controlledFields";
import { displayLoginMessageError } from "../../app/actions/layout/general";

const ConnectFields: Function = () => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.login.signIn.fields' })
    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch()

    // Récupération des states
    const { email, password } = ControlledFieldsStore().user
    const { showPassword: show } = GeneralLayoutStore()

    // Liste des champs
    const signinFieldsProps = [
        {
            labelHtml: 'email-input',
            labelName: t('email'),
            placeholder: undefined,
            inputType: 'email',
            inputName: 'email',
            required: true,
            maxLength: 1000,
            action: (e: React.ChangeEvent<HTMLInputElement>) => {
                // masquage du message d'erreur de connexion à la saisie
                dispatch(displayLoginMessageError(false))
                // champs contrôlé
                dispatch(handleUserFields('email', e.target.value))
            },
            inputValue: email
        },
        {
            labelHtml: 'password-input',
            labelName: t('password'),
            placeholder: undefined,
            inputType: show ? 'text' : 'password',
            inputName: 'password',
            required: true,
            maxLength: 1000,
            action: (e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(displayLoginMessageError(false))
                dispatch(handleUserFields('password', e.target.value))
            },
            inputValue: password
        }
    ];

    return (
        signinFieldsProps.map(({ labelHtml, labelName, inputType, inputName, required, maxLength, action, inputValue }): JSX.Element => (
            <Field
                className={undefined}
                key={labelHtml}
                labelHtml={labelHtml}
                labelName={labelName}
                inputType={inputType}
                inputName={inputName}
                required={required}
                inputValue={inputValue}
                maxLength={maxLength}
                action={action}
            />
        ))
    )
}

export default ConnectFields