import { useAppSelector } from "../hooks"
import { InitialState as AllAlbumsStates } from "../types/states/album/allAlbums"
import { InitialState as SharedAlbumsStates } from "../types/states/album/sharedAlbums"
import { InitialState as SingleAlbumStates } from "../types/states/album/singleAlbum"

export const AllAlbumsStore: Function = () => {
    const allAlbums: AllAlbumsStates = useAppSelector(state => state.allAlbums)
    return allAlbums
}

export const SharedAlbumsStore: Function = () => {
    const sharedAlbums: SharedAlbumsStates = useAppSelector(state => state.sharedAlbums)
    return sharedAlbums
}

export const SingleAlbumStore: Function = () => {
    const singleAlbum: SingleAlbumStates = useAppSelector(state => state.singleAlbum)
    return singleAlbum
}