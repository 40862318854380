import { buttonsENtranslation } from "./components/Buttons/buttonsENtranslation";
import { albumENtranslation } from "./components/features/albumENtranslation";
import { feedbackENtranslation } from "./components/features/feedbackENtranslation";
import { footerENtranslation } from "./components/features/footerENtranslation";
import { headerENtranslation } from "./components/features/headerENtranslation";
import { homeENtranslation } from "./components/features/homeENtranslation";
import { lightboxENtranslation } from "./components/features/lightboxENtranslation";
import { loginENtranslation } from "./components/features/loginENtranslation";
import { lostPasswordENtranslation } from "./components/features/lostPasswordENtranslation";
import { mediasENtranslation } from "./components/features/mediasENtranslation";
import { navabarENtranslation } from "./components/features/navbarENtranslation";
import { profileENtranslation } from "./components/features/profileENtranslation";
import { uploadENtranslation } from "./components/features/uploadENtranslation";
import { modalsENtranslation } from "./components/modals/modalsENtranslation";
import { popoverENtranslation } from "./components/Popover/popoverENtranslation";

export const translationEN = {
    "components": {
        "modals": {
            ...modalsENtranslation
        },
        "buttons": {
            ...buttonsENtranslation
        },
        "popover": {
            ...popoverENtranslation
        }
    },
    "features": {
        "album": {
            ...albumENtranslation
        },
        "login": {
            ...loginENtranslation
        },
        "feedback": {
            ...feedbackENtranslation
        },
        "footer": {
            ...footerENtranslation
        },
        "header": {
            ...headerENtranslation
        },
        "home": {
            ...homeENtranslation
        },
        "lightbox": {
            ...lightboxENtranslation
        },
        "lostPassword": {
            ...lostPasswordENtranslation
        },
        "medias": {
            ...mediasENtranslation
        },
        "layout": {
            ...navabarENtranslation
        },
        "profile": {
            ...profileENtranslation
        },
        "upload": {
            ...uploadENtranslation
        }
    }
}