// Hooks
import { Link } from 'react-router-dom';
// Composants de librairies
import { useTranslation } from 'react-i18next';
// Styles
import './footer.scss';

const Footer: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.footer' });

    return (
        <div className="footer bg-primary">
            <Link to="/">{t('terms')}</Link>
            <div>{`@anoukiss ${new Date().getFullYear()}`}</div>
        </div>
    );
}

export default Footer;
