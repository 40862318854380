/*******************************************************************
          Liste des boutons présents dans la lightbox
*******************************************************************/

// Icônes
import { faCommentDots, faHeart, faPenSquare, faSquarePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

export const lightboxButtons = {
    delete: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "delete"
        },
        icon: {
            icon: faTrashCan,
            classname: "delete-icon"
        }
    },
    editAnswerkiss: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "change-answerkiss"
        },
        icon: {
            icon: faPenSquare,
            classname: "change-icon"
        }
    },
    addToAlbum: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "add"
        },
        icon: {
            icon: faSquarePlus,
            classname: "add-icon"
        }
    },
    like: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "likeInSlide"
        },
        icon: {
            icon: faHeart,
            classname: "likeInSlide-icon",
        }
    },
    commentsDropdown: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "comments-dropdown"
        },
        icon: {
            icon: faCommentDots,
            classname: "comments-icon"
        }
    },
    deleteComment: {
        tooltip: {
            placement: "bottom",
            classname: "lightbox-tooltips",
        },
        button: {
            classname: "delete-comment"
        },
        icon: {
            icon: faTrashCan,
            classname: "delete-comment-icon"
        }
    }
}