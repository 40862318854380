/*******************************************************************
          Composant pour l'affichage du profil
*******************************************************************/

// Hooks
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks"
import { useTranslation } from "react-i18next";
// Composants "locaux"
import { ProfileFields } from "./profileFields";
import PrimaryButton from "../../components/Buttons/primaryButton";
import SecondaryButton from "../../components/Buttons/secondaryButton";
import Field from "../../components/Field/field";

// Stores
import { UserStore } from "../../app/store/user";
// Actions
import { clearFields, handleUserFields } from "../../app/actions/controlledFields";
import { updateProfile } from "../../app/actions/user";
// Type
import { ProfileField } from "./profileFields";
// Style
import './profile.scss';


const Profile: Function = (): JSX.Element => {
    const { t } = useTranslation('', { keyPrefix: 'features.profile' })
    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch()
    // Récupération des states
    const { firstname, lastname, email } = UserStore().userData

    // Efet de bord
    // On peuple par défaut les champs du form avec les valeurs connues des informations de l'utilisateur
    useEffect(() => {
        dispatch(handleUserFields('firstname', firstname!))
        dispatch(handleUserFields('lastname', lastname!))
        dispatch(handleUserFields('email', email!))
    }, [dispatch, firstname, lastname, email])

    return (
        <div className="profile marginMenuLeft">
            <h1 className="profile-header">Mon Compte</h1>
            <form
                id="profile"
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(updateProfile());
                }}
            >
                {ProfileFields().map(({
                    labelHtml,
                    labelName,
                    placeholder,
                    inputType,
                    inputName,
                    inputValue,
                    maxLength,
                    action
                }: ProfileField) => (
                    <Field
                        key={labelHtml}
                        placeholder={placeholder}
                        labelName={labelName}
                        labelHtml={labelHtml}
                        inputName={inputName}
                        inputType={inputType}
                        inputValue={inputValue}
                        maxLength={maxLength}
                        action={action}
                    />
                ))}
                <div className="buttons">
                    <SecondaryButton
                        buttonName={t('update.cancel')}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            dispatch(clearFields('user'))
                            dispatch(handleUserFields('firstname', firstname!))
                            dispatch(handleUserFields('lastname', lastname!))
                            dispatch(handleUserFields('email', email!))
                        }}
                    />
                    <PrimaryButton
                        buttonType="submit"
                        buttonName={t('update.validate')}
                        formAttached="profile"
                    />
                </div>
            </form>
        </div>
    )
}

export default Profile