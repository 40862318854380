// Importation du type Middleware de redux
import { Middleware } from "redux";
import { getAllMedias } from "../../app/actions/media/mediasList";
import { getAllAlbums } from "../../app/actions/album/allAlbums";
// Importantion du module de connexion websocket
import { io } from "socket.io-client";
// Importation des actions utilisées
import { EMIT_TO_WEBSOCKET, LISTEN_WEBSOCKET } from "../actions/socket";
import { addNewUploadedMediaToAlbum } from "../actions/album/singleAlbum";
import { addUploadedMedia, generateTimestampKey, putAllMediasInState } from "../actions/media/mediasList";
// Importation de types
import { Media } from "../types/media";
// Variables d'environnement contenant l'url de connexion au websocket
const { REACT_APP_WEBSOCKET_URL } = process.env;
// déclaration de la connexion
const socket = io(`${REACT_APP_WEBSOCKET_URL}`);

const socketMiddleware: Middleware = store => next => action => {
    switch (action.type) {
        // envoi du token d'authentification au serveur
        case EMIT_TO_WEBSOCKET:
            socket.emit('addToken', action.token)
            break;
        // écoute de la réception du média après traitement sur le serveur, au fur et à mesure de l'upload
        case LISTEN_WEBSOCKET:
            socket.on('newImage', ({ image }) => {
                const newImage = {
                    _id: image.mediaId,
                    height: image.height,
                    width: image.width,
                    serveurMedia: image.serveur,
                    status: image.status,
                    type_media: 'image',
                    albums: [],
                    comments: [],
                    likes: [],
                    nbComments: 0
                }

                store.dispatch(getAllMedias());
                store.dispatch(getAllAlbums());

                /*switch (store.getState().singleAlbum.uploadingMediaInAlbum) {
                    case true:
                        store.dispatch(addNewUploadedMediaToAlbum(newImage))
                        break;
                    case false:
                        store.dispatch(addUploadedMedia(newImage))
                        break;
                    default:
                }*/
            })

            socket.on('newVideo', ({ video }) => {
                const newVideo = {
                    _id: video.mediaId,
                    height: video.height,
                    width: video.width,
                    serveurMedia: video.serveur,
                    status: video.status,
                    type_media: 'video',
                    albums: [],
                    comments: [],
                    likes: [],
                    nbComments: 0
                }

                store.dispatch(getAllMedias());
                store.dispatch(getAllAlbums());
                /*switch (store.getState().singleAlbum.uploadingMediaInAlbum) {
                    case true:
                        store.dispatch(addNewUploadedMediaToAlbum(newVideo))
                        break;
                    case false:
                        store.dispatch(addUploadedMedia(newVideo))
                        break;
                    default:
                }*/
            })

            socket.on('newAnswerkiss', ({ newAnswerkiss: answerkiss }) => {
                const newAnswerkiss = {
                    _id: answerkiss.mediaId,
                    height: answerkiss.height,
                    width: answerkiss.width,
                    serveurMedia: answerkiss.serveur,
                    status: answerkiss.status,
                    type_media: 'answerkiss',
                    answerKissAnswer: answerkiss.answerKissAnswer,
                    answerKissQuestion: answerkiss.answerKissQuestion,
                    albums: [],
                    comments: [],
                    likes: [],
                    nbComments: 0
                }

                store.dispatch(getAllMedias());
                store.dispatch(getAllAlbums());
                /*switch (store.getState().singleAlbum.uploadingMediaInAlbum) {
                    case true:
                        store.dispatch(addNewUploadedMediaToAlbum(newAnswerkiss))
                        break;
                    case false:
                        store.dispatch(addUploadedMedia(newAnswerkiss))
                        break;
                    default:
                }*/
            })

            socket.on('updateAnswerkiss', ({ newAnswerkiss }) => {
                const { allMedias } = store.getState().mediasList

                const allMediasUpdated = allMedias.map((media: Media) => {
                    if (media._id === newAnswerkiss.mediaId) {
                        media = { ...media, answerKissAnswer: newAnswerkiss.answerKissAnswer, answerKissQuestion: newAnswerkiss.answerKissQuestion }
                    }
                    return ({ ...media })
                })
                store.dispatch(putAllMediasInState(allMediasUpdated))
                store.dispatch(generateTimestampKey(newAnswerkiss.mediaId))
            })
            break;
    }
    next(action);
}

export default socketMiddleware;