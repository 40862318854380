import { Media } from "../../types/media";

// Vérouillage du nom des actions
export const LIKE_MEDIA: string = 'LIKE_MEDIA';
export const DISLIKE_MEDIA: string = 'DISLIKE_MEDIA';
export const SEND_COMMENT: string = 'SEND_COMMENT';
export const DELETE_COMMENT: string = 'DELETE_COMMENT';
export const DELETE_MEDIA: string = 'DELETE_MEDIA';
export const PATCH_ANSWERKISS: string = 'PATCH_ANSWERKISS';

// Liker un média => middleware
export function likeMedia(mediaId: string) {
    return ({
        type: LIKE_MEDIA,
        mediaId: mediaId
    })
}

// Supprimer le like sur un média => middleware
export function dislikeMedia(mediaId: string) {
    return ({
        type: DISLIKE_MEDIA,
        mediaId: mediaId
    })
}

// Envoyer un commentaire sur un média => middleware
export function sendComment(mediaId: string) {
    return ({
        type: SEND_COMMENT,
        mediaId: mediaId,
    })
}

// supprimer le commentaire sur un média => middleware
export function deleteComment(mediaId: string, commentId: string) {
    return ({
        type: DELETE_COMMENT,
        mediaId: mediaId,
        commentId: commentId
    })
}

// suppression d'un media => middleware
export function deleteMedia(media: Media) {
    return ({
        type: DELETE_MEDIA,
        media: media
    })
}

// modifier une answerkiss => middleware
export function patchAnswerkiss() {
    return ({
        type: PATCH_ANSWERKISS
    })
}