// utilisée pour calculer la taille des médias de façon responsive en fonction de la laergeurd de la fenêtre

import { Media } from "../../app/types/media";

/**
 * 
 * @param containerWidth 
 * @param allMedias 
 */
export function calculMediaSize(containerWidth: number, allMedias: Media[]) {
    // largeur d'une ligne de Médias
    let lineWidth: number = 0;
    // Liste des médias sur la ligne
    let mediasInLine: Media[] = [];
    // marge à droite (gouttière) pour un média
    let mediaMarginRight: number = 10;
    // ratio utilisé pour le calcul de la taille responsive
    let mediaRatio: number = 0;

    let maxLineHeight: number = 0;

    // LOOP
    allMedias.forEach((media: Media) => {
        // incrémentation de la largeur de la ligne suivant la largeur de chaque média un à un
        lineWidth += media.width;


        // Si la largeur de la ligne obtenue est supérieure ou égale à la largeur du conteneur
        if (lineWidth >= containerWidth) {
            media.marginRight = 0;
            mediasInLine.push(media);

            // On calcule le ratio de mise à l'échelle ( rétrécissement ) en intégrant le calcul des marges à droite
            // et en fonction du nombres de media contenu sur la ligne
            const sumMargeRight = (mediasInLine.length - 1) * mediaMarginRight;
            mediaRatio = (containerWidth - sumMargeRight) / (lineWidth - sumMargeRight);

            if (media.height * mediaRatio > maxLineHeight) {
                maxLineHeight = media.height * mediaRatio
            }
            // On réajuste la hauteur et la largeur de chaque média contenu sur la ligne en fonction du ratio
            mediasInLine.forEach((mediaToScale: Media) => {
                mediaToScale.width = mediaToScale.width * mediaRatio;
                mediaToScale.height = mediaToScale.height * mediaRatio;
            });
            // On réinitialise les largeurs pour une nouvelle ligne et on vide le tableau des médias associé
            lineWidth = 0;
            mediasInLine = [];
        } else {
            lineWidth += mediaMarginRight;
            media.marginRight = 10;
            mediasInLine.push(media);
        }
    });
    // Calcul de la hauteur de la dernière ligne si elle n'est pas remplie
    if (mediasInLine.length && maxLineHeight) {
        mediasInLine.forEach(imageToChangeSize => {
            let ratioImage = maxLineHeight / imageToChangeSize.height;
            imageToChangeSize.width = imageToChangeSize.width * ratioImage;
            imageToChangeSize.height = imageToChangeSize.height * ratioImage;
        });
    }
}
