// Vérouillage du nom des actions
export const HANDLE_IS_LOADING: string = 'HANDLE_IS_LOADING';
export const OPEN_FEEDBACK: string = 'OPEN_FEEDBACK';
export const THANKS_FOR_FEEDBACK: string = 'THANKS_FOR_FEEDBACK';
export const DISPLAY_PASSWORD_CHANGED_MESSAGE: string = 'DISPLAY_PASSWORD_CHANGED_MESSAGE';
export const DISPLAY_LOGIN_MESSAGE_ERROR: string = 'DISPLAY_LOGIN_MESSAE_ERROR';
export const DISPLAY_REGISTER_MESSAGE_ERROR: string = 'DISPLAY_REGISTER_MESSAGE_ERROR';
export const SHOW_PASSWORD: string = 'SHOW_PASSWORD';
export const OPEN_DIALOG_FILES_FOR_UPLOAD: string = 'OPEN_DIALOG_FILES_FOR_UPLOAD';
export const GET_HEADER_HEIGHT: string = 'GET_HEADER_HEIGHT';
export const OPEN_NAVBAR: string = 'OPEN_NAVBAR';

// mise en place d'un statut loading pour l'attente des requêtes et afficher une icône de chargement => reducer
export function handleIsLoading(isLoading: boolean) {
    return ({
        type: HANDLE_IS_LOADING,
        isLoading: isLoading,
    })
}

// ouvrir le panneau pour l'envoi de feedback => reducer
export function openFeedback(open: boolean) {
    return ({
        type: OPEN_FEEDBACK,
        open: open
    })
}

// afficher un message de remerciement pour l'envoi de feedback => reducer
export function thanksForFeedback(thanks: boolean) {
    return ({
        type: THANKS_FOR_FEEDBACK,
        thanks: thanks
    })
}

// afficher le message de confirmation après le changement de mot de passe => reducer
export function displayPasswordChangedMessage(display: boolean) {
    return ({
        type: DISPLAY_PASSWORD_CHANGED_MESSAGE,
        display: display
    })
}

// afficher le message d'erreur en cas d'erreur (catch) de login => reducer
export function displayLoginMessageError(display: boolean) {
    return ({
        type: DISPLAY_LOGIN_MESSAGE_ERROR,
        display: display
    })
}

// afficher le message d'erreur en cas d'erreur (catch) de login => reducer
export function displayRegisterMessageError(display: boolean) {
    return ({
        type: DISPLAY_REGISTER_MESSAGE_ERROR,
        display: display
    })
}

// afficher le mot de passe en clair lors de la saisie => reducer
export function showPassword(show: boolean) {
    return ({
        type: SHOW_PASSWORD,
        show: show
    })
}

// obtenir la hauteur du header (pour pouvoir placer la navbar au clic sur le bouton correspondant pour le responsive) => reducer
export function getHeaderHeight(height: number | undefined) {
    return ({
        type: GET_HEADER_HEIGHT,
        height: height
    })
}

// dérouler la navabr au clic sur le bouton (responsive) => reducer
export function openNavbar(open: boolean) {
    return ({
        type: OPEN_NAVBAR,
        open: open
    })
}