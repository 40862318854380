// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { Api_URL, instance } from "../config";
// importation d'axios pour les requêtes non configurées (ne nécessitant pas d'authentification)
import axios from "axios";
// Importation des actions utilisées
import {
    GET_SHARED_ALBUM_MEDIAS,
    putSharedAlbumMediasInState,
    DELETE_SHARE,
    putAlbumsSharedWithMeInState,
    GET_ALBUMS_SHARED_WITH_ME,
    SUGGEST_SHARES,
    getAlbumsSharedWithMe,
} from "../../actions/album/sharedAlbums";
import { displayAlbumShared } from "../../actions/layout/album";
import { putSuggestedSharesInState } from "../../actions/layout/modals";
import { updateSharingContactsInSingleAlbum } from "../../actions/album/singleAlbum";

const sharedAlbumsMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case GET_ALBUMS_SHARED_WITH_ME:
            instance.get('/album-shares')
                .then(response => {
                    // Stockage de la liste reçue des albums partagés avec moi dans le store
                    store.dispatch(putAlbumsSharedWithMeInState(response.data.albums))
                })
                .catch(error => console.log(error));
            break;

        case GET_SHARED_ALBUM_MEDIAS:
            axios.get(`${Api_URL}/album-share/${action.albumId}/${action.shareId} `)
                .then(response => {
                    // Stockage de l'album correspondant au lien de partage dans le store pour affichage
                    const album = response.data.album
                    store.dispatch(putSharedAlbumMediasInState(album._id, album.title, album.medias))
                })
                .catch(error => {
                    // Si le serveur renvoie un code 403 Forbidden,
                    // on affiche le message indiquant que l'utilisateur ne peut pas consulter cet album
                    if (error.response.status === 403) {
                        store.dispatch(displayAlbumShared(false))
                    }
                });
            break;

        case DELETE_SHARE:
            {
                // Récupération dans le store des informations concernant le partage à révoquer
                // (id du partage, id de l'album, email de partage à révoquer)
                const { shareToDelete: shareId } = store.getState().modals.deleteShare;
                const { id: albumId } = store.getState().singleAlbum.singleAlbum;
                const { email: sharedemailToDelete } = store.getState().singleAlbum.singleAlbum.sharingContacts.find((contact: { [key: string]: any }) => contact.id === shareId)
                if (sharedemailToDelete !== 'partage public') {
                    instance.delete(`/album-share/${albumId}/${shareId}`)
                        .then(response => {
                            // Mise à jour de la liste des partages après révocation
                            store.dispatch(updateSharingContactsInSingleAlbum('delete', shareId, sharedemailToDelete))
                            // si l'email de partage révoqué est le même que celui de l'utilisateur,
                            // (dans le cas où il aurait préalablement partagé un album avec son propre email)
                            // on met à jour la liste des albums partagés avec lui (évite le refresh)
                            const { email: userEmail } = store.getState().user.userData
                            if (sharedemailToDelete === userEmail) {
                                store.dispatch(getAlbumsSharedWithMe())
                            }
                        })
                        .catch(error => console.log(error));
                } else {
                    const { sharingContacts } = store.getState().singleAlbum.singleAlbum;
                    const tokenPublic = sharingContacts.find((contact: { [key: string]: any }) => contact.email === "partage public").id
                    instance.delete(`/album-share-public/${albumId}`)
                        .then(response => {
                            store.dispatch(updateSharingContactsInSingleAlbum('delete', tokenPublic, sharedemailToDelete))
                        })
                        .catch(error => console.log(error))
                }
            }
            break;

        case SUGGEST_SHARES:
            {
                // Récupération dans le store de la valeur du champ contrôlé correspondant à l'email de partage
                const { emailForShare } = store.getState().controlledFields.singleAlbum;
                // Envoi au serveur
                instance.post(`album-share-contact`, {
                    email: emailForShare
                })
                    .then(response => {
                        // Envoi dans le store des emails renvoyés par le serveur pour l'autocomplétion
                        store.dispatch(putSuggestedSharesInState(response.data))
                    })
                    .catch(error => console.log(error));
            }
            break;

        default:
    }

    next(action);
}

export default sharedAlbumsMiddleware;
