// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/modals';
// Importations des actions utilisées
import {
    ADD_TO_ALBUM_MODAL,
    ASK_FOR_CHANGING_PASSWORD_MESSAGE,
    CLEAR_SUGGESTED_SHARES,
    CONFIRM_DELETE_ALBUM,
    CONFIRM_DUPLICATE_ALBUM,
    CONFIRM_DELETE_MEDIA,
    CONFIRM_DELETE_SHARE,
    CREATE_ALBUM_NEXT_STEP,
    IS_EMAIL_ALREADY_IN_SHARES,
    OPEN_ANSWERKISS_MODAL,
    OPEN_ANSWERKISS_HELP,
    OPEN_CHANGING_PASSWORD_MODAL,
    OPEN_SHARE_ALBUM_MODAL,
    PUT_SUGGESTED_SHARES_IN_STATE,
    SHOW_ADD_MEDIA_IN_ALBUM,
    SHOW_CREATE_ALBUM_MODAL
} from '../../actions/layout/modals';

const initialState: InitialState = {
    addAnswerkiss: {
        show: false,
        showHelp: false
    },
    deleteMedia: {
        show: false,
    },
    deleteAlbum: {
        show: false,
        albumToDelete: undefined,
    },
    duplicateAlbum: {
        show: false,
        albumToDuplicate: undefined,
    },
    deleteShare: {
        show: false,
        shareToDelete: undefined,
    },
    shareAlbum: {
        show: false,
        suggestedShares: [],
        emailAlreadyInShares: false
    },
    createAlbum: {
        show: false,
        firstStep: true,
    },
    updateAlbum: {
        show: false
    },
    addMediaInSingleAlbum: {
        show: false
    },
    changingPassword: {
        show: false,
        showMessage: false
    }
}

function modalsReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case OPEN_ANSWERKISS_MODAL:
            return ({
                ...state,
                addAnswerkiss: {
                    show: action.show,
                    showHelp: state.addAnswerkiss.showHelp
                }
            })

        case OPEN_ANSWERKISS_HELP:
            return ({
                ...state,
                addAnswerkiss: {
                    show: state.addAnswerkiss.show,
                    showHelp: action.show
                }
            })

        case CONFIRM_DELETE_MEDIA:
            return ({
                ...state,
                deleteMedia: {
                    show: action.show,
                }
            })

        case CONFIRM_DELETE_ALBUM:
            return ({
                ...state,
                deleteAlbum: {
                    show: action.show,
                    albumToDelete: action.albumId
                }
            })

        case CONFIRM_DUPLICATE_ALBUM:
            return ({
                ...state,
                duplicateAlbum: {
                    show: action.show,
                    albumToDuplicate: action.albumId
                }
            })

        case SHOW_CREATE_ALBUM_MODAL:
            return ({
                ...state,
                createAlbum: {
                    ...state.createAlbum,
                    show: action.show
                }
            })

        case ADD_TO_ALBUM_MODAL:
            return ({
                ...state,
                updateAlbum: {
                    show: action.show
                }
            })

        case SHOW_ADD_MEDIA_IN_ALBUM:
            return ({
                ...state,
                addMediaInSingleAlbum: {
                    show: action.show
                }
            })

        case OPEN_SHARE_ALBUM_MODAL:
            return ({
                ...state,
                shareAlbum: {
                    ...state.shareAlbum,
                    show: action.show
                }
            })

        case CONFIRM_DELETE_SHARE:
            return ({
                ...state,
                deleteShare: {
                    show: action.show,
                    shareToDelete: action.shareId
                }
            })

        case PUT_SUGGESTED_SHARES_IN_STATE:
            return ({
                ...state,
                shareAlbum: {
                    ...state.shareAlbum,
                    suggestedShares: action.shares
                }
            })

        case CLEAR_SUGGESTED_SHARES:
            return ({
                ...state,
                shareAlbum: {
                    ...state.shareAlbum,
                    suggestedShares: []
                }
            })

        case OPEN_CHANGING_PASSWORD_MODAL:
            return ({
                ...state,
                changingPassword: {
                    show: action.show,
                    showMessage: false
                }
            })

        case ASK_FOR_CHANGING_PASSWORD_MESSAGE:
            return ({
                ...state,
                changingPassword: {
                    ...state.changingPassword,
                    showMessage: true
                }
            })

        case IS_EMAIL_ALREADY_IN_SHARES:
            return ({
                ...state,
                shareAlbum: {
                    ...state.shareAlbum,
                    emailAlreadyInShares: action.alreadyShared
                }
            })

        case CREATE_ALBUM_NEXT_STEP:
            return ({
                ...state,
                createAlbum: {
                    ...state.createAlbum,
                    firstStep: !action.next,
                }
            })
        default:
            return state
    }
}

export default modalsReducer;