import { Media } from "../../types/media";

// Vérouillage du nom des actions
export const GET_ALL_MEDIAS: string = 'GET_ALL_MEDIAS';
export const PUT_ALL_MEDIAS_IN_STATE: string = 'PUT_ALL_MEDIAS_IN_STATE';
export const ADD_UPLOADED_MEDIA: string = 'ADD_UPLOADED_MEDIA';
export const GENERATE_TIMESTAMP_KEY: string = 'GENERATE_TIMESTAMP_KEY';

// obtenir tous les medias d'un utilisateur => middleware
export function getAllMedias() {
    return ({
        type: GET_ALL_MEDIAS,
    })
}

// stocker la liste des médias dans le store => reducer
export function putAllMediasInState(allMedias: Media[]) {
    return ({
        type: PUT_ALL_MEDIAS_IN_STATE,
        allMedias: allMedias,
    })
}

// ajouer un nouveau media dans la liste de média après un upload => reducer
export function addUploadedMedia(media: Media) {
    return ({
        type: ADD_UPLOADED_MEDIA,
        media: media
    })
}

// Ajouter une clé timestamp (valeur Date.now()) au answerkiss pour les actualiser après update => reducer
export function generateTimestampKey(mediaId: string) {
    return ({
        type: GENERATE_TIMESTAMP_KEY,
        mediaId: mediaId
    })
}