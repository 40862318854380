// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/login';
// Importations des actions utilisées
import { DISPLAY_CONNECT, DISPLAY_REGISTER } from '../../actions/layout/login';

const initialState: InitialState = {
    openConnect: false,
    openRegister: false,
}

function loginReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case DISPLAY_CONNECT:
            return ({
                ...state,
                openConnect: true,
                openRegister: false,
            });

        case DISPLAY_REGISTER:
            return ({
                ...state,
                openRegister: true,
                openConnect: false,
            });

        default:
            return state
    }
}

export default loginReducer;