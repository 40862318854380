// Importations des actions utilisées
import {
    CLEAR_SELECTED_MEDIA_FOR_ALBUM,
    PUT_ALL_ALBUMS_IN_STATE,
    SELECT_MEDIA_FOR_ALBUM
} from '../../actions/album/allAlbums';
// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/album/allAlbums';

const initialState: InitialState = {
    allAlbums: [],
    selectedMediasForAlbum: [],
}

function allAlbumsReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case PUT_ALL_ALBUMS_IN_STATE:
            return ({
                ...state,
                allAlbums: action.albums
            })

        case SELECT_MEDIA_FOR_ALBUM:
            {
                // Récupération de la liste de médias sélectionnés ( au clic ) pour l'ajout dans un album
                const selectedMediasForAlbum = [...state.selectedMediasForAlbum];
                // Si la liste contient déjà le média sélectionné, on le supprime, sinon on l'ajoute
                if (selectedMediasForAlbum.includes(action.mediaId) === true) {
                    selectedMediasForAlbum.splice(selectedMediasForAlbum.indexOf(action.mediaId), 1)
                } else {
                    selectedMediasForAlbum.push(action.mediaId)
                }
                return ({
                    ...state,
                    selectedMediasForAlbum: selectedMediasForAlbum
                })
            }

        case CLEAR_SELECTED_MEDIA_FOR_ALBUM:
            return ({
                ...state,
                selectedMediasForAlbum: []
            })

        default:
            return state
    }
}

export default allAlbumsReducer