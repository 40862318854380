import { MediaFileToUpload } from "../../types/media";

// Vérouillage du nom des actions
export const PUT_UPLOAD_FILE_IN_STATE: string = 'PUT_UPLOAD_FILE_IN_STATE';
export const UPLOAD_MEDIA: string = 'UPLOAD_MEDIA';
export const UPDATE_FILE_UPLOAD_STATUS: string = 'UPDATE_FILE_UPLOAD_STATUS';
export const UPDATE_PROGRESS_ON_UPLOAD_FILE: string = 'UPDATE_PROGRESS_ON_UPLOAD_FILE';
export const UPDATE_FILES_TO_UPLOAD_QUEUE: string = 'UPDATE_FILES_TO_UPLOAD_QUEUE';
export const POST_ANSWERKISS: string = 'POST_ANSWERKISS';

// enregistrement de la liste des fichiers à uploader dans le store => reducer
export function putUploadFileInState(mediaFilesToUpload: MediaFileToUpload[]) {
    return ({
        type: PUT_UPLOAD_FILE_IN_STATE,
        mediaFilesToUpload: mediaFilesToUpload
    })
}

// envoie d'un fichier à uploader au serveur => middleware
export function uploadMedia(media: File, albumId: string | undefined) {
    return ({
        type: UPLOAD_MEDIA,
        media: media,
        albumId: albumId
    })
}

// mise à jour du statut "to send" pour "sending" lors de l'upload du fichier correspondant => reducer
export function updateFileUploadStatus(file: File, status: string) {
    return ({
        type: UPDATE_FILE_UPLOAD_STATUS,
        file: file,
        status: status
    })
}

// mise à jour du pourcentage d'upload d'un fichier pour l'affichage de la barre de progression dans les toast => reducer
export function updateProgressOnUploadFile(file: File, progress: number) {
    return ({
        type: UPDATE_PROGRESS_ON_UPLOAD_FILE,
        progress: progress,
        file: file
    })
}

// suppression des fichiers à uploader dans le store en fonction de leur statut => reducer
export function updateFilesToUploadQueue() {
    return ({
        type: UPDATE_FILES_TO_UPLOAD_QUEUE,
    })
}

// uploader une answerkiss
export function postAnswerkiss() {
    return ({
        type: POST_ANSWERKISS
    })
}