import { Album } from "../../../app/types/album";
import { Media } from "../../../app/types/media";

export function findThumbnail(pathname: string, allMedias: Media[], album: Album, extension: string) {
    let findThumbnailMedia = null
    let thumbnailUrl = ''

    if (pathname !== '/shared-with-me') {
        findThumbnailMedia = allMedias.find((media: Media) => {
            let firstMediaInAlbum = null;
            album.medias.forEach((albumMedia: Media) => {
                if (albumMedia._id === media._id) {
                    firstMediaInAlbum = albumMedia._id
                }
            })
            return media._id === firstMediaInAlbum
        })
    } else {
        if (album.medias.length !== 0) {
            findThumbnailMedia = album.medias[0]
        }
    }

    switch (extension) {
        case 'webp':
            thumbnailUrl = findThumbnailMedia?.url_album?.webp
            break;
        case 'jpg':
            thumbnailUrl = findThumbnailMedia?.url_album?.jpg
            break;
        default:
    }

    return thumbnailUrl
}