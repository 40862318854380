// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/medias';
// Importations des actions utilisées
import {
    OPEN_CAN_I_LIKE_OR_COMMENT_POPOVER,
    GET_COMMENTS_OFFCANVAS_SIZE,
    OPEN_ALL_MEDIAS_MENU,
    EDIT_ANSWERKISS,
} from '../../actions/layout/medias';


const initialState: InitialState = {
    editAnswerkiss: false,
    openMenu: false,
    offcanvasSize: {
        width: 0,
        height: 0
    },
    popover: {
        show: false,
        media: '',
        action: '',
        refContainer: null
    },
};

function mediasLayoutReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case OPEN_ALL_MEDIAS_MENU:
            return ({
                ...state,
                openMenu: action.open
            })

        case OPEN_CAN_I_LIKE_OR_COMMENT_POPOVER:
            return ({
                ...state,
                popover: {
                    show: action.open,
                    media: action.mediaId,
                    action: action.action,
                    refContainer: action.refContainer
                }
            })

        case GET_COMMENTS_OFFCANVAS_SIZE:
            return ({
                ...state,
                offcanvasSize: {
                    width: action.width,
                    height: action.height
                }
            })

        case EDIT_ANSWERKISS:
            return ({
                ...state,
                editAnswerkiss: action.edit
            })

        default:
            return state;
    }
}

export default mediasLayoutReducer;