/// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/media/upload';
import { MediaFileToUpload, MediaUploadProgress } from "../../types/media";
// Importations des actions utilisées
import {
    PUT_UPLOAD_FILE_IN_STATE,
    UPDATE_FILES_TO_UPLOAD_QUEUE,
    UPDATE_FILE_UPLOAD_STATUS,
    UPDATE_PROGRESS_ON_UPLOAD_FILE
} from "../../actions/media/upload";

const initialState: InitialState = {
    mediaFilesToUpload: [],
    mediaUploadProgress: []
}

function uploadReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case PUT_UPLOAD_FILE_IN_STATE:
            {
                // liste des fichiers à uploader avec leur statut
                const mediaFilesToUpload: MediaFileToUpload[] = [...state.mediaFilesToUpload];
                mediaFilesToUpload.push(...action.mediaFilesToUpload);

                // liste des fichiers à uploader avec leur pourcentage d'upload
                const mediaUploadProgress: MediaUploadProgress[] = [...state.mediaUploadProgress];
                mediaUploadProgress.push(...action.mediaFilesToUpload.map((file: MediaFileToUpload) => ({ file: file.file, progress: 0 })))

                return ({
                    ...state,
                    mediaFilesToUpload: mediaFilesToUpload,
                    mediaUploadProgress: mediaUploadProgress
                })
            }

        case UPDATE_FILE_UPLOAD_STATUS:
            {
                const mediaFilesToUpload: MediaFileToUpload[] = [...state.mediaFilesToUpload].map((fileToUpload: MediaFileToUpload) => {
                    return fileToUpload.file.name === action.file.name ? { ...fileToUpload, status: action.status } : fileToUpload
                })

                return ({
                    ...state,
                    mediaFilesToUpload: mediaFilesToUpload
                })
            }

        case UPDATE_PROGRESS_ON_UPLOAD_FILE:
            {
                const mediaUploadProgress: MediaUploadProgress[] = [...state.mediaUploadProgress].map((fileUploading: MediaUploadProgress) => {
                    return fileUploading.file.name === action.file.name ? { ...fileUploading, progress: action.progress } : fileUploading
                })

                return ({
                    ...state,
                    mediaUploadProgress: mediaUploadProgress
                })
            }

        case UPDATE_FILES_TO_UPLOAD_QUEUE:
            {
                // liste des médias ayant été uploadés à 100%
                const mediaFilesUploaded: MediaUploadProgress[] = [...state.mediaUploadProgress].filter(file => file.progress === 100);
                // On supprime de la liste des médias à uploader les médias ayant été uploadés à 100%
                const mediaFilesToUpload = [...state.mediaFilesToUpload].filter((file, index, array) => {
                    // On cherche si un média fait partie des deux tableaux
                    const mediaFilesToDelete = mediaFilesUploaded.find(uploadedFile => uploadedFile.file.name === file.file.name)
                    // Si c'est le cas la condition du filtrage est que l'index du média ne soit pas égal à lui-même
                    // Comme cette condition est toujours fausse, le média ne sera pas retourné dans le nouveau tableau filtré
                    // Si il n'y a pas de média à supprimer on retourne le tableau
                    return mediaFilesToDelete ? index !== array.indexOf(file) : array
                })
                // on supprime également les médias uploadés à 100% de la liste de progression d'upload des médias
                const mediaUploadProgress = [...state.mediaUploadProgress].filter(file => file.progress !== 100)

                return ({
                    ...state,
                    mediaFilesToUpload: mediaFilesToUpload,
                    mediaUploadProgress: mediaUploadProgress
                })
            }
        default:
            return state
    }
}

export default uploadReducer;