/*******************************************************************
   Composant pour la redirection après inscription (/confirmation)
*******************************************************************/

// Hooks
import { useTranslation } from 'react-i18next';
// Composants de librairies
import { Link } from 'react-router-dom';
// Styles
import './confirmation.scss';

const Confirmation: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.login.register.confirmation' })

    return (
        <div className="confirmation">
            <p>{t('success')}<br />{t('welcom')}</p>
            <p><Link to='/login/signin'>{t('singIn.link')}</Link>{t('singIn.phrase')}</p>
        </div>
    );
}

export default Confirmation;