// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/layout/lightbox';
// Importations des actions utilisées
import {
    CHANGE_CURRENT_SLIDE,
    CHANGE_SLIDES_INTERVAL,
    OPEN_COMMENTS,
    OPEN_LIGHTBOX,
    PLAY_CAROUSEL
} from '../../actions/layout/lightbox';

const initialState: InitialState = {
    open: false,
    currentSlide: undefined,
    interval: null,
    play: false,
    openComments: true,
}

function lightboxReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case OPEN_LIGHTBOX:
            return ({
                ...state,
                open: action.open,
                currentSlide: action.mediaIndex,
            })

        case CHANGE_SLIDES_INTERVAL:
            return ({
                ...state,
                interval: action.interval
            })

        case PLAY_CAROUSEL:
            {
                // Si le carousel est en lecture automatique on définit un interval de 5s entre chaque slide
                // sinon on affecte la valeur null à l'interval pour stopper le défilement
                let interval
                if (action.play === true) {
                    interval = 5000
                } else {
                    interval = null
                }
                return ({
                    ...state,
                    interval: interval,
                    play: action.play
                })
            }

        case CHANGE_CURRENT_SLIDE:
            return ({
                ...state,
                currentSlide: action.currentSlide
            })

        case OPEN_COMMENTS:
            return ({
                ...state,
                openComments: action.open
            })
        default:
            return state
    }
}

export default lightboxReducer;