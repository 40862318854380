// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/album/singleAlbum';
import { Media } from '../../types/media';
import { Album } from '../../types/album';
// Importations des actions utilisées
import {
    ADD_NEW_UPLOADED_MEDIA_TO_ALBUM,
    EDIT_SINGLE_ALBUM_TITLE_IN_STATE,
    MEDIA_UPLOADING_IN_ALBUM,
    POPULATE_SINGLE_ALBUM_IN_STATE,
    UPDATE_SHARING_CONTACTS_IN_SINGLE_ALBUM
} from '../../actions/album/singleAlbum';
// Importation de la fonction permettant la création des urls pour l'affichage des médias
import { composeUrlsForMedia } from '../functions';

const initialState: InitialState = {
    singleAlbum: {
        id: '',
        title: '',
        medias: [],
        sharingContacts: []
    },
    uploadingMediaInAlbum: false,
}

function singleAlbumReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case POPULATE_SINGLE_ALBUM_IN_STATE:
            {
                // Création d'un tableau de média vide
                let mediasInAlbum: Media[] = []
                // récupération de l'album dans la liste des albums en recherchant par id
                const currentAlbum = action.allAlbums.find((album: Album) => album._id === action.albumId)
                // Un album contient une liste d'id de médias qui lui appartiennent,
                // On récupère donc pour chaque id, le média qui correspond dans la liste de tous les médias
                // et on l'ajoute au tableau de médias initialement créé
                currentAlbum!.medias.forEach((mediaInAlbum: Media) => {
                    const mediaFromAll = action.allMedias.find((media: Media) => media._id === mediaInAlbum._id)
                    mediasInAlbum.push({ ...mediaFromAll })
                })
                // Dans le tableau de médias peuplé on filtre les medias ayant un statut "ready"
                // (c'est-à-dire dont le traitement a été effectué par le serveur)
                const mediasReadyInAlbum = mediasInAlbum.filter((media: Media) => media.status === "ready")
                // on ajoute également à l'album les liens de partages qui y sont liés
                const sharingContacts = currentAlbum.shares.map((contact: any) => ({ id: contact._id, email: contact.email }))
                if (currentAlbum.tokenPublic) {
                    sharingContacts.unshift({ id: currentAlbum.tokenPublic, email: "partage public" })
                }
                return ({
                    ...state,
                    singleAlbum: {
                        id: action.albumId,
                        title: currentAlbum!.title,
                        medias: mediasReadyInAlbum,
                        sharingContacts: sharingContacts.length !== 0 ? sharingContacts : []
                    }
                })
            }

        case EDIT_SINGLE_ALBUM_TITLE_IN_STATE:
            return ({
                ...state,
                singleAlbum: {
                    ...state.singleAlbum,
                    title: action.title
                }
            })

        case MEDIA_UPLOADING_IN_ALBUM:
            return ({
                ...state,
                uploadingMediaInAlbum: action.uploading
            })

        case ADD_NEW_UPLOADED_MEDIA_TO_ALBUM:
            {
                const mediasInAlbum = [...state.singleAlbum.medias]
                if (mediasInAlbum.find((media: Media) => media._id === action.media._id) === undefined) {
                    // mise en place des urls pour l'affichage des médias en fonction des dimensions souhaitées (album, lightbox, etc)
                    const newUploadedMedia = composeUrlsForMedia(action.media)
                    mediasInAlbum.push(newUploadedMedia)
                    action.media.albums.push(state.singleAlbum.id)
                }
                return ({
                    ...state,
                    singleAlbum: {
                        ...state.singleAlbum,
                        medias: mediasInAlbum
                    }
                })
            }

        case UPDATE_SHARING_CONTACTS_IN_SINGLE_ALBUM:
            {
                let sharingContacts = [...state.singleAlbum.sharingContacts];
                switch (action.action) {
                    // suppression d'un contact
                    case 'delete':
                        sharingContacts.splice(sharingContacts.findIndex(contact => contact.id === action.id), 1)
                        break;
                    // ajout d'un contact
                    case 'add':
                        sharingContacts.push({ id: action.id, email: action.email })
                        break;
                    default:
                }
                return ({
                    ...state,
                    singleAlbum: {
                        ...state.singleAlbum,
                        sharingContacts: sharingContacts
                    }
                })
            }

        default:
            return state
    }
}

export default singleAlbumReducer