// Features
import Header from './features/header/header';
import Footer from './features/footer/footer';
import FeedBack from './features/feedback/feedback';
import LayoutContent from './features/layout/layoutContent';


// styles
import './App.scss';
import './index.scss';

const App: Function = (): JSX.Element => {
    return (
        <div className="app">
            <Header />
            <LayoutContent />
            <FeedBack />
            <Footer />
        </div>
    );
}

export default App;