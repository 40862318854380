/***************** Vérouillage du nom des actions *****************/
// ADD ANSWERKISS
export const OPEN_ANSWERKISS_MODAL: string = 'OPEN_ANSWERKISS_MODAL';
export const OPEN_ANSWERKISS_HELP: string = 'OPEN_ANSWERKISS_HELP';
// DELETE MEDIA
export const CONFIRM_DELETE_MEDIA: string = 'CONFIRM_DELETE_MEDIA';
// CREATE ALBUM
export const SHOW_CREATE_ALBUM_MODAL: string = 'SHOW_CREATE_ALBUM_MODAL';
export const CREATE_ALBUM_NEXT_STEP: string = 'CREATE_ALBUM_NEXT_STEP';
// DELETE ALBUM
export const CONFIRM_DELETE_ALBUM: string = 'CONFIRM_DELETE_ALBUM';
// DUPLICATE ALBUM
export const CONFIRM_DUPLICATE_ALBUM: string = 'CONFIRM_DUPLICATE_ALBUM';
// ADD MEDIA TO ALBUM
export const ADD_TO_ALBUM_MODAL: string = 'ADD_TO_ALBUM_MODAL';
export const SHOW_ADD_MEDIA_IN_ALBUM: string = 'SHOW_ADD_MEDIA_IN_ALBUM';
// SHARE ALBUM
export const OPEN_SHARE_ALBUM_MODAL: string = 'OPEN_SHARE_ALBUM_MODAL';
export const PUT_SUGGESTED_SHARES_IN_STATE: string = 'PUT_SUGGESTED_SHARES_IN_STATE';
export const CLEAR_SUGGESTED_SHARES: string = 'CLEAR_SUGGESTED_SHARES';
export const IS_EMAIL_ALREADY_IN_SHARES: string = 'IS_EMAIL_ALREADY_IN_SHARES';
// DELETE SHARE
export const CONFIRM_DELETE_SHARE: string = 'CONFIRM_DELETE_SHARE';
// CHANGE PASSWORD (PASSWORD LOST)
export const OPEN_CHANGING_PASSWORD_MODAL: string = 'OPEN_CHANGING_PASSWORD_MODAL';
export const ASK_FOR_CHANGING_PASSWORD_MESSAGE: string = 'ASK_FOR_CHANGING_PASSWQORD_MESSAGE';

// Affichage du modal pour confirmer la suppression d'un média => reducer
export function confirmDeleteMedia(show: boolean) {
    return ({
        type: CONFIRM_DELETE_MEDIA,
        show: show,
    })
}

// Affichage du modal pour la création d'un album => reducer
export function showCreateAlbumModal(show: boolean) {
    return ({
        type: SHOW_CREATE_ALBUM_MODAL,
        show: show
    })
}

// Affichage du modal pour confirmer la suppression d'un album => reducer
export function confirmDeleteAlbum(show: boolean, albumId: string | undefined) {
    return ({
        type: CONFIRM_DELETE_ALBUM,
        show: show,
        albumId: albumId
    })
}

// Affichage du modal pour confirmer la duplication d'un album => reducer
export function confirmDuplicateAlbum(show: boolean, albumId: string | undefined) {
    return ({
        type: CONFIRM_DUPLICATE_ALBUM,
        show: show,
        albumId: albumId
    })
}


// Affichage du modal permettant d'ajouter un média à un album déjà existant (via la lightbox) => reducer
export function addToAlbumModal(show: boolean) {
    return ({
        type: ADD_TO_ALBUM_MODAL,
        show: show,
    })
}

// Affichage du modal permettant de partager un album
export function openShareAlbumModal(show: boolean) {
    return ({
        type: OPEN_SHARE_ALBUM_MODAL,
        show: show,
    })
}

// Affichage du modal pour confirmer la révocation d'un lien de partage
export function confirmDeleteShare(show: boolean, shareId: string | undefined) {
    return ({
        type: CONFIRM_DELETE_SHARE,
        show: show,
        shareId: shareId
    })
}

// Stockage des emails ayant déjà utilisés pour le partage dans le store afin de proposer une autocomplétion => reducer
export function putSuggestedSharesInState(shares: string[]) {
    return ({
        type: PUT_SUGGESTED_SHARES_IN_STATE,
        shares: shares
    })
}

// Vide la liste des emails suggérés pour l'autocomplétion => reducer
export function clearSuggestedShares() {
    return ({
        type: CLEAR_SUGGESTED_SHARES
    })
}

// Affichage du modal permettant l'ajout d'un média dans un album parmi la totalité des médias => reducer
export function showAddMediaInAlbum(show: boolean) {
    return ({
        type: SHOW_ADD_MEDIA_IN_ALBUM,
        show: show
    })
}

// Affichage du modal pour l'ajout ou la modification d'une answerkiss (si le mode édition est à ture) => reducer
export function openAnswerKissModal(show: boolean) {
    return ({
        type: OPEN_ANSWERKISS_MODAL,
        show: show
    })
}

// masque/masque l'aide aux questions answerKiss 
export function openAnswerKissHelp(show: boolean) {
    return ({
        type: OPEN_ANSWERKISS_HELP,
        show: show
    })
}

// Affichage du modal permettant l'envoi de son email en cas de mot de passe oublié
export function openChangingPasswordModal(show: boolean) {
    return ({
        type: OPEN_CHANGING_PASSWORD_MODAL,
        show: show
    })
}

// Ouverture du modal pour le changement de mot de passe => reducer
export function askForChangingPasswordMessage(show: boolean) {
    return ({
        type: ASK_FOR_CHANGING_PASSWORD_MESSAGE,
        show: show
    })
}

// afficher un message pour une demande de partage si l'email transmis au serveur est déjà dans la liste des partages => reducer
export function isEmailAlreadyInShares(alreadyShared: boolean) {
    return ({
        type: IS_EMAIL_ALREADY_IN_SHARES,
        alreadyShared: alreadyShared
    })
}

// passer à l'étape de sélection de médias lors de la création d'un album (après avoir saisi un titre) => reducer
export function createAlbumNextStep(next: boolean) {
    return ({
        type: CREATE_ALBUM_NEXT_STEP,
        next: next
    })
}