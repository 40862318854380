/*******************************************************************
               Liste des champs pour le formulaire
                    de modification du profil
*******************************************************************/

// Hooks
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../app/hooks"
// Stores
import { ControlledFieldsStore } from "../../app/store/controlledFields"
import { UserStore } from "../../app/store/user"
// Actions
import { handleUserFields } from "../../app/actions/controlledFields"


export type ProfileField = {
    labelHtml: string,
    labelName: string,
    placeholder: string | undefined,
    inputName: string,
    inputType: string,
    inputValue: string,
    maxLength: number,
    action: Function
}

export const ProfileFields: Function = (): ProfileField[] => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.profile.update.fields' })
    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch()

    // Récupération des states
    const {
        firstname: firstnameField,
        lastname: lastnameField,
        email: emailField,
        password: passwordField
    } = ControlledFieldsStore().user

    const { firstname, lastname, email } = UserStore().userData


    return (
        [
            {
                labelHtml: 'firstname-input',
                labelName: t('firstname'),
                placeholder: firstname,
                inputName: 'firstname',
                inputType: 'text',
                maxLength: 1000,
                // Champ contrôlé
                action: (e: React.ChangeEvent<HTMLInputElement>) => { dispatch(handleUserFields('firstname', e.target.value)) },
                inputValue: firstnameField
            },
            {
                labelHtml: 'lastname-input',
                labelName: t('lastname'),
                placeholder: lastname,
                inputType: 'text',
                inputName: 'lastname',
                maxLength: 1000,
                // Champ contrôlé
                action: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(handleUserFields('lastname', e.target.value)),
                inputValue: lastnameField
            },
            {
                labelHtml: 'email-input',
                labelName: t('email'),
                placeholder: email,
                inputName: 'email',
                inputType: 'email',
                maxLength: 1000,
                // Champ contrôlé
                action: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(handleUserFields('email', e.target.value)),
                inputValue: emailField
            },
            {
                labelHtml: 'password-input',
                labelName: t('password'),
                inputName: 'password',
                inputType: 'password',
                placeholder: undefined,
                maxLength: 1000,
                // Champ contrôlé
                action: (e: React.ChangeEvent<HTMLInputElement>) => dispatch(handleUserFields('password', e.target.value)),
                inputValue: passwordField
            },
        ]
    )
}