import { Album } from "../../types/album";
import { Media } from "../../types/media";

// Vérouillage du nom des actions
export const GET_ALBUMS_SHARED_WITH_ME: string = 'GET_ALBUMS_SHARED_WITH_ME';
export const PUT_ALBUMS_SHARED_WITH_ME_IN_STATE: string = 'PUT_ALBUMS_SHARED_WITH_ME_IN_STATE';
export const GET_SHARED_ALBUM_MEDIAS: string = 'GET_SHARED_ALBUM_MEDIAS';
export const PUT_SHARED_ALBUM_MEDIAS_IN_STATE: string = 'PUT_SHARED_ALBUM_MEDIAS_IN_STATE';
export const DELETE_SHARE: string = 'DELETE_SHARE';
export const SUGGEST_SHARES: string = 'SUGGEST_SHARES';

// Obtenir la liste des albums qui ont été partagés avec moi => middleware
export function getAlbumsSharedWithMe() {
    return ({
        type: GET_ALBUMS_SHARED_WITH_ME,
    })
}

// Mettre la liste des albums partagés avec moi dans le store => reducer
export function putAlbumsSharedWithMeInState(albums: Album[]) {
    return ({
        type: PUT_ALBUMS_SHARED_WITH_ME_IN_STATE,
        albums: albums
    })
}

// Obtenir la liste des médias d'un album en fonction de l'adresse de partage => middleware
export function getSharedAlbumMedias(albumId: string | undefined, shareId: string | undefined) {
    return ({
        type: GET_SHARED_ALBUM_MEDIAS,
        albumId: albumId,
        shareId: shareId
    })
}

// Mettre la liste des médias d'un lien d'album partagé dams le store => reducer
export function putSharedAlbumMediasInState(id: string, title: string, medias: Media[]) {
    return ({
        type: PUT_SHARED_ALBUM_MEDIAS_IN_STATE,
        id: id,
        title: title,
        medias: medias,
    })
}

// Révoquer un lien de partage => middleware
export function deleteShare() {
    return ({
        type: DELETE_SHARE
    })
}


// Obtenir la liste des emails déjà utilisés pour des partages => middleware
export function suggestShares() {
    return ({
        type: SUGGEST_SHARES,
    })
}

