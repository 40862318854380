// verouillage du nom des actions
export const HANDLE_USER_FIELDS: string = 'HANDLE_USER_FIELDS';
export const CLEAR_FIELDS: string = 'CLEAR_FIELDS';
export const HANDLE_NEW_ALBUM_TITLE: string = 'HANDLE_NEW_ALBUM_TITLE';
export const HANDLE_SELECTED_ALBUM: string = 'HANDLE_SELECTED_ALBUM';
export const HANDLE_SINGLE_ALBUM_TITLE: string = 'HANDLE_SINGLE_ALBUM_TITLE';
export const HANDLE_EMAIL_FOR_SHARE: string = 'HANDLE_EMAIL_FOR_SHARE';
export const HANDLE_COMMENT_TO_SEND: string = 'HANDLE_COMMENT_TO_SEND';
export const HANDLE_FEEDBACK: string = 'HANDLE_FEEDBACK';
export const HANDLE_ANSWERKISS_FIELDS: string = 'HANDLE_ANSWERKISS_FIELDS';

// champs contrôlés du formulaire de connexion/inscription => reducer
export function handleUserFields(fieldName: 'firstname' | 'lastname' | 'email' | 'password' | 'resetPassword', fieldValue: string) {
    return ({
        type: HANDLE_USER_FIELDS,
        field: fieldName,
        fieldValue: fieldValue,
    });
}

// champ contrôlé pour le titre d'un nouvel album => reducer
export function handleNewAlbumTitle(fieldValue: string) {
    return ({
        type: HANDLE_NEW_ALBUM_TITLE,
        fieldValue: fieldValue,
    })
}

// réinitialisation des champs contrôlés d'un formulaire => reducer
export function clearFields(fieldsList: 'user' | 'answerkiss' | 'newAlbum' | 'addMediaToAlbum' | 'singleAlbum' | 'commentToSend' | 'feedback') {
    return ({
        type: CLEAR_FIELDS,
        fieldsList: fieldsList,
    });
}

// champ contrôlé pour la sélection d'un album dans la liste des albums pour l'ajout d'un média parmi la liste des médias => reducer
export function handleSelectedAlbum(selectedAlbum: string) {
    return ({
        type: HANDLE_SELECTED_ALBUM,
        selectedAlbum: selectedAlbum
    })
}

// champ contrôlé pour la modification du titre d'un album => reducer
export function handleSingleAlbumTitle(albumTitle: string) {
    return ({
        type: HANDLE_SINGLE_ALBUM_TITLE,
        albumTitle: albumTitle,
    })
}

// champ contrôlé pour l'envoi d'un email dans le formulaire de partage d'un album => reducer
export function handleEmailForShare(email: string) {
    return ({
        type: HANDLE_EMAIL_FOR_SHARE,
        email: email
    })
}

// champ contrôlé du formulaire d'envoi de commentaire => reducer
export function handleCommentToSend(comment: string) {
    return ({
        type: HANDLE_COMMENT_TO_SEND,
        comment: comment,
    })
}

// champ contrôlé pour l'envoi dun feedback => reducer
export function handleFeedback(feedback: string) {
    return ({
        type: HANDLE_FEEDBACK,
        feedback: feedback
    })
}

// champ contrôlé pour l'envoi d'une answerkiss => reducer
export function handleAnswerkissFields(fieldName: 'question' | 'answer' | 'albumIdSend', fieldValue: string) {
    return ({
        type: HANDLE_ANSWERKISS_FIELDS,
        field: fieldName,
        fieldValue: fieldValue,
    })
}
