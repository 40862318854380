// Vérouillage du nom des actions
export const OPEN_LIGHTBOX: string = 'OPEN_LIGHTBOX';
export const CHANGE_SLIDES_INTERVAL: string = 'CHANGE_SLIDES_INTERVAL';
export const PLAY_CAROUSEL: string = 'PLAY_CAROUSEL';
export const CHANGE_CURRENT_SLIDE: string = 'CHANGE_CURRENT_SLIDE';
export const OPEN_COMMENTS: string = 'OPEN_COMMENTS';

// Ouverture de la lightbox pour le diaporama sur allMedias => reducer
export function openLightbox(open: boolean, mediaIndex: number | null) {
    return ({
        type: OPEN_LIGHTBOX,
        open: open,
        mediaIndex: mediaIndex
    })
}

// Lecture automatique du carousel de médias dans la lightbox => reducer
export function playCarousel(play: boolean) {
    return ({
        type: PLAY_CAROUSEL,
        play: play
    })
}

// changement de l'interval de temps entre deux slides du carousel pour la lecture automatique => reducer
export function changeSlidesInterval(interval: number | null) {
    return ({
        type: CHANGE_SLIDES_INTERVAL,
        interval: interval,
    })
}

// changement de l'index du média dans le slide en cours d'affichage du carousel => reducer
export function changeCurrentSlide(index: number) {
    return ({
        type: CHANGE_CURRENT_SLIDE,
        currentSlide: index
    })
}

// ouvrir les commentaires (responsive) => reducer
export function openComments(open: boolean) {
    return ({
        type: OPEN_COMMENTS,
        open: open
    })
}