import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import {translation as translationFR} from './locales/fr/translation';

import { translationEN } from './locales/en/translationEN';
import { translationFR } from './locales/fr/translationFR';
const resources = {
    fr: {
        translation: translationFR,
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        lng: 'fr',
        interpolation: {
            escapeValue: false,
        },
        resources
    });

export default i18n;