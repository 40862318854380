/*******************************************************************
        Composant pour la page d'accueil (url: /)
*******************************************************************/

// Styles
import './home.scss';

import votreHistoire from '../../assets/images/home/votre-histoire.jpg';
import vosPhotos from '../../assets/images/home/vos-photos.jpg';
import vosDocuments from '../../assets/images/home/vos-documents.jpg';
import vosVideos from '../../assets/images/home/vos-videos-compress.mp4';
import partagerVotreHistoire from '../../assets/images/home/partager-votre-histoire.jpg';
import boiteSouvenirs from '../../assets/images/home/boite-souvenirs.jpg';
import livrePhotos from '../../assets/images/home/livre-photo-offrir.jpg';

const Home: Function = (): JSX.Element => {
    return (
        <div className="home-container">

            <div className="row">
                <div className="col">
                    <h1>Anoukiss, votre boîte à souvenirs <span className='text-rouge'>à partager</span></h1>
                </div>
            </div>



            <div className="row columReverse">
                <div className='col-gauche'>
                    <img src={votreHistoire} alt='votre histoire' />
                </div>
                <div className='col-droite'>
                    <div className='titre-col'>Votre histoire</div>
                    <div className='description-col'>Sous forme de questions/réponses, racontez : votre enfance, votre parcours scolaire, vos passions...</div>
                </div>
            </div>

            <div className="row">
                <div className='col-gauche'>
                    <div className='titre-col'>Vos photos</div>
                    <div className='description-col'>Créez de jolies boîtes à souvenirs avec vos photos</div>
                </div>
                <div className='col-droite'>
                    <img src={vosPhotos} alt='vos photos' />
                </div>
            </div>

            <div className="row columReverse">
                <div className='col-gauche'>
                    <video autoPlay muted loop width="600" src={vosVideos}>
                    </video>
                </div>
                <div className='col-droite'>
                    <div className='titre-col'>Vos vidéos</div>
                    <div className='description-col'>Conservez les vidéos riches en émotions</div>
                </div>
            </div>

            <div className="row">
                <div className='col-gauche'>
                    <div className='titre-col'>Vos documents</div>
                    <div className='description-col'>Numérisez les documents inoubliables (dessin d'enfant, lettre d'amour, coupure de journal, recette de cuisine, poème, ...)</div>
                </div>
                <div className='col-droite'>
                    <img src={vosDocuments} alt='vos documents' />
                </div>
            </div>




            {/* 
            <PrimaryButton
                type="button"
                buttonName={t('registerButton')}
                onClick={() => {
                    navigate('/login/register');
                }}
            />
            */}


            <div className="row">
                <div className="col">
                    <h2 className='text-center'>Partagez tous vos souvenirs avec vos proches</h2>
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <img src={partagerVotreHistoire} alt='Partagez votre histoire' />
                    <div className='descriptionPhoto text-center'>Votre histoire se transmet au fil des générations</div>
                </div>
                <div className="col">
                    <img src={boiteSouvenirs} alt='Boite à souvenirs' />
                    <div className='descriptionPhoto text-center'>Le plaisir d'ouvrir une boîte à photos</div>
                </div>
                <div className="col">
                    <img src={livrePhotos} alt='livre photos' />
                    <div className='descriptionPhoto text-center'>Un livre photos à offrir ou à s'offrir</div>
                </div>
            </div>


        </div>
    );
}

export default Home;