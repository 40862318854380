/*******************************************************************
      Composant pour afficher les médias en mode diaporama
*******************************************************************/

// Hooks
import { useAppDispatch } from "../../app/hooks";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// Composants "locaux"
import DeleteConfirmation from "../../components/Modals/deleteConfirmation";
import AddToAlbumList from "../../components/Modals/addToAlbumList";
import Comments from "./comments/comments";
import CreateAlbum from "../../components/Modals/createAlbum";
import Slides from "./slides";
// Stores
import { LightboxLayoutStore, ModalsLayoutStore } from "../../app/store/layout";
import { MediasListStore } from "../../app/store/media";
// Actions
import { confirmDeleteMedia } from "../../app/actions/layout/modals";
import { deleteMediaInAlbum } from "../../app/actions/album/singleAlbum";
import { deleteMedia } from "../../app/actions/media/singleMedia";
import { changeCurrentSlide } from "../../app/actions/layout/lightbox";
import { openCanILikeOrCommentPopover } from "../../app/actions/layout/medias";
// Types
import { Media } from "../../app/types/media";
// Style
import './lightbox.scss';

type LightboxProps = {
    mediasList: Media[],
    interval: number | null,
    albumId: string,
    albumShared: boolean
}

const Lightbox: Function = ({ mediasList, interval, albumId, albumShared }: LightboxProps): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.lightbox' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération du chemin dans l'url
    const { pathname } = useLocation();

    // Récupération des states
    const { currentSlide } = LightboxLayoutStore()
    const { allMedias } = MediasListStore()

    // Effet de bord 
    // Fermeture du popover contenant le message de connexion / inscription pour pouvoir liker ou commenter
    // Si ce dernier était ouvert (sur la liste des médias par exemple)
    useEffect(() => {
        dispatch(openCanILikeOrCommentPopover(false, mediasList[currentSlide!]?._id, '', ''))
    }, [dispatch, mediasList, currentSlide])

    return (
        <div className="lightbox" >
            <Slides mediasList={mediasList} interval={interval} />
            <Comments media={mediasList[currentSlide!]} albumShared={albumShared} mediasList={mediasList} />

            {/* MODALS */}
            <DeleteConfirmation
                specificModal={ModalsLayoutStore().deleteMedia}
                message={`${pathname.includes('/album/') ?
                    `${t('confirm-delete.part1')}
            ${(mediasList[currentSlide!]?.type_media === 'answerkiss' && 'answerkiss') ||
                    (mediasList[currentSlide!]?.type_media === 'image' && t('picture')) ||
                    (mediasList[currentSlide!]?.type_media === 'video' && t('video'))
                    }
          ${t('confirm-delete.part2')}`
                    :
                    `${t('confirm-delete.part1')}
          ${(mediasList[currentSlide!]?.type_media === 'answerkiss' && 'answerkiss') ||
                    (mediasList[currentSlide!]?.type_media === 'image' && t('picture')) ||
                    (mediasList[currentSlide!]?.type_media === 'video' && t('video'))
                    } 
          ?`
                    }`}
                closeModal={() => dispatch(confirmDeleteMedia(false))}
                deleteAction={() => {
                    // Suppression du médias de la liste en cours (tous les médias ou médias d'un album)
                    if (pathname.includes('/all-medias')) {
                        dispatch(deleteMedia(mediasList[currentSlide!]))
                        mediasList.splice(currentSlide!, 1)
                    }
                    else if (pathname.includes('/album/')) {
                        dispatch(deleteMediaInAlbum(albumId, mediasList[currentSlide!]._id))
                        mediasList.splice(currentSlide!, 1)
                    }
                    // Mise à jour de la diapo en cours
                    if (currentSlide === mediasList.length) {
                        dispatch(changeCurrentSlide(0))
                    } else {
                        dispatch(changeCurrentSlide(currentSlide!))
                    }
                    // Fermeture du modal
                    dispatch(confirmDeleteMedia(false))
                }}
            />
            <AddToAlbumList mediaToAdd={mediasList[currentSlide!]?._id} />
            <CreateAlbum allMedias={allMedias} />
        </div>
    );
}

export default Lightbox;