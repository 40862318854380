// verouillage du nom des actions
export const LISTEN_WEBSOCKET: string = 'LISTEN_WEBSOCKET';
export const EMIT_TO_WEBSOCKET: string = 'EMIT_TO_WEBSOCKET';

// écoute des évènements du websocket => middleware
export function listenWebSocket() {
    return ({
        type: LISTEN_WEBSOCKET,
    })
}

// envoi du token au websocket pour authentification => middleware
export function emitToWebSocket(token: string) {
    return ({
        type: EMIT_TO_WEBSOCKET,
        token: token
    })
}