import allAlbums from "../../../../../../features/album/allAlbums/languages/en/allAlbums.json"
import singleAlbum from "../../../../../../features/album/singleAlbum/languages/en/singleAlbum.json"

export const albumENtranslation = {
    "allAlbums": {
        ...allAlbums
    },
    "singleAlbum": {
        ...singleAlbum
    }
}