// Hooks
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useAppDispatch } from '../../app/hooks';
// Composants de librairies
import { Container, Navbar } from 'react-bootstrap';
// Composants "locaux"
import LinkButton from '../../components/Buttons/linkButton';
import PrimaryButton from '../../components/Buttons/primaryButton';
import UserMenu from './Menus/userMenu';
// Actions
import { getHeaderHeight } from '../../app/actions/layout/general';
// Assets
import logo from '../../assets/logo.png';
// Styles
import './header.scss';

const Header: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.header' });

    // Hook de navigation
    const navigate = useNavigate();

    // Dispatcheru d'actions Redux
    const dispatch = useAppDispatch();

    // Récupérations de l'état connecté de l'utilisateur et de la présence du token d'authentification ('user')
    const user = localStorage.getItem('user');
    const userConnected = localStorage.getItem('userConnected');

    // Mise en référence du header dans le DOM (pour pouvoir obtenir sa hauteur)
    const headerRef = useRef<null | HTMLDivElement>(null)

    // Effet de bord
    // Mise en mémoire de la hauteur du header dans le state
    // pour pouvoir ajuster la position du menu de navigation pour la version mobile
    useEffect(() => {
        dispatch(getHeaderHeight(headerRef.current?.offsetHeight))
    }, [dispatch])

    return (
        <div className="header sticky-top" ref={headerRef}>
            <Navbar className="sticky-top navbar-header" expand="lg">
                <Container fluid>
                    <div className="brand">
                        <Navbar.Brand href="/" draggable={false}>
                            <img src={logo} className="logoAnoukiss align-bottom" alt="anoukiss" draggable={false} />
                        </Navbar.Brand>
                        <div className="d-none d-lg-inline d-xl-inline d-md-inline titleLogo">{t('slogan')}</div>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav ms-auto">
                            {/* <li className="nav-item">
                <Langages />
              </li> */}
                            {user !== null && userConnected === 'true' ?
                                <li className="nav-item">
                                    <UserMenu />
                                </li>
                                :
                                <>
                                    <li className="nav-item">
                                        <LinkButton
                                            className="login-button"
                                            type="button"
                                            buttonName={t('buttons.register')}
                                            onClick={() => {
                                                navigate('/login/register');
                                            }}
                                        />
                                    </li>
                                    <li className="nav-item">
                                        <PrimaryButton
                                            className="login-button"
                                            type="button"
                                            buttonName={t('buttons.signIn')}
                                            onClick={() => {
                                                navigate('/login/signin');
                                            }}
                                        />
                                    </li>
                                </>
                            }
                        </ul>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;
