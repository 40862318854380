/// Importation de type
import { AnyAction } from 'redux';
import { InitialState } from '../../types/states/media/mediasList';
import { Media } from '../../types/media';
// Importations des actions utilisées
import {
    ADD_UPLOADED_MEDIA,
    GENERATE_TIMESTAMP_KEY,
    PUT_ALL_MEDIAS_IN_STATE
} from '../../actions/media/mediasList';
// Importation de la fonction permettant la création des urls pour l'affichage des médias
import { composeUrlsForMedia } from '../functions';

const initialState: InitialState = {
    allMedias: []
}

function mediasListReducer(state = initialState, action: AnyAction): InitialState {
    switch (action.type) {

        case PUT_ALL_MEDIAS_IN_STATE:
            {
                // ajout des urls pour l'affichage des images : 
                // url_list : miniature pour la liste de tous les médias
                // url_full : image pour l'affichage "plein écran"
                // url_album : miniature pour la présentation de l'album
                const allMediasForState: Media[] = action.allMedias.map((media: Media) => {
                    composeUrlsForMedia(media)
                    return { ...media }
                })
                return ({
                    ...state,
                    allMedias: allMediasForState
                })
            }

        case ADD_UPLOADED_MEDIA:
            const allMedias = [...state.allMedias];
            if (allMedias.find((media: Media) => media._id === action.media._id) === undefined) {
                const uploadedMediaWithUrls = composeUrlsForMedia(action.media)
                allMedias.push(uploadedMediaWithUrls);
            }
            return ({
                ...state,
                allMedias: allMedias
            })

        case GENERATE_TIMESTAMP_KEY:
            {
                const allMedias = [...state.allMedias];
                // On ajoute / met à jour un timestamp au média concerné 
                // (utilisé pour l'update des answerkiss)
                const allMediasPictureKeyUpdated = allMedias.map((media: Media) => {
                    if (media._id === action.mediaId) {
                        media = { ...media, timestamp: Date.now() }
                    }
                    return { ...media }
                })
                return ({
                    ...state,
                    allMedias: allMediasPictureKeyUpdated
                })
            }

        default:
            return state
    }
}

export default mediasListReducer;