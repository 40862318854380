// fichier de configuariton des requêtes axios pour les middlewares

// Importation d'axios
import axios from 'axios';

// récupération de la variable d'environnement de l'url de l'API
export const { REACT_APP_API_URL: Api_URL } = process.env;

// Création du modèle de requête
let instance = axios.create();

instance.interceptors.request.use((config) => {
    config.baseURL = Api_URL
    config.headers!['Authorization'] = localStorage.getItem('user') ? localStorage.getItem('user') as string : ''
    return config
})

let instanceWithRefreshOnResponse = axios.create();

instanceWithRefreshOnResponse.interceptors.request.use((config) => {
    config.baseURL = Api_URL
    config.headers!['Authorization'] = localStorage.getItem('user') ? localStorage.getItem('user') as string : ''
    return config
})

// Création d'intercepteurs de réponse pour la mise en place du refresh token si le 'Bearer'token a expiré
instance.interceptors.response.use((response) => {
    return response;
},

    async (error) => {
        const originalRequest = error.config;
        // On vérifie que le endpoint de la requête d'origine ne correspond pas à une demande de refreshToken
        // et les statuts retournés par l'erreur correspondent bien à UNAUTHORIZED ou FORBIDDEN
        if (
            originalRequest.url !== `/refreshToken`
            && (error.response.status === 401 || (error.response.status === 403 && error.response.data.error === "Authenfication failed"))
            && !originalRequest._retry
            && localStorage.getItem('userConnected') === 'true'
        ) {
            originalRequest._retry = true;
            await instance.get('/refreshToken', { withCredentials: true })
                .then(response => {
                    localStorage.removeItem('user');
                    localStorage.setItem('user', response.data.access_token);
                    originalRequest.headers['Authorization'] = response.data.access_token;
                })
                // Si le refresh a échoué avec un statut 401 ou 403, on vide le localStorage contenant le Bearer token et le statut connecté de l'utilisateur
                // ( Si l'utilisateur n'est pas connecté, il est redirigé sur la home => voir le routage dans App.tsx)
                .catch(error => {
                    if (error.status === 401 || error.status === 403) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('userConnected');
                    }
                });
            // on renvoie la requête initial contenant le nouveau Bearer token en header
            return instance(originalRequest);

            // si le refreshToken renvoi une erreur, renvoi vers la page de login
        } else if (originalRequest.url === '/refreshToken' && error.response.status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('userConnected');
            document.location.replace('/login/signin')
        }
        return Promise.reject(error)
    })

instanceWithRefreshOnResponse.interceptors.response.use(async (response) => {
    if (response.headers['anoukiss-refreshtoken'] && response.headers['anoukiss-refreshtoken'] === 'send') {
        await instance.get('/refreshToken', { withCredentials: true })
            .then(refreshResponse => {
                localStorage.removeItem('user');
                localStorage.setItem('user', refreshResponse.data.access_token);
            })
            .catch(refreshError => {
                if (refreshError.status === 401 || refreshError.status === 403) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('userConnected');
                }
            });
    }
    return response;
},

    async (error) => {
        return Promise.reject(error)
    })

export { instance, instanceWithRefreshOnResponse };