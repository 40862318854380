/*******************************************************************
   Composant pour le menu utilisateur lorsqu'il est connecté 
*******************************************************************/

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Composants de librairies
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
// Store
import { UserStore } from '../../../app/store/user';
// Actions
import { clearUserData } from '../../../app/actions/user';

const UserMenu: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.header.user-menu' });
    // Hook de navigation
    const navigate = useNavigate();
    // Dispatcher d'actions Redux
    const dispatch = useDispatch();

    // Récupérations des données du state
    const { firstname }: { firstname: string | undefined } = UserStore().userData;

    return (
        <Dropdown>
            <Dropdown.Toggle variant='default' id="dropdown-basic">
                <FontAwesomeIcon icon={faUser} className='FontAwesomeFaUser' />
                <span className="firstname">{firstname !== undefined ? firstname : ''}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu id="user-dropdown">
                <Dropdown.Item as={Link} to="/my-account">{t('my-account')}</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/my-subscription">{t('my-subscription')}<br /> */}
                {/* <i>{t('unlimited')}</i></Dropdown.Item> */}
                <Dropdown.Item
                    onClick={() => {
                        localStorage.removeItem('user');
                        localStorage.removeItem('userConnected');
                        dispatch(clearUserData());
                        navigate('/');
                    }}
                >
                    {t('logout')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UserMenu;
