// Composants de librairies
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LegacyRef } from "react";
import { MouseEventHandler } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

// Typage des props
type ButtonWithTooltipProps = {
    trigger: ('hover' | 'click' | 'focus')[],
    tooltip: { placement: Placement, classname: string, content: string },
    icon: { icon: any, classname: string },
    button: { classname: string, buttonRef: LegacyRef<HTMLButtonElement> | undefined },
    onClick: MouseEventHandler
}

const ButtonWithTooltip: Function = ({ trigger, tooltip, icon, button, onClick }: ButtonWithTooltipProps): JSX.Element => {
    return (
        // On affiche les tooltips uniquement pour les devices permettant le hover / click
        window.innerWidth >= 992 ?
            <OverlayTrigger
                trigger={trigger}
                placement={tooltip.placement}
                overlay={
                    <Tooltip className={tooltip.classname}>
                        {tooltip.content}
                    </Tooltip>
                }
            >
                <button className={button.classname} ref={button.buttonRef}>
                    <FontAwesomeIcon
                        icon={icon.icon}
                        className={icon.classname}
                        onClick={onClick}
                    />
                </button>
            </OverlayTrigger>
            :
            // pour les devices tablettes / mobiles : 
            <button className={button.classname} ref={button.buttonRef}>
                <FontAwesomeIcon
                    icon={icon.icon}
                    className={icon.classname}
                    onClick={onClick}
                />
            </button>
    )
}

export default ButtonWithTooltip;