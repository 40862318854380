// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { instance } from "../config";
// Importation de l'interface history pour récupérer les urls à partir desquelles l'action est dispatchée
// et modifier le state lié à ces urls si besoin
import { createBrowserHistory } from "history";
// Importation des actions utilisées
import {
    DELETE_COMMENT,
    DELETE_MEDIA,
    DISLIKE_MEDIA,
    LIKE_MEDIA,
    PATCH_ANSWERKISS,
    SEND_COMMENT
} from "../../actions/media/singleMedia";
import { putSharedAlbumMediasInState } from "../../actions/album/sharedAlbums";
import { putAllMediasInState } from "../../actions/media/mediasList";
import { editAnswerkiss } from "../../actions/layout/medias";
import { clearFields } from "../../actions/controlledFields";
// Importation de types
import { Media } from "../../types/media";

const singleMediaMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case DELETE_MEDIA:
            instance.delete(`/media/${action.media._id}`)
                .then(response => {
                })
                .catch(error => console.log(error));
            break;

        case LIKE_MEDIA:
            instance.post(`/like/${action.mediaId}`)
                .then(response => {
                    // Récupération de liste des médias
                    const { allMedias } = store.getState().mediasList;
                    const { id, title, medias: singleAlbumMedias } = store.getState().sharedAlbums.singleAlbum

                    // Si on se trouve sur une url de partage alors la liste des médias correspond aux médias de l'album partagé
                    // sinon on assigne à mediasList la liste de tous les médias
                    const history = createBrowserHistory();
                    const mediasList = history.location.pathname.includes('/share/') ? singleAlbumMedias : allMedias
                    // On actualise les likes sur la liste des médias
                    const mediasListLikesUpdated = mediasList.map((media: Media) => {
                        if (media._id === action.mediaId) {
                            media.likes.push(response.data)
                            // On détermine si le champs nbLikes existe en BDD car si ce n'est pas le cas, il est de type NaN
                            // et l'incrémentation n'est pas possible
                            if (media.nbLikes) {
                                media.nbLikes += 1;
                            } else {
                                media.nbLikes = 1;
                            }
                        }
                        return { ...media }
                    })

                    history.location.pathname.includes('/share/') ?
                        store.dispatch(putSharedAlbumMediasInState(id, title, mediasListLikesUpdated))
                        :
                        store.dispatch(putAllMediasInState(mediasListLikesUpdated))
                })
                .catch(error => console.log(error));
            break;

        case DISLIKE_MEDIA:
            instance.delete(`/like/${action.mediaId}`)
                .then(response => {
                    // Récupération de liste des médias
                    const { allMedias } = store.getState().mediasList;
                    const { id, title, medias: singleAlbumMedias } = store.getState().sharedAlbums.singleAlbum
                    // Si on se trouve sur une url de partage alors la liste des médias correspond aux médias de l'album partagé
                    // sinon on assigne à mediasList la liste de tous les médias
                    const history = createBrowserHistory();
                    const mediasList = history.location.pathname.includes('/share/') ? singleAlbumMedias : allMedias
                    // Actualisation du like en faisant une recherche sur le owner_id qui correspond à l'id utilisateur
                    const { id: userId } = store.getState().user.userData
                    const mediasListLikesUpdated = mediasList.map((media: Media) => {
                        if (media._id === action.mediaId) {
                            media.likes.splice(media.likes.findIndex((like: { [key: string]: any }) => like.owner_id === userId), 1)
                            media.nbLikes -= 1;
                        }
                        return { ...media }
                    })

                    history.location.pathname.includes('/share/') ?
                        store.dispatch(putSharedAlbumMediasInState(id, title, mediasListLikesUpdated))
                        :
                        store.dispatch(putAllMediasInState(mediasListLikesUpdated))
                })
                .catch(error => console.log(error));
            break;

        case SEND_COMMENT:
            {
                // Récupération de la valeur du champ contrôlé correspondant à l'input des commentaires
                const { commentToSend } = store.getState().controlledFields;
                // Envoi au serveur
                instance.post(`/comment/${action.mediaId}`, {
                    comment: commentToSend
                })
                    .then(response => {
                        // Récupération de liste des médias
                        const { allMedias } = store.getState().mediasList;
                        const { id, title, medias: singleAlbumMedias } = store.getState().sharedAlbums.singleAlbum
                        
                        // Si on se trouve sur une url de partage alors la liste des médias correspond aux médias de l'album partagé
                        // sinon on assigne à mediasList la liste de tous les médias
                        const history = createBrowserHistory();
                        const mediasList = history.location.pathname.includes('/share/') ? singleAlbumMedias : allMedias
                        // On actualise les commentaires sur la liste des médias
                        const mediasListCommentsUpdated = mediasList.map((media: Media) => {
                            if (media._id === action.mediaId) {
                                media.comments.push(response.data)
                                // On détermine si le champs nbComments existe en BDD car si ce n'est pas le cas, il est de type NaN
                                // et l'incrémentation n'est pas possible
                                if (media.nbComments) {
                                    media.nbComments += 1;
                                } else {
                                    media.nbComments = 1;
                                }
                            }
                            return { ...media }
                        })

                        history.location.pathname.includes('/share/') ?
                            store.dispatch(putSharedAlbumMediasInState(id, title, mediasListCommentsUpdated))
                            :
                            store.dispatch(putAllMediasInState(mediasListCommentsUpdated))

                        store.dispatch(clearFields("commentToSend"));
                    })
                    .catch(error => console.log(error));
            }
            break;

        case DELETE_COMMENT:
            instance.delete(`/comment/${action.mediaId}/${action.commentId}`)
                .then(response => {
                    // Récupération de liste des médias
                    const { allMedias } = store.getState().mediasList;
                    const { id, title, medias: singleAlbumMedias } = store.getState().sharedAlbums.singleAlbum
                    // Si on se trouve sur une url de partage alors la liste des médias correspond aux médias de l'album partagé
                    // sinon on assigne à mediasList la liste de tous les médias
                    const history = createBrowserHistory();
                    const mediasList = history.location.pathname.includes('/share/') ? singleAlbumMedias : allMedias
                    // Actualisation des commentaires en faisant une recherche sur l'id du commentaire
                    const mediasListCommentsUpdated = mediasList.map((media: Media) => {
                        if (media._id === action.mediaId) {
                            media.comments.splice(media.comments.findIndex((comment: { [key: string]: any }) => comment._id === action.commentId), 1)
                            media.nbComments -= 1;
                        }
                        return { ...media }
                    })

                    history.location.pathname.includes('/share/') ?
                        store.dispatch(putSharedAlbumMediasInState(id, title, mediasListCommentsUpdated))
                        :
                        store.dispatch(putAllMediasInState(mediasListCommentsUpdated))
                })
                .catch(error => console.log(error));
            break;

        case PATCH_ANSWERKISS:
            {
                // Récupération de la valeur des champs contrôlés correspondant aux input question / réponse de l'answerkiss
                const { question, answer } = store.getState().controlledFields.answerkiss
                // Récupération de l'id du média via sa position dans la lightbox
                const { allMedias } = store.getState().mediasList
                const { currentSlide } = store.getState().lightbox
                const mediaId = allMedias[currentSlide]._id

                instance.patch(`/answer-kiss/${mediaId}`, {
                    question: question,
                    answer: answer
                })
                    .then(response => {
                        // On passe le mode édition de l'answerkiss à true pour réinitialiser le modal au prochain affichage
                        store.dispatch(editAnswerkiss(false))
                        // On vide les champs contrôlés correspondant à l'answerkiss
                        store.dispatch(clearFields('answerkiss'))
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            break;

        default:

    }

    next(action);
}

export default singleMediaMiddleware;