/*******************************************************************
    Composant pour l'affichage de tous les médisa (/all-medias)
*******************************************************************/

//Hooks
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
// Composants "locaux"
import Upload from "../upload/upload";
import Lightbox from "../lightbox/lightbox";
import MediasList from "./mediasList";
import AddAnswerkiss from "../../components/Modals/addAnswerkiss";
import PrimaryButton from "../../components/Buttons/primaryButton";
// Stores
import { LightboxLayoutStore } from "../../app/store/layout";
import { MediasListStore } from "../../app/store/media";
// Actions
import { mediaUploadingInAlbum } from "../../app/actions/album/singleAlbum";
import { getAllMedias } from "../../app/actions/media/mediasList";
import { editAnswerkiss } from "../../app/actions/layout/medias";
import { openAnswerKissModal } from "../../app/actions/layout/modals"
// Types
import { Media } from "../../app/types/media";
// Styles
import './allMedias.scss';

import votreHistoire from '../../assets/images/home/votre-histoire.jpg';
import vosPhotos from '../../assets/images/home/vos-photos.jpg';
import vosDocuments from '../../assets/images/home/vos-documents.jpg';
import vosVideos from '../../assets/images/home/vos-videos-compress.mp4';


const AllMedias: Function = (props: any): JSX.Element => {

    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.medias.allMedias' })

    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch();

    // Récupérations des states
    const { open: lightboxIsOpen, interval } = LightboxLayoutStore();
    const { allMedias } = MediasListStore();

    //const filesUploadDialog = useRef<null | HTMLInputElement>(null);
    const filesUploadDialog = props.filesUploadDialog;

    // Effets de bords 
    // enregistrement de la liste des médias dans le store au chargement du composant
    useEffect(() => {
        dispatch(getAllMedias());
        dispatch(mediaUploadingInAlbum(false));
        dispatch(editAnswerkiss(false));
    }, [dispatch]);

    // "clonage" de la liste des Medias filtrés pour contourner l'attribution par référence de JS
    const mediasToScaled: Media[] = allMedias.map((media: Media) => ({ ...media }));

    return (
        <div className="all-medias marginMenuLeft">
            {/* Message si l'utilisateur n'a pas de médias */}
            {allMedias.length === 0 && (
                <div className="presentationCompteVierge">
                    <div className="row">

                        <div className="col">
                            <h1>Créez votre boîte à souvenirs</h1>


                            {t('message.part1')}<br />{t('message.part2')}

                        </div>

                    </div>

                    <div className="row">
                        <div className='col text-center'>
                            <PrimaryButton
                                id="create-button"
                                className="btn-lg"
                                buttonName={t('menu.add.upload.label')}
                                onClick={() => filesUploadDialog.current?.click()}
                            />
                        </div>
                        <div className='col text-center'>
                            <PrimaryButton
                                id="create-button"
                                className="btn-lg"
                                buttonName={t('menu.add.answerkiss')}
                                onClick={() => {
                                    dispatch(editAnswerkiss(false))
                                    dispatch(openAnswerKissModal(true))
                                }}
                            />
                        </div>
                    </div>


                    <div className="row" style={{marginTop: '50px'}}>
                        <div className='col-gauche'>
                            <img src={votreHistoire} alt='votre histoire' />
                        </div>
                        <div className='col-droite'>
                        <img src={vosPhotos} alt='vos photos' />
                        </div>
                    </div>


                    <div className="row columReverse">
                        <div className='col-gauche'>
                            <video autoPlay muted loop width="600" src={vosVideos}>
                            </video>
                        </div>
                        <div className='col-droite'>
                            <img src={vosDocuments} alt='vos documents' />
                        </div>
                    </div>
                </div>

            )}

            {/* Liste des médias */}
            <MediasList mediasList={mediasToScaled} />

            {/* Lightbox pour le diaporama */}
            {lightboxIsOpen === true && allMedias.length !== 0 && (
                <Lightbox mediasList={allMedias} interval={interval} />
            )}

            {/* Composant Upload en arrière-plan (non visible) */}
            <Upload tooltipDirection="left" filesUploadDialog={filesUploadDialog} />

            {/* Modal pour la création d'answerkiss */}
            <AddAnswerkiss />

        </div>
    )
}

export default AllMedias;