// Hooks
import { useEffect, useState, useRef } from 'react';

import { useAppDispatch } from '../../app/hooks';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Login from '../login/login';
import Home from '../home/home';
import AllMedias from '../medias/allMedias';
import Confirmation from '../confirmation/confirmation';
import AllAlbums from '../album/allAlbums/allAlbums';
import SingleAlbum from '../album/singleAlbum/singleAlbum';
import NotAllowed from '../album/singleAlbum/notAllowed';
import Profile from '../profile/profile';
import LostPassword from '../lostPassword/lostPassword';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import PrimaryButton from "../../components/Buttons/primaryButton";
import { showCreateAlbumModal } from "../../app/actions/layout/modals";

// Stores
import { AlbumLayoutStore } from '../../app/store/layout';
// Actions
import { emitToWebSocket, listenWebSocket } from '../../app/actions/socket';
import { getUserData, sendStatistics } from '../../app/actions/user';

import { UserStore } from '../../app/store/user';

import { SharedAlbumsStore } from "../../app/store/album";
import { confirmDeleteAlbum, confirmDuplicateAlbum, openShareAlbumModal, showAddMediaInAlbum } from "../../app/actions/layout/modals"

// Actions
import { getAlbumsSharedWithMe } from "../../app/actions/album/sharedAlbums";
import { editAnswerkiss } from "../../app/actions/layout/medias"
import { openAnswerKissModal } from "../../app/actions/layout/modals"
import { clearUserData } from '../../app/actions/user';

import { editSingleAlbumTitle, openSingleAlbumMenu } from "../../app/actions/layout/album"

// Style
import './layoutContent.scss';



const LayoutContent: Function = (): JSX.Element => {
    // Traduction
    const { t } = useTranslation('')

    // Dispatcheur d'actions Redux
    const dispatch = useAppDispatch();

    // Hook de navigation
    const navigate = useNavigate();

    // Récupération du chemin dans l'url
    const { pathname } = useLocation();

    let albumId: any = null;
    if (pathname.startsWith('/album/')) {
        albumId = pathname.replace('/album/', '');
    }



    const filesUploadDialog = useRef<null | HTMLInputElement>(null);

    // Récupération des states
    const { displayAlbumShared } = AlbumLayoutStore();
    // Récupération du token 'user' et du statut connecté de l'utilisateur pour les routes privées
    const user: string | null = localStorage.getItem('user');
    const { userConnected } = UserStore().userData;

    // Récupération des states
    const { albumsSharedWithMe } = SharedAlbumsStore()


    // Définition du texte du bouton de création d'album en fonction de la largeur de la fenêtre
    const device = window.innerWidth >= 425 ? "medium" : "small"
    const labelTextSmall = t('features.album.allAlbums.buttons.create.device.small')
    const labelTextMedium = t('features.album.allAlbums.buttons.create.device.medium')

    // Effet de bord au chargement de la page et à chaque changement de token
    // Récupération des données de l'utilisateur via le token,
    // Émission du token au websocket,
    // Écoute du socket
    useEffect(() => {
        if (user !== 'null') {
            dispatch(getUserData())
            dispatch(emitToWebSocket(user!))
            dispatch(listenWebSocket())
        }
    }, [dispatch, user])

    // Envoi de statistiques de consultation de page au serveur
    useEffect(() => {
        dispatch(sendStatistics())
    }, [dispatch, pathname])

    // Effet de bord
    // Récupération des albums partagés au chargement du composant
    useEffect(() => {
        dispatch(getAlbumsSharedWithMe())
    }, [dispatch])

    const isOnMobile: boolean = window.innerWidth <= 768 ? true : false

    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            {/* colonne avec le menu */}
            {userConnected && (
                <div className={`layoutContentMenu ${showMenu ? '' : 'layoutContentMenuNavbarHide'}`}>
                    <div className='layoutContentMenuBurgerMenu text-end'>
                        <FontAwesomeIcon icon={faBars} onClick={(e) => {
                            setShowMenu(!showMenu);
                        }} />
                    </div>
                    <div className="layoutContentMenuNavbar">

                        {/* <NavLink
                            draggable={false}
                            to="/all-medias"
                            className={({ isActive }) => (isActive ? 'nav_link selected' : 'nav_link')}
                        >
                            {t('features.layout.all-medias')}
                        </NavLink> */}
                        <NavLink
                            draggable={false}
                            to="/all-albums"
                            className={({ isActive }) => (isActive || pathname.includes('/album/') ? 'nav_link selected' : 'nav_link')}
                        >
                            {t('features.layout.my-albums')}
                        </NavLink>
                        {albumsSharedWithMe.length !== 0 && (
                            <NavLink
                                draggable={false}
                                to="/shared-with-me"
                                className={({ isActive }) => (isActive ? 'nav_link selected' : 'nav_link')}
                            >
                                {t('features.layout.shared-with-me')}
                            </NavLink>
                        )}

                        <div className='boutonAction'>
                            {pathname === '/all-albums' && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={device === 'small' ? labelTextSmall : labelTextMedium}
                                    onClick={() => dispatch(showCreateAlbumModal(true))}
                                />
                            )}




                            {pathname === '/all-medias' && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.medias.allMedias.menu.add.upload.label')}
                                    onClick={() => filesUploadDialog.current?.click()}
                                />
                            )}


                            {pathname.startsWith('/album/') && (
                                <div className='menuSingleAlbumStart'>Ajouter un souvenir</div>
                            )}
                            {pathname.startsWith('/album/') && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.album.singleAlbum.menu.add-media.from-computer')}
                                    onClick={() => filesUploadDialog.current?.click()}
                                />
                            )}
                            {/* {pathname.startsWith('/album/') && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.album.singleAlbum.menu.add-media.from-all-medias')}
                                    onClick={() => dispatch(showAddMediaInAlbum(true))}
                                />
                            )} */}


                            {(pathname === '/all-medias' || pathname.startsWith('/album/')) && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.medias.allMedias.menu.add.answerkiss')}
                                    onClick={() => {
                                        dispatch(editAnswerkiss(false))
                                        dispatch(openAnswerKissModal(true))
                                    }}
                                />
                            )}

                            {pathname.startsWith('/album/') && (
                                <div className='menuSingleAlbumEnd'></div>
                            )}




                            {pathname.startsWith('/album/') && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.album.singleAlbum.menu.edit-title')}
                                    onClick={() => {
                                        dispatch(editSingleAlbumTitle(true))
                                        dispatch(openSingleAlbumMenu(false))
                                    }}
                                />
                            )}

                            {pathname.startsWith('/album/') && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.album.singleAlbum.menu.share')}
                                    onClick={() => dispatch(openShareAlbumModal(true))}
                                />
                            )}

                            {pathname.startsWith('/album/') && albumId && (
                                <PrimaryButton
                                    id="create-button"
                                    className="btn-sm"
                                    buttonName={t('features.album.singleAlbum.menu.delete')}
                                    onClick={() => dispatch(confirmDeleteAlbum(true, albumId))}
                                />
                            )}

                            {pathname.startsWith('/album/') && (
                                <>
                                    <div className='menuSingleAlbumEnd'></div>
                                    <PrimaryButton
                                        id="create-button"
                                        className="btn-sm"
                                        buttonName={t('features.album.singleAlbum.menu.duplicate-album')}
                                        onClick={() => {
                                            dispatch(confirmDuplicateAlbum(true, albumId))
                                        }}
                                    />
                                </>
                            )}

                            {pathname.startsWith('/album/') && (
                                <div className='menuSingleAlbumEnd'></div>
                            )}


                            {isOnMobile && (
                                <>
                                    <div className='menuSingleAlbumEnd'></div>
                                    <PrimaryButton
                                        id="create-button"
                                        className="btn-sm"
                                        buttonName={t('features.header.user-menu.my-account')}
                                        onClick={() => {
                                            navigate('/my-account');
                                        }}
                                    />
                                    <PrimaryButton
                                        id="create-button"
                                        className="btn-sm"
                                        buttonName={t('features.header.user-menu.logout')}
                                        onClick={() => {
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('userConnected');
                                            dispatch(clearUserData());
                                            navigate('/');
                                        }}
                                    />
                                </>
                            )}



                        </div>
                    </div>
                </div>
            )}


            {/* colonne avec le content */}
            <div className='layoutContentContent'>
                <Routes>
                    <Route
                        key='Login'
                        path='/login/signin'
                        element={<Login />}
                    />
                    <Route
                        key='Login'
                        path='/login/register'
                        element={<Login />}
                    />
                    <Route
                        key='Home'
                        path='/'
                        element={userConnected && user ? <Navigate to="/all-albums" /> : <Home />}
                    />
                    <Route
                        key='PrivateShare'
                        path='/share/:albumId/:shareId'
                        element={displayAlbumShared ? <SingleAlbum /> : <NotAllowed />}
                    />
                    <Route
                        key='PublicShare'
                        path='/share/:albumId/:shareId/public'
                        element={displayAlbumShared ? <SingleAlbum /> : <NotAllowed />}
                    />
                    <Route
                        key='ResetPassword'
                        path='/resetPassword/:token'
                        element={<LostPassword />}
                    />
                    {userConnected && (
                        <Route
                            key='Confirmation'
                            path='/confirmation'
                            element={<Confirmation />}
                        />
                    )}
                    {userConnected && user !== null && (
                        <>
                            <Route
                                key='AllMedias'
                                path='/all-medias'
                                element={<AllMedias filesUploadDialog={filesUploadDialog} />}
                            />
                            <Route
                                key='AllAlbums'
                                path='/all-albums'
                                element={<AllAlbums />}
                            />
                            <Route
                                key='Album'
                                path='/album/:albumId'
                                element={<SingleAlbum filesUploadDialog={filesUploadDialog} />}
                            />
                            <Route
                                key='SharedWithMe'
                                path='/shared-with-me'
                                element={<AllAlbums />}
                            />
                            <Route
                                key="Profile"
                                path='/my-account'
                                element={<Profile />}
                            />
                        </>
                    )}
                    <Route
                        key="Other"
                        path='*'
                        element={<Home />}
                    />
                </Routes>
            </div>
        </>
    )
}

export default LayoutContent;