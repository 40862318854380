// Importation du type Middleware de redux
import { Middleware } from "redux";
// importation de la configuration des requêtes axios
import { Api_URL, instance } from "./config";
// importation d'axios pour les requêtes non configurées (ne nécessitant pas d'authentification)
import axios from "axios";
import ReactGA from "react-ga4";
// Importation des actions utilisées
import {
    ASK_FOR_CHANGING_PASSWORD,
    getUserData,
    LOG_IN,
    REGISTER,
    RESET_PASSWORD,
    SEND_FEEDBACK,
    SEND_STATISTICS,
    UPDATE_PROFILE,
    userConnectedAfterRegister
} from "../actions/user";
import { clearFields, handleFeedback } from '../actions/controlledFields';
import { emitToWebSocket } from '../actions/socket';
import { displayLoginMessageError, displayRegisterMessageError, displayPasswordChangedMessage, handleIsLoading, thanksForFeedback } from '../actions/layout/general';


// initialisation de Google Analytics
ReactGA.initialize("G-DS48KECXYK");

const userMiddleware: Middleware = store => next => action => {
    switch (action.type) {

        case REGISTER:
            {
                // récupération de la valeur des champs contrôlés du formulaire d'inscription
                const { firstname, lastname, email, password } = store.getState().controlledFields.user;
                // Envoi au serveur
                axios.post(`${Api_URL}/profile`,
                    {
                        firstname: firstname,
                        lastname: lastname,
                        email: email,
                        password: password,
                    }
                )
                    .then(response => {
                        // Envoie du token reçu dans la réponse au websocket
                        store.dispatch(emitToWebSocket(response.data.access_token))
                        // Stockage du token et de l'état connecté de l'utilisateur dans le le localstorage
                        localStorage.setItem('user', response.data.access_token);
                        localStorage.setItem('userConnected', JSON.stringify(true));
                        // On vide les champs contrôlés associés aux informations de l'utilisateur
                        store.dispatch(clearFields('user'));
                        // On désactive le loader
                        store.dispatch(handleIsLoading(false));
                        document.location.replace('/all-albums');
                        

                        // On vide les champs contrôlés associés aux informations de l'utilisateur
                        store.dispatch(clearFields('user'));
                        // document.location.replace('/confirmation')
                    })
                    .catch(error => {
                        store.dispatch(displayRegisterMessageError(true))
                    });
            }
            break;

        case LOG_IN:
            {
                // Mise en place d'un loader en attendant la connexion
                store.dispatch(handleIsLoading(true));
                // récupération de la valeur des champs contrôlés du formulaire de connexion
                const { email, password }: { email: string, password: string } = store.getState().controlledFields.user;
                // On ajoute l'option withCredentials pour pouvoir récupérer le token envoyé en cookie par le serveur (refreshtoken)
                axios.post(`${Api_URL}/login`,
                    {
                        email: email,
                        password: password,
                    }, { withCredentials: true }
                )
                    .then(response => {
                        // Envoie du token reçu dans la réponse au websocket
                        store.dispatch(emitToWebSocket(response.data.access_token))
                        // Stockage du token et de l'état connecté de l'utilisateur dans le le localstorage
                        localStorage.setItem('user', response.data.access_token);
                        localStorage.setItem('userConnected', JSON.stringify(true));
                        // On vide les champs contrôlés associés aux informations de l'utilisateur
                        store.dispatch(clearFields('user'));
                        // On désactive le loader
                        store.dispatch(handleIsLoading(false));
                        document.location.replace('/all-albums');
                    })
                    .catch(error => {
                        store.dispatch(handleIsLoading(false));
                        store.dispatch(displayLoginMessageError(true))
                    });
            }
            break;

        case SEND_STATISTICS:
            // Google Analytics : Send pageview with a custom path
            ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.title });


            // On détermine si le feedback est envoyé par un utilisateur connecté ou non
            // et on adapte la requête en fonction
            const { id: userId } = store.getState().user.userData;
            const requestConfig = userId !== undefined ? instance : axios
            const endpoint = userId !== undefined ? '' : Api_URL

            requestConfig.post(`${endpoint}/viewStats`, {
                url: document.location.href
            })
                .then(response => {
                })
                .catch(error => {
                    console.log(error)
                })
            break;

        case UPDATE_PROFILE:
            {
                const userData = store.getState().user.userData;
                const userFields = store.getState().controlledFields.user;
                // On compare les informations des champs contrôlés (saisies par l'utilisateur)
                // avec celle du store qui correspondent aux informations fournies par le payload du token
                // Si l'utilisateur a laissé des champs vides, ce sont les informations du state qui sont retenues pour ces champs
                let userDataUpdated: any = {}
                for (let [key, value] of Object.entries(userFields)) {
                    if (value === '') {
                        value = userData[key]
                    }
                    userDataUpdated[key] = value
                }
                // Si il n'y a pas de mot de passe fourni par l'utilisateur, 
                // on supprime la propriété dans l'objet data à envoyer au serveur
                if (userDataUpdated.password === undefined) {
                    delete userDataUpdated.password;
                }
                instance.patch(`/profile`, userDataUpdated)
                    .then(response => {
                        // On envoie le refreshtoken pour obtenir un nouveau token avec les nouvelles informations du user en payload
                        // On récupère les informations du user avec getUserData()
                        instance.get('/refreshToken', { withCredentials: true })
                            .then(response => {
                                localStorage.removeItem('user');
                                localStorage.setItem('user', response.data.access_token);
                                store.dispatch(getUserData());
                            })
                            // Si le refresh a échoué avec un statut 401 ou 403, on vide le localStorage contenant le Bearer token et le statut connecté de l'utilisateur
                            // ( Si l'utilisateur n'est pas connecté, il est redirigé sur la home => voir le routage dans App.tsx)
                            .catch(error => {
                                console.log(error);
                                if (error.status === 401 || error.status === 403) {
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('userConnected');
                                }
                            });
                    })
                    .catch(error => console.log(error));
            }
            break;

        case SEND_FEEDBACK:
            {
                // Récupération de la valeur du champ contrôlé pour l'input des feedbacks
                const { feedback } = store.getState().controlledFields

                // On détermine si le feedback est envoyé par un utilisateur connecté ou non
                // et on adapte la requête en fonction
                const { id: userId } = store.getState().user.userData;
                const requestConfig = userId !== undefined ? instance : axios
                const endpoint = userId !== undefined ? '' : Api_URL

                requestConfig.post(`${endpoint}/review`, {
                    content: feedback
                })
                    .then(response => {
                        // On réinitialise le champ contrôlé pour les feedbacks
                        store.dispatch(handleFeedback(''))
                        // On affiche un message de remerciement pour l'utilisateur
                        store.dispatch(thanksForFeedback(true))
                    })
                    .catch(error => console.log(error));
            }
            break;

        case ASK_FOR_CHANGING_PASSWORD:
            {
                // Récupération de la valeur du champ contrôlé "Email"
                const { email } = store.getState().controlledFields.user
                // Envoi au serveur
                axios.post(`${Api_URL}/lostPassword`, {
                    email: email
                })
                    .then(response => {
                        // Réinitialisation du champ contrôlé
                        store.dispatch(clearFields('user'))
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            break;

        case RESET_PASSWORD:
            {
                // Récupération de la valeur du champ contrôlé pour la saisie du nouveau mot de passe
                const { resetPassword } = store.getState().controlledFields.user
                // Récupération dans le store du token provenant de l'url de confirmation envoyée par mail
                const { resetPasswordToken } = store.getState().user

                axios.post(`${Api_URL}/changePassword/${resetPasswordToken}`, {
                    password: resetPassword
                })
                    .then(response => {
                        // affichage  du message de confirmation de changement de mot de passe
                        store.dispatch(displayPasswordChangedMessage(true))
                        // Réinitialisation des champs contrôlés relatifs à l'utilisateur
                        store.dispatch(clearFields('user'))
                    })
                    .catch(error => console.log(error))
            }
            break;

        default:
    }

    next(action);
}

export default userMiddleware;