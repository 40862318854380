/*******************************************************************
              Composant pour la boîte de commentaires 
            (et les boutons d'actions dans la lightbox)
*******************************************************************/

// Hooks
import { useRef } from 'react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';
// Composants de librairies
import { Offcanvas } from 'react-bootstrap';
import InputEmoji from 'react-input-emoji';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// Composantas "locaux"
import OffCanvasButtons from '../offCanvasButtons';
import ButtonWithTooltip from '../../../components/Buttons/buttonWithTooltip';
import { lightboxButtons } from '../lightboxButtons';
import PrimaryButton from '../../../components/Buttons/primaryButton';
import LikeOrCommentPopover from '../../../components/Popover/LikeOrCommentPopover';
// Stores
import { ControlledFieldsStore } from '../../../app/store/controlledFields';
import { UserStore } from '../../../app/store/user';
import { LightboxLayoutStore } from '../../../app/store/layout';
// Actions
import { handleCommentToSend } from '../../../app/actions/controlledFields';
import { getCommentsOffcanvasSize, openCanILikeOrCommentPopover } from '../../../app/actions/layout/medias';
import { deleteComment, sendComment } from '../../../app/actions/media/singleMedia';
import { openComments } from '../../../app/actions/layout/lightbox';
// Types
import { Media } from '../../../app/types/media';
// Styles
import './comments.scss';

type CommentsProps = {
    media: Media,
    albumShared: boolean,
    mediasList: Media[]
}

type Comment = {
    _id: string,
    owner_id: string,
    firstname: string,
    lastname: string,
    comment: string
}

const Comments: Function = ({ media, albumShared, mediasList }: CommentsProps): JSX.Element => {
    // Traduction
    const { t } = useTranslation('', { keyPrefix: 'features.lightbox.comments' })
    // Dispatcher d'actions Redux
    const dispatch = useAppDispatch();
    // Récupération des states
    const { commentToSend } = ControlledFieldsStore();
    const { id: userId } = UserStore().userData;
    const { openComments: isCommentsOpenned } = LightboxLayoutStore();

    // Mise en références DOM
    // de l'input de saisie de commentaires( pour affichage du popover en cas d'utilisateur non connecté)
    const sendCommentRef = useRef<null | HTMLDivElement>(null);
    // de la boîte de dialogue entière
    const commentsRef = useRef<null | any>(null);
    // du header de la boîte de dialogue
    const commentsRefHeader = useRef<null | HTMLDivElement>(null);

    // Effets de bord
    // Envoie au state au chargement du composant des dimensions de la boîte de commentaires si elle est ouverte
    // (pour permettre ensuite de calculer la taille du média à afficher)
    // Si la boîte de commentaires n'est pas ouverte (cas pour les petits écrans) on récupère uniquement les dimensions du header
    useEffect(() => {
        if (commentsRef.current !== null && commentsRef.current?.dialog !== null && commentsRefHeader.current !== null) {
            isCommentsOpenned ?
                dispatch(getCommentsOffcanvasSize(commentsRef.current.dialog.offsetWidth, commentsRef.current.dialog.offsetHeight))
                :
                dispatch(getCommentsOffcanvasSize(commentsRefHeader.current.offsetWidth, commentsRefHeader.current.offsetHeight))
        }
    }, [dispatch, commentsRef.current?.dialog?.offsetHeight, commentsRef.current?.dialog?.offsetWidth, isCommentsOpenned])

    // Ouverture automatique de la boîte de commentaires pour les écrans dont la largeur est supérieure ou égale à 768px
    useEffect(() => {
        if (window.innerWidth < 768) {
            dispatch(openComments(false))
        } else {
            dispatch(openComments(true))
        }
    }, [dispatch])

    // Origine de l'ouverture de la boîte ( en bas pour les petits écrans / à droite pour les écrans plus larges)
    const openDirection = window.innerWidth < 768 ? "bottom" : "end"
    // Hauteurs de la boîte et de la liste des commentaires pour les petits écrans
    const offCanvasHeight = window.innerWidth < 768 ? "max-content" : "auto"
    const commentsListHeight = window.innerWidth < 768 ? "100px" : "auto"


    return (
        <>
            {media !== undefined && (
                /* Offcanvas => React Bootsrap */
                <Offcanvas
                    id="offCanvas-comments"
                    show={true}
                    placement={openDirection}
                    // désactivation de l'arrière-plan de masquage
                    backdrop={false}
                    ref={commentsRef}
                    style={{ height: offCanvasHeight }}
                >

                    <Offcanvas.Header ref={commentsRefHeader} >
                        {/* Boutons d'actions */}
                        <OffCanvasButtons albumShared={albumShared} mediasList={mediasList} />
                    </Offcanvas.Header>

                    {isCommentsOpenned && (
                        <Offcanvas.Body>

                            <Offcanvas.Title>
                                <div className="title">
                                    {`${t('title')} ${media.nbComments > 0 ? `(${media.nbComments})` : ''}`}
                                </div>
                                {/* Ajout d'une icône de fermeture pour les petits écrans afin de refermer les commentaires */}
                                {window.innerWidth < 768 && (
                                    <FontAwesomeIcon icon={faXmark} onClick={() => dispatch(openComments(false))} />
                                )}
                            </Offcanvas.Title>

                            <div className="comments-list" style={{ height: commentsListHeight }}>
                                {media.comments && media.comments.map((comment: Comment) => (
                                    <div key={comment._id} className="comment-item">
                                        <div className="comment-content">
                                            <div className="comment-owner">{`${comment.firstname} ${comment.lastname}`}</div>
                                            <div className="comment-text">{comment.comment}</div>
                                        </div>

                                        {/* Ajout d'un bouton de suppression si l'auteur du commentaire est aussi l'utilisateur connecté */}
                                        {comment.owner_id === userId && (
                                            <ButtonWithTooltip
                                                trigger={['hover', 'click']}
                                                tooltip={{
                                                    ...lightboxButtons.deleteComment.tooltip,
                                                    content: t('delete')
                                                }}
                                                icon={lightboxButtons.deleteComment.icon}
                                                button={lightboxButtons.deleteComment.button}
                                                onClick={() => dispatch(deleteComment(media._id, comment._id))}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Envoi de commentaire */}
                            <div
                                className="send-comment"
                                ref={sendCommentRef}
                                onClick={() => {
                                    if (userId === undefined) {
                                        dispatch(openCanILikeOrCommentPopover(true, media._id, 'commenter', 'comments'));
                                    }
                                }}
                            >
                                <p className="add-comment">{t('add.label')}</p>
                                <InputEmoji
                                    theme="light"
                                    placeholder={(t('add.placeholder'))}
                                    borderColor="#000000"
                                    cleanOnEnter
                                    onEnter={() => {
                                        if (userId === undefined) {
                                            dispatch(openCanILikeOrCommentPopover(true, media._id, 'commenter', 'comments'));
                                        } else {
                                            dispatch(sendComment(media._id))
                                        }
                                    }}
                                    // Champ contrôlé pour envoyer au state la valeur du commentaire saisi
                                    value={commentToSend}
                                    onChange={(value: string) => dispatch(handleCommentToSend(value))}
                                />
                                <div className="send-button">
                                    <PrimaryButton buttonType="button" buttonName={t('send')} onClick={() => {
                                        if (userId === undefined) {
                                            dispatch(openCanILikeOrCommentPopover(true, media._id, 'commenter', 'comments'));
                                        } else {
                                            dispatch(sendComment(media._id))
                                        }
                                    }}
                                    />
                                </div>
                            </div>

                            { /* Popover indiquant l'obligation de connexion pour pouvoir commenter */}
                            <LikeOrCommentPopover
                                target={sendCommentRef}
                                mediaId={media._id}
                                container="comments"
                                action="commenter"
                                placement="top"
                            />
                        </Offcanvas.Body>
                    )}
                </Offcanvas>
            )}
        </>
    )
}

export default Comments;